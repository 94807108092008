
import { Popover, List, Row, Skeleton, Divider, Button, message, Modal, Col, Space } from 'antd';
import { useEffect, useState } from 'react';
import { noteApi } from '../../service/api';
import { NoteItem, Notice } from '../../service/api/types/Note';
import { category, curTag, curPage, curHasMore, totalNote, curEditNote, searchKey, remindList, vipModalShow } from '../../store/note';
import { StyleConfig } from '../../untils/styleConfig';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BorderOutlined, CheckSquareOutlined, CloseOutlined, EllipsisOutlined, UpCircleOutlined } from '@ant-design/icons';
import Editor from './Editor';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { reload } from '../../store/note';
import copy from 'copy-to-clipboard';
import Html2React from './Html2React';
import { profile } from '../../store/auth';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import { formatCardId, getAnnounceStore, setAnnounceStore } from '../../untils/notice';

let ContainerHeight = window.innerHeight;
interface WindowSize {
  width: number
  height: number
}

export default function NoteList() {

  const [data, setData] = useState<NoteItem[]>([]);
  const [searchData, setSearchData] = useState<NoteItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [isReload, setAllReload] = useRecoilState(reload);
  const [getCurTag, setCurTag] = useRecoilState(curTag);
  const [getCategory, setCategory] = useRecoilState(category);
  const [getCurPage, setCurPage] = useRecoilState(curPage);
  const [hasMore, setHasMore] = useRecoilState(curHasMore);
  const setTotalNote = useSetRecoilState(totalNote);
  const [selfReload, setSelfReload] = useState(new Date().getTime());
  const [getCurEdit, setCurEdit] = useRecoilState(curEditNote);
  const [isNoteDetailShow, setNoteDetailShow] = useState(false);
  const [getIsShareShow, setIsShareShow] = useState(false);
  const [getCombineShow, setCombineShow] = useState(false);
  const [getDelManyShow, setDelManyShow] = useState(false);
  const [getDelManyFocus, setDelManyFocus] = useState(false);
  const [getCombineArr, setCombineArr] = useState([] as string[]);
  const [getDelManyArr, setDelManyArr] = useState([] as string[]);
  const [noteDetail, setNoteDetail] = useState({} as NoteItem);
  const [getAnnounce, setAnnounce] = useState({} as Notice);
  const setRemindList = useSetRecoilState(remindList);
  const getSearchKey = useRecoilValue(searchKey);
  const userInfo = useRecoilValue(profile);
  const [getLinkNotesShow, setLinkNotesShow] = useState(false);
  const [getLinksList, setLinksList] = useState({ cur: null, links: [] } as any);
  const setVipModalShow = useSetRecoilState(vipModalShow);
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  })

  const appendData = () => {
    if (loading) {
      return;
    }
    setLoading(true);

    if (getCategory === 'home') {
      ContainerHeight = window.innerHeight - 54;
    } else {
      ContainerHeight = window.innerHeight;
    }

    if (getCategory === 'home' || getCategory === 'tags') {
      noteApi.note.list(getCurPage, getCurTag).then(res => {
        if (getCurPage === 1) {
          res && res.list && setData(res.list);
        } else {
          res && res.list && setData([...data, ...res.list]);
        }
        if (res.page && res.page.total) {
          setTotalNote(res.page.total);
        }
        setLoading(false);
        if (res.list.length < 10) {
          setHasMore(false);
        }
        setCurPage(getCurPage + 1);
      }).catch(() => {
        setLoading(false);
      })
    } else if (getCategory === 'wechat') {
      noteApi.note.wechatList(getCurPage).then(res => {
        if (getCurPage === 1) {
          res && res.list && setData(res.list);
        } else {
          res && res.list && setData([...data, ...res.list]);
        }
        setLoading(false);
        if (res.list.length < 10) {
          setHasMore(false);
        }
        setCurPage(getCurPage + 1);
      }).catch(() => {
        setLoading(false);
      })
    } else if (getCategory === 'trash') {
      noteApi.note.trashList(getCurPage).then(res => {
        if (getCurPage === 1) {
          res && res.list && setData(res.list);
        } else {
          res && res.list && setData([...data, ...res.list]);
        }
        setLoading(false);
        if (res.list.length < 10) {
          setHasMore(false);
        }
        setCurPage(getCurPage + 1);
      }).catch(() => {
        setLoading(false);
      })
    }
  };

  useEffect(() => {
    appendData();
  }, [getCategory, getCurTag, isReload, selfReload]);

  useEffect(() => {
    const handler = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    handler()

    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    }

  }, []);

  // search
  useEffect(() => {
    if (loading || !getSearchKey) {
      return;
    }
    setLoading(true);

    noteApi.note.search(getSearchKey).then(res => {
      res && res.list && setSearchData(res.list);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    })
  }, [getSearchKey]);

  // 消息
  useEffect(() => {
    noteApi.note.noticeList().then(res => {
      if (res.announce) {
        setAnnounce(res.announce);
      }
      if (res.remindList && res.remindList.length > 0) {
        setRemindList(res.remindList);
      }
    }).catch(err => {
      console.log('noticeList err', err);
    })
  }, [setRemindList]);
  const onPressCloseAnnounce = (announce: Notice) => {
    announce && setAnnounceStore(announce._id);
    setAnnounce({} as Notice);
  }

  const onPressNoteTop = (note: NoteItem) => {
    noteApi.note.noteSetTop(note._id, note.weight > 0 ? 'TRUE' : '').then(res => {
      setCurPage(1);
      setHasMore(true);
      setSelfReload(new Date().getTime());
    }).catch(() => {
    })
  }
  const onPressNoteEdit = (note: NoteItem) => {
    setCurEdit(note._id);
  }
  const onPressNoteShare = (note: NoteItem) => {
    setNoteDetail(note);
    setIsShareShow(true);
  }
  const onPressSaveSharePic = (note: NoteItem) => {
    const node = document.getElementById("shareId");
    node && domtoimage.toPng(node).then((defaultUrl: any) => {
      saveAs(defaultUrl, `catnote-${note._id}.png`)
    }).catch((err: any) => {
      console.log("onPressSaveSharePic error:", err)
    })
  }
  const onPressCombineNote = (note: NoteItem) => {
    setCombineShow(true);
  }
  const onCombineItemPress = (id: string, action: boolean) => {
    if (action) {
      if (!getCombineArr.includes(id)) {
        setCombineArr(getCombineArr.concat([id]));
      }
    } else {
      if (getCombineArr.includes(id)) {
        setCombineArr(getCombineArr.filter(item => item !== id) || []);
      }
    }
  }
  const onPressDoCombine = () => {
    if (getCombineArr.length < 2) {
      return message.warning('请至少选择两条笔记');
    }
    noteApi.note.noteCombine(getCombineArr.join(',')).then(res => {
      if (res.success) {
        setCombineArr([]);
        setCombineShow(false);
        setCurPage(1);
        setHasMore(true);
        setAllReload(new Date().getTime());
        return message.success('笔记合并成功');
      } else {
        return message.error(res.msg || '笔记合并失败');
      }
    }).catch((err) => {
      console.log("noteCombine error:", err)
    })
  }
  const onPressNoteDelMany = (note: NoteItem, focus = false) => {
    setDelManyShow(true);
    if (focus) {
      setDelManyFocus(true);
    }
  }
  const onDelManyItemPress = (id: string, action: boolean) => {
    if (action) {
      if (!getDelManyArr.includes(id)) {
        setDelManyArr(getDelManyArr.concat([id]));
      }
    } else {
      if (getDelManyArr.includes(id)) {
        setDelManyArr(getDelManyArr.filter(item => item !== id) || []);
      }
    }
  }
  const onPressDoDelMany = () => {
    if (getDelManyArr.length < 1) {
      return message.warning('未勾选笔记');
    }
    noteApi.note.noteDelMany(getDelManyArr.join(','), getDelManyFocus ? 'TRUE' : '').then(res => {
      if (res.success) {
        setDelManyArr([]);
        setDelManyShow(false);
        setDelManyFocus(false);
        setCurPage(1);
        setHasMore(true);
        setAllReload(new Date().getTime());
        return message.success('批量删除成功');
      } else {
        return message.error(res.msg || '批量删除失败');
      }
    }).catch((err) => {
      console.log("noteCombine error:", err)
    })
  }
  const onPressCopyContent = (note: NoteItem) => {
    const reg =  new RegExp('<[^>]+>','gi');
		const content = note.text.replace(reg, '').replaceAll('&nbsp;', '');
    copy(content);
    message.success('笔记内容复制成功');
  }
  const onPressCopyLink = (note: NoteItem) => {
    const link = `${process.env.REACT_APP_WEB_DOMAIN}/note/${note._id}`;
    copy(link);
    message.success('笔记链接复制成功');
  }
  const onPressNoteDel = (note: NoteItem) => {
    noteApi.note.noteDel(note._id, '', '').then(res => {
      setCurPage(1);
      setHasMore(true);
      setAllReload(new Date().getTime());
      return message.success('删除成功');
    }).catch(() => {
    })
  }
  const onPressNoteDelFocus = (note: NoteItem) => {
    noteApi.note.noteDel(note._id, 'TRUE', '').then(res => {
      setCurPage(1);
      setHasMore(true);
      setAllReload(new Date().getTime());
    }).catch(() => {
    })
  }
  const onPressNoteDelReturn = (note: NoteItem) => {
    noteApi.note.noteDel(note._id, '', 'TRUE').then(res => {
      setCurPage(1);
      setHasMore(true);
      setAllReload(new Date().getTime());
      return message.success('还原成功，请在全部笔记中查看');
    }).catch(() => {
    })
  }

  const menu = function(note: NoteItem, isInList = true) {
    return (
      <div>
        <p style={{paddingTop: 0, marginTop: 0}}>
          <Button onClick={(e) => { onPressNoteTop(note); e.stopPropagation(); !isInList && setLinkNotesShow(false) }} style={{borderWidth: 0, padding: 0, height: 10}} >
          {
            note.weight > 0 ? <i className="iconfont icon-arrow-down-circle" style={{padding: 4}}/> : <i className="iconfont icon-arrow-up-circle1" style={{padding: 4}}/>
          }
          {
            note.weight > 0 ? '取消置顶' : '置顶'
          }
          </Button>
        </p>
        {/* <p>
          <Button onClick={(e) => { onPressSee(note); e.stopPropagation(); }} style={{borderWidth: 0, padding: 0, height: 10}} >
          <i className="iconfont icon-detail" style={{padding: 4, color: '#495057'}}/>
          详情
          </Button>
        </p> */}
        <p>
          <Button onClick={(e) => { onPressNoteEdit(note); e.stopPropagation(); !isInList && setLinkNotesShow(false) }} style={{borderWidth: 0, padding: 0, height: 10}} >
          <i className="iconfont icon-create-outline" style={{padding: 4}}/>
          编辑
          </Button>
        </p>
        <div style={{height: 1, backgroundColor: '#DCDCDC', width: '100%', marginTop: 10}}></div>
        <p>
          <Button onClick={(e) => { onPressNoteShare(note); e.stopPropagation(); }} style={{borderWidth: 0, padding: 0, height: 10}} >
          <i className="iconfont icon-share-outline" style={{padding: 4}}/>
          分享
          </Button>
        </p>
        {
          isInList ? (
            <p>
              <Button onClick={(e) => { onPressCombineNote(note); e.stopPropagation(); }} style={{borderWidth: 0, padding: 0, height: 10}} >
              <i className="iconfont icon-bandage-outline" style={{padding: 4}}/>
              合并笔记
              </Button>
            </p>
          ) : <></>
        }
        <p>
          <Button onClick={(e) => { onPressCopyContent(note); e.stopPropagation(); }} style={{borderWidth: 0, padding: 0, height: 10}} >
          <i className="iconfont icon-copy-outline" style={{padding: 4}}/>
          复制内容
          </Button>
        </p>
        <p>
          <Button onClick={(e) => { onPressCopyLink(note); e.stopPropagation(); }} style={{borderWidth: 0, padding: 0, height: 10}} >
          <i className="iconfont icon-at-circle-outline" style={{padding: 4}}/>
          复制链接
          </Button>
        </p>
        <p style={{color: StyleConfig.color_red}}>
          <Button onClick={(e) => { onPressNoteDel(note); e.stopPropagation(); !isInList && setLinkNotesShow(false) }} style={{borderWidth: 0, padding: 0, height: 10}} >
          <i className="iconfont icon-trash-outline" style={{color: StyleConfig.color_red}}/>
          删除
          </Button>
        </p>
        {
          isInList ? (
            <p style={{color: StyleConfig.color_red}}>
              <Button onClick={(e) => { onPressNoteDelMany(note); e.stopPropagation(); }} style={{borderWidth: 0, padding: 0, height: 10}} >
              <i className="iconfont icon-trash" style={{color: StyleConfig.color_red, padding: 4}}/>
              批量删除
              </Button>
            </p>
          ) : <></>
        }
        <div style={{height: 1, backgroundColor: '#DCDCDC', width: '100%', marginTop: 10}}></div>
        {
          note.wcount ? (
            <div style={{width: '100%', display: 'flex', justifyContent:'center',flexDirection: 'row', paddingTop: 10}}>
              <div style={{color: '#A9A9A9', fontSize: 12, justifySelf: 'center'}}>
              字数统计: {note.wcount}
              </div>
            </div>
          ) : <></>
        }
        
      </div>
    );
  }

  const menuFlush = function(note: NoteItem, isInList = true) {
    return (
      <div>
        <p style={{paddingTop: 0, marginTop: 0}}>
          <Button onClick={(e) => { onPressNoteDelReturn(note); e.stopPropagation(); !isInList && setLinkNotesShow(false) }} style={{borderWidth: 0, padding: 0, height: 10}} >
          <i className="iconfont icon-arrow-undo-circle" style={{padding: 4}}/>
          还原
          </Button>
        </p>
        <p style={{color: StyleConfig.color_red, paddingTop: 2}}>
          <Button onClick={(e) => { onPressNoteDelFocus(note); e.stopPropagation(); !isInList && setLinkNotesShow(false) }} style={{borderWidth: 0, padding: 0, height: 10}} >
          <i className="iconfont icon-trash-outline" style={{color: StyleConfig.color_red}}/>
          清除
          </Button>
        </p>
        {
          isInList ? (
            <p style={{color: StyleConfig.color_red, paddingTop: 2}}>
              <Button onClick={(e) => { onPressNoteDelMany(note, true); e.stopPropagation(); }} style={{borderWidth: 0, padding: 0, height: 10}} >
              <i className="iconfont icon-trash" style={{color: StyleConfig.color_red, padding: 4}}/>
              批量清除
              </Button>
            </p>
          ) : <></>
        }
      </div>
    );
  }

  const clickNote = (note: NoteItem, isInList = true) => {
    // setNoteDetail(note);
    // setNoteDetailShow(true);
    onPressNoteEdit(note);
    !isInList && setLinkNotesShow(false)
  }

  const onPressSee = (note: NoteItem) => {
    setNoteDetail(note);
    setNoteDetailShow(true);
  }

  const clickLinksShow = (noteId: string) =>  {
    setLinkNotesShow(true);
    noteId && noteApi.note.noteLinks(noteId).then(res => {
      if (res.success) {
        setLinksList(res);
      } else {
        message.error(res.msg);
      }
    }).catch(err => {
    });
  }

  const onPressNoteTag = (tagId: string) => {
    setCurPage(1);
    setHasMore(true);
    setCurTag(tagId);
    setCategory('tags');
  }

  const maxItemHeight = userInfo.note_fold ? 500 : 10000

  return (
    <div>
      <Modal title={formatCardId((noteDetail && noteDetail.card_id) || '')} open={isNoteDetailShow} onCancel={() => setNoteDetailShow(false)}
        footer={false}
        width={windowSize.width > 700 ? '58%' : '94%'}
        zIndex={1000}
      >
        <div style={{width: '100%'}}>
          <Html2React html={noteDetail.text || ''}  imgBig={true} />
        </div>
        <div style={{marginTop: 10}}>
          {
            (noteDetail.tags || []).map(tag => {
              return (
                <span
                  key={tag._id}
                  style={{ marginTop: 10, marginRight: 5, paddingTop: 3, paddingBottom: 3, paddingLeft: 5, paddingRight: 5, backgroundColor: StyleConfig.color_tag_back, borderRadius: 5 }}
                >
                  <span
                    style={{ color: StyleConfig.color_tag_text, fontSize: StyleConfig.tag_text_size }}
                  >
                    {tag.name}
                  </span>
                </span>
              )
            })
          }
        </div>
      </Modal>
      <Modal open={getIsShareShow} onCancel={() => setIsShareShow(false)}
        footer={false}
        width={windowSize.width > 700 ? '58%' : '94%'}
      >
        <Row id="shareId" style={{width: '90%', backgroundColor: StyleConfig.color_background_gray}} justify="center" >
          <Col style={{ backgroundColor: StyleConfig.color_background_gray, padding: 6}}>
            <div className='note' style={{borderRadius: 6, backgroundColor: StyleConfig.color_white, padding: 14, margin: 5}}>
              <div
                dangerouslySetInnerHTML={{ __html: noteDetail.text || '' }} 
              />
              <Row style={{ marginTop: 10}} justify="center">
                <Col>
                  <img src='https://file.catnote.cn/img/log_new.jpeg' alt='' style={{width: 16, height: 16, borderRadius: 8,  marginBottom: -3, alignSelf: 'center'}}/>
                  <span style={{marginLeft: 6, fontSize: 10, fontWeight: 'bold', alignSelf: 'center'}}>
                    CatNote
                  </span>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row justify='center' style={{marginTop: 10}}>
          <Col>
            <Space>
              <Button type='primary' onClick={() => onPressSaveSharePic(noteDetail)}>保存长图</Button>
              <Button type='primary' onClick={(e) => onPressCopyLink(noteDetail)}>复制链接</Button>
            </Space>
          </Col>
        </Row>
      </Modal>
      <Modal open={getLinkNotesShow} onCancel={() => setLinkNotesShow(false)}
        footer={false}
        width={windowSize.width > 700 ? '58%' : '94%'}
        >
          <div style={{paddingTop: 15, paddingBottom: 10, backgroundColor: StyleConfig.color_background_gray, paddingLeft: 5, paddingRight: 5}} >
            {
              getLinksList.cur ? (
                <div className='note' key={getLinksList.cur._id} style={{borderRadius: 6, borderWidth: 1, borderColor: StyleConfig.color_primary, borderStyle: 'solid', marginBottom: 10, backgroundColor: StyleConfig.color_white, padding: 14, margin: 5, cursor: 'pointer' }}>
                  <Row style={{color: StyleConfig.color_gray, fontSize: 12}}>
                    <div>
                    <div style={{float: 'left', width: 2, marginTop: 4, marginRight: 2, height: 10, backgroundColor: getLinksList.cur.deleted ? StyleConfig.color_danger : (getLinksList.cur.wx_in ? StyleConfig.color_green : StyleConfig.color_primary)}}></div>
                    {formatCardId(getLinksList.cur.card_id)}
                    </div>
                    {
                      getLinksList.cur.weight > 0 ? (
                        <div>
                          <UpCircleOutlined style={{color: StyleConfig.color_primary, paddingLeft: 4}}/>
                        </div>
                      ) : <></>
                    }
                    <div style={{position: 'absolute', right: 40}}>
                    <Button onClick={(e) => { onPressSee(getLinksList.cur); e.stopPropagation(); }} style={{borderWidth: 0, padding: 0, marginTop: -11, marginRight: 10}} >
                      <i className="iconfont icon-detail" style={{padding: 4, color: '#495057'}}/>
                    </Button>
                    <Popover placement="rightBottom" content={getLinksList.cur.deleted ? menuFlush(getLinksList.cur) : menu(getLinksList.cur, false)} trigger="hover">
                      <EllipsisOutlined style={{ fontSize: 20 }}/>
                    </Popover>
                    </div>
                  </Row>
                  <div style={{width: '100%', marginTop: 4 }} 
                    onDoubleClick={() => clickNote(getLinksList.cur, false)}
                  >
                    <Html2React html={getLinksList.cur.text || ''} imgBig={false} searchKey={getSearchKey} isList={true} maxHeight={maxItemHeight}/>
                  </div>
                </div>
              ) : <></>
            }
            <div style={{fontSize: 13, color: StyleConfig.color_gray, paddingLeft: 10, paddingTop: 10}}>{getLinksList.links.length}条笔记链接至此:</div>
            {
              getLinksList.links.length > 0 ? (
                <List
                  dataSource={userInfo.is_vip ? getLinksList.links : getLinksList.links.slice(0, 1)}
                  split={false}
                  renderItem={(item: NoteItem) => {
                    item.text = item.text.replaceAll('http:catNote:', `${process.env.REACT_APP_WEB_DOMAIN || ''}/note/`)
                    return (
                      <div className='note' key={item._id} style={{borderRadius: 6, marginBottom: 10, backgroundColor: StyleConfig.color_white, padding: 14, margin: 5, cursor: 'pointer' }}>
                        <Row style={{color: StyleConfig.color_gray, fontSize: 12}}>
                          <div>
                          <div style={{float: 'left', width: 2, marginTop: 4, marginRight: 2, height: 10, backgroundColor: item.deleted ? StyleConfig.color_danger : (item.wx_in ? StyleConfig.color_green : StyleConfig.color_primary)}}></div>
                          {formatCardId(item.card_id)}
                          </div>
                          {
                            item.weight > 0 ? (
                              <div>
                                <UpCircleOutlined style={{color: StyleConfig.color_primary, paddingLeft: 4}}/>
                              </div>
                            ) : <></>
                          }
                          <div style={{position: 'absolute', right: 20}}>
                          <Button onClick={(e) => { onPressSee(item); e.stopPropagation(); }} style={{borderWidth: 0, padding: 0, marginTop: -11, marginRight: 10}} >
                            <i className="iconfont icon-detail" style={{padding: 4, color: '#495057'}}/>
                          </Button>
                          <Popover placement="rightBottom" content={item.deleted ? menuFlush(item, false) : menu(item, false)} trigger="hover">
                            <EllipsisOutlined style={{ fontSize: 20 }}/>
                          </Popover>
                          </div>
                        </Row>
                        <div style={{width: '100%', marginTop: 4 }} 
                          onDoubleClick={() => clickNote(item, false)}
                        >
                          <Html2React html={item.text || ''} imgBig={false} searchKey={getSearchKey} isList={true} maxHeight={maxItemHeight}/>
                        </div>
                      </div>
                    );
                  }}
                />
              ) : <></>
            }
            {
              !userInfo.is_vip && getLinksList.links.length > 1 ? (
                <div style={{color: 'rgb(80,137,245)', fontSize: 16, textAlign: 'center', padding: 10, cursor: 'pointer'}} onClick={() => setVipModalShow(true)}>
                  查看更多?
                </div>
              ) : <></>
            }
          </div>
      </Modal>
      {
        (getAnnounce && getAnnounce._id && getAnnounceStore() !== getAnnounce._id) ?
        (
          <div style={{padding: 10, width: '100%' }}>
            <Row style={{ backgroundColor: StyleConfig.color_muted_light }}>
              <Row justify='space-between' align='middle' style={{backgroundColor: StyleConfig.color_white, borderRadius: 5, padding: 16, width: '100%'}}>
                <Col>
                  <Space>
                  <span>
                  { (getAnnounce && getAnnounce.text) || '' }
                  </span>
                  {
                    getAnnounce.link ?  <Button type='link' href={getAnnounce.link} >查看详情</Button> : <></>
                  }
                  </Space>
                </Col>
                <Col>
                  <CloseOutlined style={{fontSize: 20}} onClick={() => onPressCloseAnnounce(getAnnounce)}/>
                </Col>
              </Row>
            </Row>
          </div>
        ): <></>
      }
      {
        getCategory === 'home' && !getCurEdit && !getSearchKey ? (
          <div style={{padding: 4}}>
            <Editor listNote={data}/>
          </div>
        ): <></>
      }
      {
        getCombineShow ? 
        (
          <Row align='middle' justify='space-between'>
            <Col style={{marginLeft: 6}}>
              已勾选{getCombineArr.length}条
            </Col>
            <Col>
              <Space>
                <Button type="primary" onClick={() => onPressDoCombine()}>
                  合并
                </Button>
                <Button onClick={() => { setCombineShow(false); setCombineArr([]); }}>
                  取消
                </Button>
              </Space>
            </Col>
          </Row>
        )
        : <></>
      }
      {
        getDelManyShow ? 
        (
          <Row align='middle' justify='space-between'>
            <Col style={{marginLeft: 6}}>
              已勾选{getDelManyArr.length}条
            </Col>
            <Col>
              <Space>
                <Button type="primary" onClick={() => onPressDoDelMany()}>
                  删除
                </Button>
                <Button onClick={() => { setDelManyShow(false); setDelManyArr([]); }}>
                  取消
                </Button>
              </Space>
            </Col>
          </Row>
        )
        : <></>
      }
      <div
        id="scrollableDiv"
        style={{
          height: ContainerHeight,
          overflow: 'auto',
          backgroundColor: StyleConfig.color_background,
        }}
      >
        {
          getSearchKey ? 
          (
            <List
              style={{backgroundColor: StyleConfig.color_background, paddingBottom: 50}}
              dataSource={searchData}
              locale={{emptyText: '未搜到相关笔记'}}
              split={false}
              renderItem={(item: NoteItem) => {
                item.text = item.text.replaceAll('http:catNote:', `${process.env.REACT_APP_WEB_DOMAIN || ''}/note/`)
                return getCurEdit === item._id ? (
                  <div style={{padding: 4}}>
                    <Editor propsNote={item} />
                  </div>
                ) : (
                  <div className='note' key={item._id} style={{borderRadius: 6, marginBottom: 10, backgroundColor: StyleConfig.color_white, padding: 14, margin: 5 }}>
                    <Row style={{color: StyleConfig.color_gray, fontSize: 12}}>
                      <div onDoubleClick={() => clickNote(item)} style={{cursor: 'pointer'}}>
                        <div  style={{float: 'left', width: 2, marginTop: 4, marginRight: 2, height: 10, backgroundColor: item.deleted ? StyleConfig.color_danger : (item.wx_in ? StyleConfig.color_green : StyleConfig.color_primary)}}>
                        </div>
							          {formatCardId(item.card_id)}
                      </div>
                      {
                          item.weight > 0 ? (
                            <div>
                              <UpCircleOutlined style={{color: StyleConfig.color_primary, paddingLeft: 4}}/>
                            </div>
                          ) : <></>
                        }
                      <div style={{position: 'absolute', right: 20}}>
                      <Button onClick={(e) => { onPressSee(item); e.stopPropagation(); }} style={{borderWidth: 0, padding: 0, marginTop: -11, marginRight: 10}} >
                        <i className="iconfont icon-detail" style={{padding: 4, color: '#495057'}}/>
                      </Button>
                      <Popover placement="rightBottom" content={item.deleted ? menuFlush(item) : menu(item)} trigger="hover">
                        <EllipsisOutlined style={{ fontSize: 20 }}/>
                      </Popover>
                      </div>
                    </Row>
                    <div style={{cursor: 'pointer',width: '100%', marginTop: 4 }} 
                      onDoubleClick={() => clickNote(item)}
                    >
                      <Html2React html={item.text || ''} imgBig={false} searchKey={getSearchKey} isList={true} maxHeight={maxItemHeight}/>
                    </div>
                      <div style={{marginTop: item.tags && item.tags.length > 0 ? 6 : 0}}>
                        {
                          (item.tags || []).map(tag => {
                            return (
                              <span
                                key={tag._id}
                                onClick={() => onPressNoteTag(tag._id)}
                                style={{ cursor: 'pointer', marginTop: 10, marginRight: 5, paddingTop: 3, paddingBottom: 3, paddingLeft: 5, paddingRight: 5, backgroundColor: StyleConfig.color_tag_back, borderRadius: 5 }}
                              >
                                <span
                                  style={{ color: StyleConfig.color_tag_text, fontSize: StyleConfig.tag_text_size }}
                                >
                                  {tag.name}
                                </span>
                              </span>
                            )
                          })
                        }
                      </div>
                      <div style={{marginTop: item.links && item.links.length > 0 ? 6 : 0}}>
                        {
                          item.links && item.links.length > 0 ? <span 
                            onClick={() => clickLinksShow(item._id)}
                          style={{cursor: 'pointer', color: StyleConfig.color_gray, fontSize: 13}}>{item.links.length}条笔记链接至此</span> : <></>
                        }
                      </div>
                  </div>
                );
              }}
            />
          ) :
          (
            <InfiniteScroll
              dataLength={data.length}
              next={appendData}
              hasMore={hasMore && data.length >= 10}
              loader={<Skeleton paragraph={{ rows: 3 }} active />}
              endMessage={data.length >= 10 ? <Divider plain>我也是有底线的~</Divider> : <></>}
              scrollableTarget="scrollableDiv"
            >
              <List
                style={{backgroundColor: StyleConfig.color_background, paddingBottom: 50}}
                dataSource={data}
                split={false}
                locale={{emptyText: '让记忆无处可藏'}}
                renderItem={(item: NoteItem) => {
                  item.text = item.text.replaceAll('http:catNote:', `${process.env.REACT_APP_WEB_DOMAIN || ''}/note/`)
                  return getCurEdit === item._id ? (
                    <div style={{padding: 4}}>
                      <Editor propsNote={item} listNote={data}/>
                    </div>
                  ) : (
                    <div className='note' key={item._id} style={{borderRadius: 6, marginBottom: 10, backgroundColor: StyleConfig.color_white, padding: 14, margin: 5}}>
                      <Row style={{color: StyleConfig.color_gray, fontSize: 12}}>
                        {
                          getCombineShow ? (
                            getCombineArr.includes(item._id) ? 
                            <CheckSquareOutlined style={{marginRight: 8, fontSize: 18}} onClick={() => onCombineItemPress(item._id, false)}/>
                            : <BorderOutlined style={{marginRight: 8, fontSize: 18}} onClick={() => onCombineItemPress(item._id, true)}/>
                          ) : <></>
                        }
                        {
                          getDelManyShow ? (
                            getDelManyArr.includes(item._id) ? 
                            <CheckSquareOutlined style={{marginRight: 8, fontSize: 18}} onClick={() => onDelManyItemPress(item._id, false)}/>
                            : <BorderOutlined style={{marginRight: 8, fontSize: 18}} onClick={() => onDelManyItemPress(item._id, true)}/>
                          ) : <></>
                        }
                        <div onDoubleClick={() => clickNote(item)} style={{cursor: 'pointer'}}>
                          <div style={{float: 'left', width: 2, marginTop: 4, marginRight: 2, height: 10, backgroundColor: item.deleted ? StyleConfig.color_danger : (item.wx_in ? StyleConfig.color_green : StyleConfig.color_primary)}}>
                          </div>
                          {formatCardId(item.card_id)}
                        </div>
                        {
                          item.weight > 0 ? (
                            <div>
                              <UpCircleOutlined style={{color: StyleConfig.color_primary, paddingLeft: 4}}/>
                            </div>
                          ) : <></>
                        }
                        <div style={{position: 'absolute', right: 20}}>
                        <Button onClick={(e) => { onPressSee(item); e.stopPropagation(); }} style={{borderWidth: 0, padding: 0, marginTop: -11, marginRight: 10}} >
                          <i className="iconfont icon-detail" style={{padding: 4, color: '#495057'}}/>
                        </Button>
                        <Popover placement="rightBottom" content={item.deleted ? menuFlush(item) : menu(item)} trigger="hover">
                          <EllipsisOutlined style={{ fontSize: 20 }}/>
                        </Popover>
                        </div>
                      </Row>
                        <div style={{width: '100%', marginTop: 4,cursor: 'pointer' }} 
                          // dangerouslySetInnerHTML={{ __html: item.text }} 
                          onDoubleClick={() => clickNote(item)}
                        >
                        <Html2React html={item.text || ''} imgBig={false} isList={true} maxHeight={maxItemHeight}/>
                        </div>
                        <div style={{marginTop: item.tags && item.tags.length > 0 ? 6 : 0}}>
                          {
                            (item.tags || []).map(tag => {
                              return (
                                <span
                                  key={tag._id}
                                  onClick={() => onPressNoteTag(tag._id)}
                                  style={{ cursor: 'pointer',marginTop: 10, marginRight: 5, paddingTop: 3, paddingBottom: 3, paddingLeft: 5, paddingRight: 5, backgroundColor: StyleConfig.color_tag_back, borderRadius: 5 }}
                                >
                                  <span
                                    style={{ color: StyleConfig.color_tag_text, fontSize: StyleConfig.tag_text_size }}
                                  >
                                    {tag.name}
                                  </span>
                                </span>
                              )
                            })
                          }
                        </div>
                        <div style={{marginTop: item.links && item.links.length > 0 ? 6 : 0}}>
                          {
                            item.links && item.links.length > 0 ? <span 
                              onClick={() => clickLinksShow(item._id)}
                            style={{cursor: 'pointer',color: StyleConfig.color_gray, fontSize: 13}}>{item.links.length}条笔记链接至此</span> : <></>
                          }
                        </div>
                      </div>
                  );
                }}
              />
            </InfiniteScroll>
          )
        }
      </div>
    </div>
  );
}