import { SettingContent, SettingRightContent, SQrcodeBox, SQrMaskButton, SQrMask, SQrMaskText, SLoginForm, SLoginFormInput, SLoginInputBox, SSendCodeButton  } from "./Setting.style";
import { Button, Menu, Avatar, Card, Col, Descriptions, Image, Input, MenuProps, message, Form, Modal, Row, Switch, Upload, Space } from 'antd';
import React, { useState } from 'react';
import { profile, authInfo, AuthInfo } from '../../store/auth';
import { reload, vipModalShow } from '../../store/note';
import { useRecoilValue, useSetRecoilState } from "recoil";
import { StyleConfig } from "../../untils/styleConfig";
import { EditOutlined, RightOutlined } from "@ant-design/icons";
import { noteApi, noteApiHost } from '../../service/api';
import copy from 'copy-to-clipboard';
import { removeToken } from "../../untils/token";
import { useNavigate } from "react-router-dom";
import ImgCrop from 'antd-img-crop';
import { formatMsToDay } from "../../helper/funcs";
import { guid } from "../../helper/funcs";
import { useEffect } from 'react';


type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('账号设置', 'account', <i className="iconfont icon-flower" style={{margin: 4, fontSize: 16}}/>),
  getItem('笔记设置', 'note', <i className="iconfont icon-aperture" style={{margin: 4, fontSize: 16}}/>),
  getItem('更多设置', 'more', <i className="iconfont icon-options" style={{marginTop: 4, marginBottom: 4, marginLeft: 2, marginRight: 2}}/>),
];

let qrCount = 30;

const useCountDown = (s: number) => {
  const [seconds, setSeconds] = useState(s);
  useEffect(() => {
    setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
  }, [seconds]);

  return [seconds, setSeconds];
};

export default function Setting() {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(profile);
  const setAuthInfo = useSetRecoilState(authInfo);
  const [getNowMenu, setNowMenu] = useState('account');
  const [getSetNickname, setNickname] = useState(false);
  const [getNickname, setNicknameValue] = useState(userInfo.nickname || '');
  const [getBindWxShow, setBindWxShow] = useState(false);
  const [getDelAccountShow, setDelAccountShow] = useState(false);
  const [getExportShow, setExportShow] = useState(false);
  const [getImageZip, setImageZip] = useState((userInfo.img_zip || false) as boolean);
  const [getNoteFold, setNoteFold] = useState((userInfo.note_fold || false) as boolean);
  const [getSetApiShow, setApiShow] = useState(false);
  const [getDelAllNoteShow, setDelAllNoteShow] = useState(false);
  const [getNoticeIn, setNoticeIn] = useState((userInfo.notice_in || false) as boolean);
  const [getNoticeSys, setNoticeSys] = useState((userInfo.notice_sys || false) as boolean);
  const setReload = useSetRecoilState(reload);
  const setVipModalShow = useSetRecoilState(vipModalShow);
  const [getUserBindWxShow, setUserBindWxShow] = useState(false);
  const [getUserUnBindWxShow, setUserUnBindWxShow] = useState(false);
  const [getUserBindPhoneShow, setUserBindPhoneShow] = useState(false);
  const [getUserUnBindPhoneShow, setUserUnBindPhoneShow] = useState(false);
  const [qrTimeout, setQrTimeout] = useState(false);
  const [qrTimer, setTimer] = useState(null as any);
  const [refreshQrcode, setRefreshQrcode] = useState(0);
  const [phoneInput, setPhoneInput] = useState('');
  const [codeInput, setCodeInput] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);
  const [seconds, setSeconds] = useCountDown(0);
  const docHost = process.env.REACT_APP_WEB_DOC_HOST;
  const [getResetPwdShow, setResetPwdShow] = useState(false);
  const [pwdInput, setPwdInput] = useState('');
  const [pwd2Input, setPwd2Input] = useState('');

  const onClickMenu = (e: any) => {
    const key = e.key;
    setNowMenu(key);
  }

  let imgUseSpace;
  const imgUse = userInfo.img_use_m || 0;
  const G = 1024*1024*1024;
  if (imgUse > G) {
    imgUseSpace = (imgUse / G).toFixed(2) + 'G';
  } else {
    imgUseSpace = (imgUse / 1024 / 1024).toFixed(2) + 'M';
  }
  let imgUsePercent;
  if (userInfo.is_vip) {
    // imgUsePercent = Math.floor(userInfo.img_use / 10737418240 * 100) + '%'; // 10G
    imgUsePercent = Math.floor(userInfo.img_use_m / 1073741824 * 100) + '%'; // 1G
  } else {
    // imgUsePercent = Math.floor(userInfo.img_use / 524288000 * 100) + '%'; // 500M
    imgUsePercent = Math.floor(userInfo.img_use_m / 62914560 * 100) + '%'; // 60M
  }

  const onSetNickname = () => {
    setNickname(true);
  }

  const onPressBig = () => {
    const w = window.open('about:blank');
    if (w && docHost) {
      w.location.href=`${docHost}/storage`;
    }
  }
  const onPressApi = () => {
    const w = window.open('about:blank');
    if (w && docHost) {
      w.location.href=`${docHost}/api`;
    }
  }

  const onSetNicknameFinish = () => {
    if (!getNickname) {
      return message.warning('昵称不能为空');
    }
    if (getNickname.length > 20) {
      return message.warning('昵称长度超限');
    }
    if (getNickname === userInfo.nickname) {
      setNickname(false);
      return;
    }
    noteApi.note.setUserInfo({ nickname: getNickname }).then(res => {
      if (res.success) {
        noteApi.user.info().then(reloadUser => {
          reloadUser.success &&  setAuthInfo({ profile: reloadUser });
        })
      } else {
        message.error(res.msg || '昵称修改失败');
      }
    }).catch(err => {
      console.log('noteAdd error', err);
      message.error('服务繁忙，请稍后再试');
    });
    setNickname(false);
  }

  const onPressDelAccount = () => {
    noteApi.note.userDelSelf().then(res => {
      if (res.success) {
        setDelAccountShow(false)
        removeToken();
        setAuthInfo({ profile: { _id: '' } } as AuthInfo);
        message.success('注销成功，已退出登录');
        navigate('/login');
      } else {
        message.error(res.msg || '注销失败');
      }
    }).catch(err => {
      console.log('noteAdd error', err);
      message.error('服务繁忙，请稍后再试');
    });
  }

  const uploadAvatar = (file: any) => {
    if (file.file) {
      const fd = new FormData();
      fd.append('file', file.file);
      const hide = message.loading('图片上传中...', 0);
      setTimeout(hide, 2500);
      noteApi.note.uploadImage(fd).then(res => {
        setTimeout(hide, 1);

        if (res.success) {
          const imageUrl = (res.files && res.files[0] && res.files[0].url) || '';
          if (imageUrl) {
            noteApi.note.setUserInfo({avatar: imageUrl}).then(setRes => {
              if (setRes.success) {
                noteApi.user.info().then(reloadUser => {
                  reloadUser.success && setAuthInfo({ profile: reloadUser });
                })
              } else {
                message.error('头像设置失败')
              }
            }).catch(setErr => {
              console.log('setErr: ', setErr);
            })
          }
        } else {
          if (res.code === 40010) {
            // TODO 图片空间不足

          } else {
            message.error(res.msg || '图片上传失败');
          }
        }
      }).catch(err => {
        setTimeout(hide, 1);
        console.log('uploadImage err:', err);
      })
    }
    return undefined;
  }

  const onImageZip = (fold: boolean) => {
    noteApi.note.setUserInfo({ img_zip: fold ? 'TRUE' : 'FALSE' }).then(res => {
      if (res.success) {
        setImageZip(fold);
        noteApi.user.info().then(reloadUser => {
          reloadUser.success &&  setAuthInfo({ profile: reloadUser });
        })
      } else {
        message.warning(res.msg || '修改失败');
      }
    }).catch(err => {
      console.log('setImageZip error', err);
      message.error('服务繁忙，请稍后再试');
    });
  }

  const onNoteFoldPress = (fold: boolean) => {
    noteApi.note.setUserInfo({ note_fold: fold ? 'TRUE' : 'FALSE' }).then(res => {
      if (res.success) {
        noteApi.user.info().then(reloadUser => {
          reloadUser.success &&  setAuthInfo({ profile: reloadUser });
        })
      } else {
        message.warning(res.msg || '修改失败');
      }
    }).catch(err => {
      console.log('setImageZip error', err);
      message.error('服务繁忙，请稍后再试');
    });
    setNoteFold(fold);
  }

  const api = `${noteApiHost}/sapi/${userInfo._id}${userInfo.api}`;
  const bdId = `bdwx${userInfo._id}`;

  const onPressCopyApi = () => {
    copy(api);
    message.success('API地址复制成功');
  }

  const onPressResetApi = () => {
    noteApi.note.resetApi().then(res => {
      if (res.success) {
        noteApi.user.info().then(reloadUser => {
          reloadUser.success &&  setAuthInfo({ profile: reloadUser });
        })
      } else {
        message.warning(res.msg || '修改失败');
      }
    }).catch(err => {
      console.log('resetApi error', err);
      message.error('服务繁忙，请稍后再试');
    });
    message.success('API地址重置成功');
    setApiShow(false);
  }

  const onPressNoticeIn = (action: boolean) => {
    noteApi.note.setUserInfo({ notice_in: action ? 'TRUE' : 'FALSE' }).then(res => {
      if (res.success) {
        noteApi.user.info().then(reloadUser => {
          reloadUser.success &&  setAuthInfo({ profile: reloadUser });
        })
      } else {
        message.warning(res.msg || '修改失败');
      }
    }).catch(err => {
      console.log('onPressNoticeIn error', err);
      message.error('服务繁忙，请稍后再试');
    });
    setNoticeIn(action);
  }

  const onPressNoticeSys = (action: boolean) => {
    noteApi.note.setUserInfo({ notice_sys: action ? 'TRUE' : 'FALSE' }).then(res => {
      if (res.success) {
        noteApi.user.info().then(reloadUser => {
          reloadUser.success &&  setAuthInfo({ profile: reloadUser });
        })
      } else {
        message.warning(res.msg || '修改失败');
      }
    }).catch(err => {
      console.log('onPressNoticeSys error', err);
      message.error('服务繁忙，请稍后再试');
    });
    setNoticeSys(action);
  }

  const onPressDelAllNote = () => {
    noteApi.note.removeAll().then(res => {
      if (res.success) {
        message.success('笔记清空成功');
        setReload(new Date().getTime());
      } else {
        message.warning(res.msg || '修改失败');
      }
    }).catch(err => {
      console.log('resetApi error', err);
      message.error('服务繁忙，请稍后再试');
    });
    setDelAllNoteShow(false);
  }

  const onPressExportNote = () => {
    const hide = message.loading('笔记导出中...', 0);
    setTimeout(hide, 2500);
    noteApi.note.exportAll().then(res => {
      setTimeout(hide, 1);
      if (res.success) {
        if (!res.link) {
          return message.warning('没有笔记供导出');
        }
        setExportShow(false);
        window.open(res.link);
      } else {
        message.warning(res.msg || '笔记导出失败');
      }
    }).catch(err => {
      setTimeout(hide, 1);
      console.log('resetApi error', err);
      message.error('服务繁忙，请稍后再试');
    });
    
  }

  const freshQrCode = () => {
    // 显示微信扫码登录二维码
    const newState = guid();
    new (window as any).WxLogin({
      self_redirect: true,
      id: "slogin",
      appid: 'wx21eedab5babf568a',
      scope: "snsapi_login",
      redirect_uri: encodeURIComponent('https://api.catnote.cn/callback/wxqrbind'),
      state: newState,
      style: "",
      href: "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKIGRpc3BsYXk6IG5vbmU7Cn0KLmltcG93ZXJCb3ggLnN0YXR1cy5zdGF0dXNfYnJvd3NlciB7CiBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94IC5xcmNvZGUgewogYm9yZGVyOiBub25lOwogd2lkdGg6IDE2MHB4OwogaGVpZ2h0OiAxNjBweDsKfQouaW1wb3dlckJveCAuaW5mb3sKIGRpc3BsYXk6IG5vbmUKfQ=="
    });

    // 每隔一秒轮询微信扫码登录接口,轮询60次
    const timer = setInterval(async () => {
      console.log(qrCount);
      if (qrCount <= 0) {
        setQrTimeout(true);
        clearInterval(timer);
        qrTimer && clearInterval(qrTimer);
        return;
      }
      qrCount = qrCount - 1;

      const res = await noteApi.login.bindQrCheck(newState);
      
      if (res && res.success) {
        if (res.data === '未扫码') {
          return;
        }
        noteApi.user.info().then(reloadUser => {
          reloadUser.success &&  setAuthInfo({ profile: reloadUser });
        });
        message.success('微信绑定成功');
        setUserBindWxShow(false);
        clearInterval(timer);
        qrTimer && clearInterval(qrTimer);
      } else {
          message.error(`${res.msg}`);
          setQrTimeout(true);
          clearInterval(timer);
          qrTimer && clearInterval(qrTimer);
      }
      
    }, 1000);
    setTimer(timer);
  }

  useEffect(() => {
    if (refreshQrcode !== 0) {
      freshQrCode();
    }
  }, [refreshQrcode]);


  const onPressBindWx = () => {
    setUserBindWxShow(true);
    setTimeout(() => {
      freshQrCode();
    }, 1000);
  }

  const onPressUnBindWx = () => {
    noteApi.user.unbind('wx').then(res => {
      if (res.success) {
        noteApi.user.info().then(reloadUser => {
          reloadUser.success &&  setAuthInfo({ profile: reloadUser });
        });
        message.success('解绑微信成功');
      } else {
        message.warning(res.msg || '解绑失败');
      }
    }).catch(err => {
      console.log('resetApi error', err);
      message.error('服务繁忙，请稍后再试');
    });
    setUserUnBindWxShow(false);
  }

  const onPressBindPhone = () => {
    setUserBindPhoneShow(true);
  }

  const onPressUnBindPhone = () => {
    noteApi.user.unbind('phone').then(res => {
      if (res.success) {
        noteApi.user.info().then(reloadUser => {
          reloadUser.success &&  setAuthInfo({ profile: reloadUser });
        });
        message.success('解绑手机号成功');
      } else {
        message.warning(res.msg || '解绑失败');
      }
    }).catch(err => {
      console.log('resetApi error', err);
      message.error('服务繁忙，请稍后再试');
    });
    setUserUnBindPhoneShow(false);
  }

  // bind phone
  const onFinish = async (values: any) => {
    const { mobile, code } = values;
    let phone = mobile;
    setLoginLoading(true);
    const res = await noteApi.user.bindPhone(phone, code);
    setLoginLoading(false);

    if (res && res.success) {
      noteApi.user.info().then(reloadUser => {
        reloadUser.success &&  setAuthInfo({ profile: reloadUser });
        setUserBindPhoneShow(false);
      });
      message.success('手机号绑定成功');
    } else {
      message.error(`${res.msg}`);
    }
  };

  const sendCode = async () => {
    if (!phoneInput) {
      message.warning('请输入手机号');
      return;
    }
    (setSeconds as any)(60);

    const res = await noteApi.login.sendCode(phoneInput);

    if (res && res.success) {
      message.info('短信发送成功');
    } else {
      message.error(`短信发送失败 ${res.msg}`);
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const checkMobile = (_: any, value: string) => {
    if (value && value.length > 0) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('请输入手机号'));
  };

  const checkCode = (_: any, value: string) => {
    if (value && value.length > 0) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('请输入验证码'));
  };

  const onFinishResetPwd = async (values: any) => {
    if (!userInfo.phone) {
      message.warning('请先绑定手机号');
      return;
    }
    if (pwdInput !== pwd2Input) {
      message.warning('两次输入的密码不一致');
      return;
    }

    let phone = userInfo.phone;
    const code = codeInput;
    const password = pwdInput;
    const password2 = pwd2Input;
    setLoginLoading(true);
    const res = await noteApi.login.resetPwd(phone, code, password, password2);
    setLoginLoading(false);

    if (res && res.success) {

      setResetPwdShow(false);

      message.success('设置成功，请使用新密码登录');

      removeToken();
      setAuthInfo({ profile: { _id: '' } } as AuthInfo);
      navigate('/login');
      
    } else {
      message.error(`${res.msg || '设置失败'}`);
    }
  }

  const onFinishResetFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  }


  const checkResetCode = (_: any, value: string) => {
    if (codeInput && codeInput.length > 0) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('请输入验证码'));
  };

  const checkPassword = (_: any, value: string) => {
    if (pwdInput && pwdInput.length > 0) {
      if (pwdInput.length > 50) {
        Promise.reject(new Error('密码长度过长'));
      }
      return Promise.resolve();
    }
    return Promise.reject(new Error('请输入密码'));
  };

  const sendResetCode = async () => {
    if (!userInfo.phone) {
      message.warning('请先绑定手机号');
      return;
    }
    (setSeconds as any)(60);

    const res = await noteApi.login.sendCode(userInfo.phone);

    if (res && res.success) {
      message.info('短信发送成功');
    } else {
      message.error(`短信发送失败 ${res.msg}`);
    }
  }


  return (
    <SettingContent style={{ height: window.innerHeight - 64 }}>
      <Modal title={userInfo.has_pwd ? '重置密码': '设置密码'} open={getResetPwdShow} onCancel={() => setResetPwdShow(false)} footer={null}>
        <Row justify="center" style={{paddingTop: 30}}>
          <Col>
            <Form
              name="resetPwd"
              onFinish={onFinishResetPwd}
              onFinishFailed={onFinishResetFailed}
              autoComplete="off"
            >
              <Form.Item
                name="mobile"
              >
                <SLoginInputBox>
                  <SLoginFormInput 
                    placeholder="请输入手机号码"
                    value={userInfo.phone || ''}
                    size='large'
                    disabled={true}
                  />
                </SLoginInputBox>
              </Form.Item>

              <Form.Item
                name="code"
                rules={[{ validator: checkResetCode }]}
              >
                <SLoginInputBox>
                  <SLoginFormInput 
                    placeholder="短信验证码"
                    value={codeInput}
                    size='large'
                    onChange={(change) => {setCodeInput(change.target.value)}}
                  />
                  <SSendCodeButton
                    onClick={sendResetCode}
                    disabled={seconds !== 0}
                  >
                    {seconds > 0 ? `${seconds}秒后可重发` : "获取短信验证码"}
                  </SSendCodeButton>
                </SLoginInputBox>
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ validator: checkPassword }]}
              >
                <SLoginInputBox>
                  <SLoginFormInput 
                    placeholder="密码"
                    value={pwdInput}
                    size='large'
                    onChange={(change)=>{setPwdInput(change.target.value)}}
                  />
                </SLoginInputBox>
              </Form.Item>

              <Form.Item
                name="password2"
              >
                <SLoginInputBox>
                  <SLoginFormInput 
                    placeholder="再次输入密码"
                    value={pwd2Input}
                    size='large'
                    onChange={(change)=>{setPwd2Input(change.target.value)}}
                  />
                </SLoginInputBox>
              </Form.Item>
              
              <Form.Item>
                <Row justify="center" style={{width: '100%'}}>
                  <Col style={{width: '100%'}}>
                    <Button type="primary" htmlType="submit" loading={loginLoading} style={{width: '100%'}}>
                      确定
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal title="微信扫码绑定" open={getUserBindWxShow} onCancel={() => {
        setUserBindWxShow(false);
        qrTimer && clearInterval(qrTimer);
      }} footer={null}>
        <Row justify="center">
          <Col>
            <SQrcodeBox>
              <div id='slogin'></div>
              {
                qrTimeout ? (
                  <div>
                    <SQrMask></SQrMask>
                      <SQrMaskText>二维码已失效</SQrMaskText>
                      <SQrMaskButton onClick={() => {
                        qrTimer && clearInterval(qrTimer);
                        qrCount = 30;
                        setRefreshQrcode(new Date().getTime());
                        setQrTimeout(false);
                      }}>请点击刷新</SQrMaskButton>
                  </div>
                ) : <></>
              }
            </SQrcodeBox>
          </Col>
        </Row>
      </Modal>
      <Modal title="解绑微信" open={getUserUnBindWxShow} onCancel={() => setUserUnBindWxShow(false)} onOk={() => {
          onPressUnBindWx()
        }}>
        <Row justify="center">
          <Col>
            确定解绑当前微信吗？
          </Col>
        </Row>
      </Modal>
      <Modal title="绑定手机号" open={getUserBindPhoneShow} onCancel={() => setUserBindPhoneShow(false)} footer={null}>
        <Row justify="center">
          <Col>
            <SLoginForm
              name="login"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="mobile"
                rules={[{ validator: checkMobile }]}
              >
                <SLoginInputBox>
                  <SLoginFormInput 
                    placeholder="请输入手机号码"
                    value={phoneInput}
                    size='large'
                    onChange={(change)=>{setPhoneInput(change.target.value)}}
                  />
                </SLoginInputBox>
              </Form.Item>

              <Form.Item
                name="code"
                rules={[{ validator: checkCode }]}
              >
                <SLoginInputBox>
                  <SLoginFormInput 
                    placeholder="输入短信验证码"
                    value={codeInput}
                    size='large'
                    onChange={(change) => {setCodeInput(change.target.value)}}
                  />
                  <SSendCodeButton
                    onClick={sendCode}
                    disabled={seconds !== 0}
                  >
                    {seconds > 0 ? `${seconds}秒后可重发` : "获取短信验证码"}
                  </SSendCodeButton>
                </SLoginInputBox>
              </Form.Item>

              <Form.Item>
                <Row justify="center">
                  <Col>
                    <Button type="primary" htmlType="submit" loading={loginLoading}>
                      绑定
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </SLoginForm>
          </Col>
        </Row>
      </Modal>
      <Modal title="解绑手机号" open={getUserUnBindPhoneShow} onCancel={() => setUserUnBindPhoneShow(false)} onOk={() => {
          onPressUnBindPhone()
        }}>
        <Row justify="center">
          <Col>
            确定解绑当前手机号吗？
          </Col>
        </Row>
      </Modal>
      <Modal title="微信录入绑定" open={getBindWxShow} onCancel={() => setBindWxShow(false)} okText="复制绑定ID" 
        onOk={() => {
          copy(bdId);
          message.success('绑定ID复制成功');
        }}>
        <Row justify="center">
          <Col>
            关注微信公众号<span style={{color: StyleConfig.color_primary,fontSize: 18}}>catnote卡片笔记</span>，输入绑定ID与当前账号绑定
          </Col>
        </Row>
        <Row justify="center" style={{marginTop: 20, borderRadius: 5, backgroundColor: StyleConfig.color_muted_light, padding: 5}}>
          <Col>
            { bdId }
          </Col>
        </Row>
      </Modal>
      <Modal title="注销账号" open={getDelAccountShow} onCancel={() => setDelAccountShow(false)} onOk={() => onPressDelAccount()}>
        <Row justify="center">
          <Col>
            注销后再无法使用，确认要注销吗？
          </Col>
        </Row>
      </Modal>
      <Modal title="重置笔记输入API地址" open={getSetApiShow} onCancel={() => setApiShow(false)} onOk={() => onPressResetApi()} okText="重置">
        <Row justify="center">
          <Col>
            当前API地址: 
            <Row justify="center" style={{marginTop: 20, marginBottom: 20, borderRadius: 5, backgroundColor: StyleConfig.color_muted_light, padding: 5}}>
              <Col>
              {api}
              </Col>
            </Row>
            确认要重置生成新地址吗？
          </Col>
        </Row>
      </Modal>
      <Modal title="导出所有笔记" open={getExportShow} onCancel={() => setExportShow(false)} onOk={() => onPressExportNote()}>
        <Row justify="center">
          <Col>
            { userInfo.is_vip ? '' : '普通用户每月只能导出一次，'  }确定导出吗？
          </Col>
        </Row>
      </Modal>
      <Modal title="清空笔记" open={getDelAllNoteShow} onCancel={() => setDelAllNoteShow(false)} onOk={() => onPressDelAllNote()}>
        <Row justify="center">
          <Col>
            确认要删除所有笔记吗？
          </Col>
        </Row>
      </Modal>
      <div style={{ width: '100%' }}>
        <Menu
          defaultSelectedKeys={['account']}
          items={items}
          mode="horizontal" 
          onClick={(e) => onClickMenu(e)}
          style={{borderRadius: 8}}
        />
      </div>
      <SettingRightContent>
        {
          getNowMenu === 'account' ? 
          (
            <Card>
              <Card.Grid style={{width: '100%', textAlign: 'center'}}>
                <Descriptions title="" bordered column={1}>
                  <Descriptions.Item label="头像">
                    <Avatar size="large" src={<Image src={(userInfo && userInfo.avatar) || 'https://file.catnote.cn/img/log_new.jpeg'} preview={true} />} />
                    <ImgCrop grid rotate>
                      <Upload
                        customRequest={(file) => uploadAvatar(file)}
                        maxCount={1}
                        showUploadList={false}
                      >
                        <EditOutlined key="edit" style={{cursor: 'pointer', marginLeft: 20}}/>
                      </Upload>
                    </ImgCrop>
                  </Descriptions.Item>
                  <Descriptions.Item label="昵称">
                    {
                      getSetNickname ? 
                      (
                        <Input.Group compact>
                          <Input style={{ width: 'calc(100% - 120px)' }} value={getNickname} onChange={(v) => setNicknameValue(v.target.value)} 
                            maxLength={20}
                          />
                          <Button type="primary" onClick={() => onSetNicknameFinish()}>提交</Button>
                        </Input.Group>
                      ) : (userInfo.nickname || '')
                    }
                    {
                      getSetNickname ? <></> : <EditOutlined key="edit" style={{cursor: 'pointer', marginLeft: 20}} onClick={() => onSetNickname()}/>
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label="微信">
                    {
                      userInfo.bd_wx ? '已绑定' : '未绑定'
                    }
                    {
                      (!userInfo.bd_wx && userInfo.phone) ? 
                      <Button type="link" size="small" style={{color: StyleConfig.color_action}} onClick={onPressBindWx}>去绑定</Button> : ''
                    }
                    {
                      (userInfo.bd_wx && userInfo.phone) ? <Button type="link" size="small" style={{color: StyleConfig.color_action}} onClick={() => setUserUnBindWxShow(true)}>解绑</Button> : ''
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label="手机号">
                    {userInfo.phone}
                    {
                      (!userInfo.phone && userInfo.bd_wx) ? <Button type="link" size="small" style={{color: StyleConfig.color_action}} onClick={onPressBindPhone}>去绑定</Button> : ''
                    }
                    {
                      (userInfo.phone && userInfo.bd_wx) ? <Button type="link" size="small" style={{color: StyleConfig.color_action}} onClick={() => setUserUnBindPhoneShow(true)}>解绑</Button> : ''
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label="登录密码">
                    {
                      !userInfo.has_pwd ? <Button type="link" size="small" style={{color: StyleConfig.color_action}} onClick={() => setResetPwdShow(true)}>设置</Button> : ''
                    }
                    {
                      userInfo.has_pwd ? <Button type="link" size="small" style={{color: StyleConfig.color_action}} onClick={() => setResetPwdShow(true)}>重置</Button> : ''
                    }
                  </Descriptions.Item>
                </Descriptions>

              </Card.Grid>
              {/* <Card.Grid style={{width: '100%', textAlign: 'center'}}>
                <Descriptions title="" bordered column={1}>
                  <Descriptions.Item label="微信录入绑定">
                    {userInfo.bd_wx ? '已绑定' : '未绑定'}
                    <EditOutlined key="edit" style={{cursor: 'pointer', marginLeft: 20}} onClick={() => setBindWxShow(true)}/>
                  </Descriptions.Item>
                </Descriptions>
              </Card.Grid> */}
              <Card.Grid style={{width: '100%', textAlign: 'center'}}>
                <Descriptions title="" bordered column={1}>
                  <Descriptions.Item>
                    CatNote会员（畅享微信语音输入、文字识别等权益）
                    {
                      userInfo.vip_exp_at ? (
                        <span style={{fontWeight: 'bold'}}>{userInfo.vip_exp_at === -1 ? '无限期' : `有效期至${(userInfo.vip_exp_at && formatMsToDay(userInfo.vip_exp_at)) || ''} `}</span>
                      ): <></>                    
                    }
                    &nbsp;
                    {
                      userInfo.vip_exp_at !== -1 ? (
                        userInfo.vip_exp_at ? (
                          <Button type="primary" onClick={() => setVipModalShow(true)}>
                            立即续订
                          </Button>
                        ) : (
                          <Button type="primary" onClick={() => setVipModalShow(true)}>
                            立即开通
                          </Button>
                        )
                      ) : <></>
                    }
                  </Descriptions.Item>
                </Descriptions>
              </Card.Grid>
              <Card.Grid style={{width: '100%', textAlign: 'center'}}>
                <Button onClick={() => setDelAccountShow(true)} danger type="primary">
                  注销账号
                </Button>
              </Card.Grid>
            </Card>
          ) : getNowMenu === 'note' ? (
            <Card>
              <Card.Grid style={{width: '100%', textAlign: 'center'}}>
                <Descriptions title="" bordered column={1}>
                  <Descriptions.Item label="图片存储空间">
                    已用{imgUseSpace}/每月{userInfo.is_vip ? '1G' : '60M'}
                    <div style={{paddingTop: 2, paddingBottom: 10, paddingLeft: 14, paddingRight: 14}}>
                      <div style={{height: 10, width: '100%', alignSelf: 'center', marginLeft: 5, backgroundColor: StyleConfig.color_middle, borderRadius: 5 }}>
                        <div style={{height: 10, width: imgUsePercent, backgroundColor: StyleConfig.color_action, borderWidth: 0, borderRadius: 5 }}></div>
                      </div>
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item label="图片自动压缩">
                    <Switch defaultChecked onChange={onImageZip} checked={getImageZip} />
                  </Descriptions.Item>
                  <Descriptions.Item>
                    <Button type="link" style={{color: StyleConfig.color_black}} onClick={() => onPressBig()}>
                      CatNote会员享超大空间+原图上传 <RightOutlined />
                    </Button>
                  </Descriptions.Item>
                </Descriptions>
              </Card.Grid>
              <Card.Grid style={{width: '100%', textAlign: 'center'}}>
                <Descriptions title="" bordered column={1}>
                  <Descriptions.Item label="笔记过长隐藏">
                    <Switch defaultChecked onChange={onNoteFoldPress} checked={getNoteFold} />
                  </Descriptions.Item>
                </Descriptions>
              </Card.Grid>
              <Card.Grid style={{width: '100%', textAlign: 'center'}}>
                <Descriptions title="" bordered column={1}>
                  <Descriptions.Item label="专属API">
                    <Button type="link" onClick={onPressCopyApi}>
                      复制地址
                    </Button>
                    <Button type="link" onClick={() => setApiShow(true)}>
                      重置地址
                    </Button>
                  </Descriptions.Item>
                  <Descriptions.Item>
                    <Button type="link" style={{color: StyleConfig.color_black}} onClick={() => onPressApi()}>
                      如何使用 <RightOutlined />
                    </Button>
                    <Button type="link" style={{color: StyleConfig.color_black}} onClick={() => onPressApi()}>
                      CatNote会员享API发布笔记 <RightOutlined />
                    </Button>
                  </Descriptions.Item>
                </Descriptions>
              </Card.Grid>
              <Card.Grid style={{width: '100%', textAlign: 'center'}}>
                <Space>
                  <Button type="primary" onClick={() => setExportShow(true)}>
                    导出所有笔记
                  </Button>
                  <Button type="primary" danger onClick={() => setDelAllNoteShow(true)}>
                    清空笔记
                  </Button>
                </Space>
              </Card.Grid>
            </Card>
          ) : (
            <Card>
              <Card.Grid style={{width: '100%', textAlign: 'center'}}>
                <Descriptions title="" bordered column={1}>
                  <Descriptions.Item label="接收站内消息">
                    <Switch onChange={onPressNoticeIn} checked={getNoticeIn}/>
                  </Descriptions.Item>
                  <Descriptions.Item label="接收系统消息">
                    <Switch onChange={onPressNoticeSys} checked={getNoticeSys}/>
                  </Descriptions.Item>
                </Descriptions>
              </Card.Grid>
            </Card>
          )
        }
      </SettingRightContent>
    </SettingContent>
  );
}