import { Container } from "./Mobile.style";
import { Modal } from 'antd';
import { useState } from 'react';

export default function Mobile () {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClickDownload = () => {
    // 是ios还是android手机里
    const userAgent = navigator.userAgent;
    const isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    const ua = userAgent.toLowerCase();
    const isWeixin = ua.indexOf('micromessenger') !== -1;
    if (isiOS) {
      if (isWeixin) {
        setIsModalOpen(true);
      } else {
        window.location.href='https://itunes.apple.com/cn/app/id1663647332?mt=8';
      }
    } else {
      window.location.href='https://a.app.qq.com/o/simple.jsp?pkgname=com.catnote';
    }
  }

  return (
    <Container>
      <Modal title="" open={isModalOpen} onCancel={() => { setIsModalOpen(false); }} closable={false} footer={null}>
        <p>请点击右上角···，选择在浏览器中打开</p>
      </Modal>

      <div style={{position: 'absolute', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end'}}>
        <div style={{width: '100%', height: '50%'}} onClick={ onClickDownload } >
        </div>

        <div style={{marginBottom: '10%', marginTop: 5}}>
          <a style={{color: '#FFFFFF', textDecoration: 'none'}} href='https://help.catnote.cn' target='_self' >用户手册</a> &nbsp;&nbsp;
          <a style={{color: '#FFFFFF', textDecoration: 'none'}} href='/?nm=yes' target='_self' >访问电脑版</a>
        </div>
      </div>
    </Container>
  )
}