import { AccountContentBox, AccountTab, AccountTabActive, AccountTabNormal, Container, ContainerRight, ContainLeft, FormFooter, FormFooterA, FormFooterButton, GrayLine, LeftText, LeftTextDesc, LeftTextTitle, LoginBox, LoginBoxPwd, LoginFormInputPwd, LoginTip, QrcodeBox, QrMask, QrMaskButton, QrMaskText, QrTip, QrTipText, RightBox, RightLoginBox } from "./Login.style";
import { useState } from 'react';
import { LoginForm, LoginFormInput, LoginInputBox, SendCodeButton } from './Login.style';
import { Button } from './Login.style';
import { Col, Form, message, Row, Modal, Checkbox } from 'antd';
import { noteApi } from '../../service/api';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { authInfo } from '../../store/auth';
import { useEffect } from 'react';
import { ReadFilled, ThunderboltFilled } from '@ant-design/icons';
import { setToken } from "../../untils/token";
import { guid } from "../../helper/funcs";
import { StyleConfig } from "../../untils/styleConfig";


export const GLOBAL_LOGIN_ROUTE_KEY = 'login_to_route';

export const genReturnUrl = () => {
  const toLink = window.location.toString().replace(new RegExp(`${window.location.origin}`), '');
  if (toLink.indexOf('/login') === -1) {
    localStorage.setItem(GLOBAL_LOGIN_ROUTE_KEY, toLink);
  }
  return `${window.location.origin}/login`;
}

const useCountDown = (s: number) => {
  const [seconds, setSeconds] = useState(s);
  useEffect(() => {
    setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
  }, [seconds]);

  return [seconds, setSeconds];
};

let qrCount = 60;

export default function Login() {
  const [phoneInput, setPhoneInput] = useState('');
  const [codeInput, setCodeInput] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);
  const [seconds, setSeconds] = useCountDown(0);
  const [pwdInput, setPwdInput] = useState('');
  const [pwd2Input, setPwd2Input] = useState('');

  const [tabActive, setTabActive] = useState('w');
  const [refreshQrcode, setRefreshQrcode] = useState(0);
  const [qrTimeout, setQrTimeout] = useState(false);
  const [qrTimer, setTimer] = useState(null as any);
  
  const [showRegister, setShowRegister] = useState(false);
  const [showForgetPwd, setShowForgetPwd] = useState(false);
  const [checkAgree, setChecktAgree] = useState(false);

  const setAuth = useSetRecoilState(authInfo);
  const navigate = useNavigate();
  const docHost = process.env.REACT_APP_WEB_DOC_HOST;

  useEffect(() => {
    // 显示微信扫码登录二维码
    const newState = guid();
    new (window as any).WxLogin({
      self_redirect: true,
      id: "login",
      appid: 'wx21eedab5babf568a',
      scope: "snsapi_login",
      redirect_uri: encodeURIComponent('https://api.catnote.cn/callback/wxqrlogin'),
      state: newState,
      style: "",
      href: "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKIGRpc3BsYXk6IG5vbmU7Cn0KLmltcG93ZXJCb3ggLnN0YXR1cy5zdGF0dXNfYnJvd3NlciB7CiBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94IC5xcmNvZGUgewogYm9yZGVyOiBub25lOwogd2lkdGg6IDE2MHB4OwogaGVpZ2h0OiAxNjBweDsKfQouaW1wb3dlckJveCAuaW5mb3sKIGRpc3BsYXk6IG5vbmUKfQ=="
    });

    // 每隔一秒轮询微信扫码登录接口,轮询60次
    const timer = setInterval(async () => {
      console.log(qrCount);
      if (qrCount <= 0) {
        setQrTimeout(true);
        clearInterval(timer);
        qrTimer && clearInterval(qrTimer);
        return;
      }
      qrCount = qrCount - 1;

      const res = await noteApi.login.qrCheck(newState);
      if (res && res.success) {
        if (res.data === '未扫码') {
          return;
        }
        setAuth({ profile: res.user });
        res.token && setToken(res.token);
        clearInterval(timer);
        qrTimer && clearInterval(qrTimer);
        navigate(localStorage.getItem(GLOBAL_LOGIN_ROUTE_KEY) || '/');
      } else {
        message.error(`登录失败 ${res.msg}`);
      }
      
    }, 1000);
    setTimer(timer);

  }, [refreshQrcode]);

  const onFinish = async (values: any) => {
    // const { mobile, code } = values;
    // let phone = mobile;
    setLoginLoading(true);
    const res = await noteApi.login.login(phoneInput, codeInput);
    setLoginLoading(false);

    if (res && res.success) {
      qrTimer && clearInterval(qrTimer);

      setAuth({ profile: res.user });
      res.token && setToken(res.token);
      
      navigate(localStorage.getItem(GLOBAL_LOGIN_ROUTE_KEY) || '/');
    } else {
      message.error(`${res.msg || '登录失败'}`);
    }
  };

  const onFinishPwd = async (values: any) => {
    // const { mobile, password } = values;
    const password = pwdInput;
    let phone = phoneInput;
    setLoginLoading(true);
    const res = await noteApi.login.loginPwd(phone, password);
    setLoginLoading(false);

    if (res && res.success) {
      qrTimer && clearInterval(qrTimer);

      setAuth({ profile: res.user });
      res.token && setToken(res.token);
      
      navigate(localStorage.getItem(GLOBAL_LOGIN_ROUTE_KEY) || '/');
    } else {
      message.error(`${res.msg || '登录失败'}`);
    }
  };

  const sendCode = async () => {
    if (!phoneInput) {
      message.warning('请输入手机号');
      return;
    }
    (setSeconds as any)(60);
   
    const res = await noteApi.login.sendCode(phoneInput);

    if (res && res.success) {
      message.info('短信发送成功');
    } else {
      message.error(`短信发送失败 ${res.msg}`);
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onFinishPwdFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const checkMobile = (_: any, value: string) => {
    if (phoneInput && phoneInput.length > 0) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('请输入手机号'));
  };

  const checkCode = (_: any, value: string) => {
    if (codeInput && codeInput.length > 0) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('请输入验证码'));
  };
  
  const checkPassword = (_: any, value: string) => {
    if (pwdInput && pwdInput.length > 0) {
      if (pwdInput.length > 50) {
        Promise.reject(new Error('密码长度过长'));
      }
      return Promise.resolve();
    }
    return Promise.reject(new Error('请输入密码'));
  };

  const clickButton = (type: string) => {
    let link = docHost;
    switch(type) {
      case 'help':
        link = docHost;
        break;
      case 'download':
        link = `${docHost}/download`;
        break;
      case 'userAgreement':
        link = `${docHost}/useragree`;
        break;
      case 'privacyAgreement':
        link = `${docHost}/privacy`;
        break;
      default:
        break;
    }
    const w = window.open('about:blank');
    if (w && link) {
      w.location.href=link;
    }
  }

  const onPressShowRegister = () => {
    setShowRegister(true)
  }

  const onPressForgetPwd = () => {
    setShowForgetPwd(true)
  }

  const onFinishRegister = async (values: any) => {
    if (pwdInput !== pwd2Input) {
      message.warning('两次输入的密码不一致');
      return;
    }
    if (!checkAgree) {
      message.warning('请先阅读并同意用户协议与隐私协议');
      return;
    }

    // const { mobile, code, password, password2 } = values;
    let phone = phoneInput;
    const code = codeInput;
    const password = pwdInput;
    const password2 = pwd2Input;
    setLoginLoading(true);
    const res = await noteApi.login.register(phone, code, password, password2);
    setLoginLoading(false);

    if (res && res.success) {
      qrTimer && clearInterval(qrTimer);

      setAuth({ profile: res.user });
      res.token && setToken(res.token);

      setShowRegister(false);
      
      navigate(localStorage.getItem(GLOBAL_LOGIN_ROUTE_KEY) || '/');
    } else {
      message.error(`${res.msg || '注册失败'}`);
    }
  }

  const onFinishRegisterFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  }

  const onFinishResetFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  }

  const onFinishResetPwd = async (values: any) => {
    if (pwdInput !== pwd2Input) {
      message.warning('两次输入的密码不一致');
      return;
    }

    // const { mobile, code, password, password2 } = values;
    let phone = phoneInput;
    const code = codeInput;
    const password = pwdInput;
    const password2 = pwd2Input;
    setLoginLoading(true);
    const res = await noteApi.login.resetPwd(phone, code, password, password2);
    setLoginLoading(false);

    if (res && res.success) {

      setShowForgetPwd(false);

      message.success('重置成功，请使用新密码登录')
      
      navigate(localStorage.getItem(GLOBAL_LOGIN_ROUTE_KEY) || '/');
    } else {
      message.error(`${res.msg || '重置失败'}`);
    }
  }

  return (
    <Container>
       <Modal title="账号注册" open={showRegister} onCancel={() => setShowRegister(false)} footer={null}>
        <Row justify="center" style={{paddingTop: 30}}>
          <Col>
            <Form
              name="register"
              onFinish={onFinishRegister}
              onFinishFailed={onFinishRegisterFailed}
              autoComplete="off"
            >
              <Form.Item
                name="mobile"
                rules={[{ validator: checkMobile }]}
              >
                <LoginInputBox>
                  <LoginFormInput 
                    placeholder="请输入手机号码"
                    value={phoneInput}
                    size='large'
                    onChange={(change)=>{setPhoneInput(change.target.value)}}
                  />
                </LoginInputBox>
              </Form.Item>

              <Form.Item
                name="code"
                rules={[{ validator: checkCode }]}
              >
                <LoginInputBox>
                  <LoginFormInput 
                    placeholder="短信验证码"
                    value={codeInput}
                    size='large'
                    onChange={(change) => {setCodeInput(change.target.value)}}
                  />
                  <SendCodeButton
                    onClick={sendCode}
                    disabled={seconds !== 0}
                  >
                    {seconds > 0 ? `${seconds}秒后可重发` : "获取短信验证码"}
                  </SendCodeButton>
                </LoginInputBox>
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ validator: checkPassword }]}
              >
                <LoginInputBox>
                  <LoginFormInput 
                    placeholder="密码"
                    value={pwdInput}
                    size='large'
                    onChange={(change)=>{setPwdInput(change.target.value)}}
                  />
                </LoginInputBox>
              </Form.Item>

              <Form.Item
                name="password2"
              >
                <LoginInputBox>
                  <LoginFormInput 
                    placeholder="再次输入密码"
                    value={pwd2Input}
                    size='large'
                    onChange={(change)=>{setPwd2Input(change.target.value)}}
                  />
                </LoginInputBox>
              </Form.Item>

              <Row style={{width: '100%', padding: 5, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <Checkbox onChange={(e) => {
                  setChecktAgree(true)
                }} checked={checkAgree}
                  style={{color: 'gray'}}
                >
                  <span>
                  我已阅读并同意
                  </span>
                  <FormFooterA
                    onClick={() => clickButton('userAgreement')}
                  >《用户协议》</FormFooterA>和<FormFooterA 
                    onClick={() => clickButton('privacyAgreement')}
                  >《隐私协议》</FormFooterA>
                </Checkbox>
              </Row>

              <Form.Item>
                <Row justify="center" style={{width: '100%'}}>
                  <Col style={{width: '100%'}}>
                    <Button type="primary" htmlType="submit" loading={loginLoading} style={{width: '100%'}}>
                      确定
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      <Modal title="重置密码" open={showForgetPwd} onCancel={() => setShowForgetPwd(false)} footer={null}>
        <Row justify="center" style={{paddingTop: 30}}>
          <Col>
            <Form
              name="resetPwd"
              onFinish={onFinishResetPwd}
              onFinishFailed={onFinishResetFailed}
              autoComplete="off"
            >
              <Form.Item
                name="mobile"
                rules={[{ validator: checkMobile }]}
              >
                <LoginInputBox>
                  <LoginFormInput 
                    placeholder="请输入手机号码"
                    value={phoneInput}
                    size='large'
                    onChange={(change)=>{setPhoneInput(change.target.value)}}
                  />
                </LoginInputBox>
              </Form.Item>

              <Form.Item
                name="code"
                rules={[{ validator: checkCode }]}
              >
                <LoginInputBox>
                  <LoginFormInput 
                    placeholder="短信验证码"
                    value={codeInput}
                    size='large'
                    onChange={(change) => {setCodeInput(change.target.value)}}
                  />
                  <SendCodeButton
                    onClick={sendCode}
                    disabled={seconds !== 0}
                  >
                    {seconds > 0 ? `${seconds}秒后可重发` : "获取短信验证码"}
                  </SendCodeButton>
                </LoginInputBox>
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ validator: checkPassword }]}
              >
                <LoginInputBox>
                  <LoginFormInput 
                    placeholder="密码"
                    value={pwdInput}
                    size='large'
                    onChange={(change)=>{setPwdInput(change.target.value)}}
                  />
                </LoginInputBox>
              </Form.Item>

              <Form.Item
                name="password2"
              >
                <LoginInputBox>
                  <LoginFormInput 
                    placeholder="再次输入密码"
                    value={pwd2Input}
                    size='large'
                    onChange={(change)=>{setPwd2Input(change.target.value)}}
                  />
                </LoginInputBox>
              </Form.Item>
              
              <Form.Item>
                <Row justify="center" style={{width: '100%'}}>
                  <Col style={{width: '100%'}}>
                    <Button type="primary" htmlType="submit" loading={loginLoading} style={{width: '100%'}}>
                      确定
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>


      <Row>
        <Col xs={0} sm={0} md={14} lg={14} xl={14} xxl={14}>
          <ContainLeft>
            <LeftText>
              <a href="https://catnote.cn" >
                <img src="https://file.catnote.cn/img/wlogin.png" alt="CatNote" height="50px" />
              </a>
              <br />
              <LeftTextTitle>
                新一代卡片笔记工具
              </LeftTextTitle>
              <LeftTextDesc>
                CatNote基于卢曼卡片笔记法的理念设计，让你方便地记录知识碎片，关联与提炼知识
              </LeftTextDesc>
            </LeftText>
          </ContainLeft>
        </Col>
        <Col xs={18} sm={24} md={10} lg={10} xl={10} xxl={10}>
          <ContainerRight>
          <RightBox>
            <RightLoginBox>
              <AccountTab>
                {
                  tabActive === 'w' ? <AccountTabActive>
                  微信登录
                  </AccountTabActive> : <AccountTabNormal onClick={() => {
                    setTabActive('w');
                    qrTimer && clearInterval(qrTimer);
                    qrCount = 60;
                    setRefreshQrcode(new Date().getTime());
                  }}>
                  微信登录
                </AccountTabNormal>
                }
                {
                  tabActive === 's' ? <AccountTabActive>
                  短信登录
                  </AccountTabActive> : <AccountTabNormal onClick={() => {
                    setTabActive('s');
                    qrTimer && clearInterval(qrTimer);
                  }}>
                  短信登录
                </AccountTabNormal>
                }
                {
                  tabActive === 'p' ? <AccountTabActive>
                  密码登录
                  </AccountTabActive> : <AccountTabNormal onClick={() => {
                    setTabActive('p');
                    qrTimer && clearInterval(qrTimer);
                  }}>
                  密码登录
                </AccountTabNormal>
                }
              </AccountTab>
              <AccountContentBox>
                {
                  tabActive === 'w' ? 
                  (
                    <LoginBox>
                      <QrcodeBox>
                        <div id='login'></div>
                        {
                          qrTimeout ? (
                            <div>
                              <QrMask></QrMask>
                                <QrMaskText>二维码已失效</QrMaskText>
                                <QrMaskButton onClick={() => {
                                  qrTimer && clearInterval(qrTimer);
                                  qrCount = 60;
                                  setRefreshQrcode(new Date().getTime());
                                  setQrTimeout(false);
                                }}>请点击刷新</QrMaskButton>
                            </div>
                          ) : <></>
                        }
                      </QrcodeBox>
                      <QrTip>
                        <i className="iconfont icon-wechat" style={{color: 'rgb(90,195,57)', fontSize: 20}}/>
                        <QrTipText>
                          微信扫码登录
                        </QrTipText>
                      </QrTip>
                    </LoginBox>
                  ) : (
                    tabActive === 's' ? 
                    (
                      <LoginBox>
                        <LoginForm
                          name="login"
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          autoComplete="off"
                        >
                          <Form.Item
                            name="mobile"
                            rules={[{ validator: checkMobile }]}
                          >
                            <LoginInputBox>
                              <LoginFormInput 
                                placeholder="请输入手机号码"
                                value={phoneInput}
                                size='large'
                                onChange={(change)=>{setPhoneInput(change.target.value)}}
                              />
                            </LoginInputBox>
                          </Form.Item>
  
                          <Form.Item
                            name="code"
                            rules={[{ validator: checkCode }]}
                          >
                            <LoginInputBox>
                              <LoginFormInput 
                                placeholder="输入短信验证码"
                                value={codeInput}
                                size='large'
                                onChange={(change) => {setCodeInput(change.target.value)}}
                              />
                              <SendCodeButton
                                onClick={sendCode}
                                disabled={seconds !== 0}
                              >
                                {seconds > 0 ? `${seconds}秒后可重发` : "获取短信验证码"}
                              </SendCodeButton>
                            </LoginInputBox>
                          </Form.Item>
  
                          <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loginLoading}>
                              登录 / 注册
                            </Button>
                          </Form.Item>
                        </LoginForm>
                      </LoginBox>
                    ) : 
                    (
                      <LoginBoxPwd>
                        <LoginForm
                          name="pwdLogin"
                          onFinish={onFinishPwd}
                          onFinishFailed={onFinishPwdFailed}
                          autoComplete="off"
                          style={{width: '100%'}}
                        >
                          <Form.Item
                            name="mobile"
                            rules={[{ validator: checkMobile }]}
                            style={{width: '100%'}}
                          >
                            <LoginInputBox
                              style={{width: '100%'}}
                            >
                              <LoginFormInput 
                                placeholder="请输入手机号码"
                                value={phoneInput}
                                size='large'
                                onChange={(change)=>{setPhoneInput(change.target.value)}}
                              />
                            </LoginInputBox>
                          </Form.Item>
  
                          <Form.Item
                            name="password"
                            rules={[{ validator: checkPassword }]}
                          >
                            <LoginInputBox>
                              <LoginFormInputPwd
                                id="loginPwd"
                                placeholder="请输入密码"
                                value={pwdInput}
                                size='large'
                                onChange={(change) => {setPwdInput(change.target.value)}}
                              />
                            </LoginInputBox>
                          </Form.Item>
  
                          <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loginLoading}>
                              登录
                            </Button>
                          </Form.Item>
                        </LoginForm>
                        <Row style={{flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                          <div style={{margin: 5, color: StyleConfig.color_blue, cursor: 'pointer', fontWeight: 'bold'}}
                            onClick={onPressShowRegister}
                          >
                            立即注册
                          </div>
                          <div style={{margin: 5, color: StyleConfig.color_blue, cursor: 'pointer'}}
                            onClick={onPressForgetPwd}
                          >
                            忘记密码
                          </div>
                        </Row>
                      </LoginBoxPwd>
                    )
                  )
                }
              
                <LoginTip>
                  <div>{
                    tabActive === 'p' ? <></> : (
                      <span>
                        未注册自动注册，注册代表同意<br />&nbsp;
                      </span>
                    )
                    }
                    <FormFooterA
                    onClick={() => clickButton('userAgreement')}
                  >《用户协议》</FormFooterA>和<FormFooterA 
                    onClick={() => clickButton('privacyAgreement')}
                  >《隐私协议》</FormFooterA></div>
                </LoginTip>

                <GrayLine />

                <FormFooter>
                  <FormFooterButton
                    onClick={() => clickButton('help')}
                  >
                    <ReadFilled style={{marginRight: 2, color: '#FFD000'}}/>
                    <span>使用手册</span>
                  </FormFooterButton>
                  <FormFooterButton
                    onClick={() => clickButton('download')}
                  >
                    <ThunderboltFilled style={{marginRight: 2, color: '#FFD000'}}/>
                    <span>下载App</span>
                  </FormFooterButton>
                </FormFooter>

              </AccountContentBox>

              
            </RightLoginBox>
          </RightBox>
        </ContainerRight>
        
        </Col>
      </Row>

      <div style={{height: 20, display: 'flex', flexDirection: 'row', justifyContent: 'center', justifyItems: 'center', position: 'absolute', bottom: 10, width: '80%', alignSelf: 'center', borderTop: '1px solid rgba(0,0,0,.1)', paddingTop: 10}}>
        <a href="https://beian.miit.gov.cn/" style={{color: 'gray', fontSize: 14, textDecoration: 'none'}} >京ICP备2022035280号-1</a>
        <img src="https://file.catnote.cn/img/gaba.png" style={{width: 12, height: 12, paddingLeft: 10, paddingTop: 5}} alt="beian" />
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=11011402053596" rel="noreferrer" target="_blank" style={{color: 'gray', fontSize: 14, textDecoration: 'none'}}>京公网安备11011402053596</a>
      </div>
    </Container>
  );
}