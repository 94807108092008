
import React, { ElementType } from 'react';
import htmr from 'htmr';
// import Zmage from 'react-zmage';
import { Image } from 'antd';
import { HtmrOptions } from 'htmr';
import { isChinese } from '../../untils/notice';

const customElementTransform: Record<string, ElementType> = {
  'img': Image,
};

const options: HtmrOptions = {
  transform: {
    ...(customElementTransform as any),
  }
};

// const customEt: Record<string, ElementType> = {
//   'a': <a></a>,
// };

export default function Html2React(props: { html: string, imgBig: boolean, searchKey?: string, isList?: boolean, maxHeight?: number }) {
  let text = props.html;
  let imgDiv = <></>;

  if (props.isList) {
    const i = /<img src=".*?">/g;
    const iv = /<div><br><\/div>/g;
    const imgs = props.html.match(i);
    const imgLinks = imgs && imgs.map(item => item && item.slice(10, -2)) || [];
    text = props.html.replace(i, '').replace(iv, '');
    const len = text.length;
    if (text.substring(len - 4) === '<br>') {
      text = text.substring(0, len - 4);
    }
    if (props.searchKey && isChinese(props.searchKey)) {
      const regExp = new RegExp(props.searchKey, 'g');
			text = text.replace(regExp, `<span style="color: rgba(255,208,0, 1)">${props.searchKey}</span>`)
    }

    imgDiv = (imgLinks.length > 0 ? (

      <Image.PreviewGroup>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4, marginLeft: -4, flexWrap: 'wrap' }}>
        {
          imgLinks.map(item => {
            return (
              <Image key={item} preview={{ src: item }} src={`${item}?x-oss-process=image/quality,q_20`} style={{ marginRight: 5, marginTop: 5, marginLeft: 5, borderRadius: 4, width: 80, height: 80, objectFit: 'cover' }}/>
            )
          })
        }
      </div>
      </Image.PreviewGroup>
      
    ):<></>);
  }

  return  props.imgBig ? <div><div className='ql-editor' style={{padding: 0, maxHeight: props.maxHeight || 10000, overflow: 'hidden'}}>{htmr(text, options)}</div>{imgDiv}</div> : <div><div className='ql-editor' style={{padding: 0, maxHeight: props.maxHeight || 10000, overflow: 'hidden'}}>{htmr(text)}</div>{imgDiv}</div>;
}