import { StyleConfig } from "../../untils/styleConfig";
import { noteApi } from '../../service/api';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Col, Row, Button, Popover, message, Badge, Dropdown, Avatar, Space, Image, Tooltip } from 'antd';
import { removeToken } from '../../untils/token';
import { AuthInfo, profile, authInfo } from '../../store/auth';
import { hotMap, category, curPage, curTag, curHasMore, remindList, remindReadIds, pageNow, vipModalShow, totalNote } from '../../store/note';
import type { MenuProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';

const rowMarginTop = 8;

export default function Side() {
  const navigate = useNavigate();

  const userInfo = useRecoilValue(profile);
  const [getCategory, setCategory] = useRecoilState(category);
  const setCurPage = useSetRecoilState(curPage);
  const [getCurTag, setCurTag] = useRecoilState(curTag);
  const setCurHasMore = useSetRecoilState(curHasMore);
  const getRemindList = useRecoilValue(remindList);
  const getRemindReadIds = useRecoilValue(remindReadIds);
  const setPageNow = useSetRecoilState(pageNow);
  const setAuthInfo = useSetRecoilState(authInfo);
  const setVipModalShow = useSetRecoilState(vipModalShow);
  const getHotMap = useRecoilValue(hotMap);
  const getTotalNote = useRecoilValue(totalNote);

  const docHost = process.env.REACT_APP_WEB_DOC_HOST;

  const onPressCategory = function(c: string) {
    switch(c) {
      case 'home':
        setCategory(c);
        setPageNow('');
        setCurTag('');
        setCurPage(1);
        setCurHasMore(true);
        break;
      case 'wechat':
        setCategory(c);
        setPageNow('');
        setCurPage(1);
        setCurHasMore(true);
        break;
      case 'review':
        setCategory(c);
        break;
      case 'images':
        setCategory(c);
        setPageNow('images');
        break;
      case 'ai':
        setCategory(c);
        setPageNow('ai');
        break;
      case 'trash':
        setCategory(c);
        setPageNow('');
        setCurPage(1);
        setCurHasMore(true);
        break;
      case 'setting':
        setCategory(c);
        setPageNow('setting');
        break;
      case 'doc':
        const w = window.open('about:blank');
        if (w && docHost) {
          w.location.href=docHost;
        }
        break;
      case 'app':
        const w2 = window.open('about:blank');
        if (w2 && docHost) {
          w2.location.href=`${docHost}/download`;
        }
        break;
      case 'vip':
        setVipModalShow(true);
        break;
      case 'no_tag':
        setCurPage(1);
        setCurHasMore(true);
        setCurTag('no_tag');
        setCategory('tags');
        setPageNow('');
        break;
      default:
        break;
    }
  }

  const onPressDoc = () => {
    const w = window.open('about:blank');
    if (w && docHost) {
      w.location.href=docHost;
    }
  }

  const onPressApp = () => {
    const w2 = window.open('about:blank');
    if (w2 && docHost) {
      w2.location.href=`${docHost}/download`;
    }
  }

  const onPressVip = () => {
    setVipModalShow(true);
  }

  const onPressClearTrash = () => {
    noteApi.note.trashClear().then(res => {
      setCurPage(1);
      setCurHasMore(true);
      setCategory('home');
      message.success('回收站清空成功')
    });
  }

  const curTagBg = function(isCur: boolean) {
    return isCur ? StyleConfig.color_primary : '#F8F9FA';
  }
  const curTagCor = function(isCur: boolean) {
    return isCur ? StyleConfig.color_white : StyleConfig.color_dark;
  }

  const trashMenu = function() {
    return (
      <div>
        <p style={{paddingTop: 0, marginTop: 0}}>
          <Button onClick={(e) => { onPressClearTrash(); e.stopPropagation(); }} style={{borderWidth: 0, padding: 0}} >
            清空回收站
          </Button>
        </p>
      </div>
    );
  }


  const notRead = getRemindList.map(item => item._id).filter(r => !getRemindReadIds.includes(r)) || [];

  const loginout = () => {
    removeToken();
    setPageNow('');
    setCurTag('');
    setCurPage(1);
    setCurHasMore(true);
    setCategory('home');
    setAuthInfo({ profile: { _id: '' } } as AuthInfo);
    message.success('退出登录成功');
    navigate('/login');
  }

  const onPressSetting = () => {
    setPageNow('setting');
  }

  const onPressInvite = () => {
    setPageNow('invite');
  }

  const onPressHelp = () => {
    setPageNow('help');
  }

  const onPressRemind = () => {
    setPageNow('remind');
  }

  const items: MenuProps['items'] = [
    {
      label: (
        <span onClick={() => onPressSetting()}>
          <i className="iconfont icon-flower" style={{color:'rgba(238,166,48,1)', margin: 5,}}/> 设置中心
        </span>
      ),
      key: 'account',
    },
    {
      label: (
        <span onClick={() => onPressInvite()}>
          <i className="iconfont icon-arrow-redo-circle-sharp" style={{color:'rgba(238,166,48,1)', margin: 5,}}/> 邀请好友
        </span>
      ),
      key: 'invite',
    },
    {
      label: (
        <span onClick={() => onPressDoc()}>
          <i className="iconfont icon-arrow-redo-circle-sharp" style={{color:'rgba(238,166,48,1)', margin: 5,}}/> 用户手册
        </span>
      ),
      key: 'doc',
    },
    {
      label: (
        <span onClick={() => onPressApp()}>
          <i className="iconfont icon-arrow-redo-circle-sharp" style={{color:'rgba(238,166,48,1)', margin: 5,}}/> APP下载
        </span>
      ),
      key: 'app',
    },
    {
      label: (
        <span onClick={() => onPressVip()}>
          <i className="iconfont icon-arrow-redo-circle-sharp" style={{color:'rgba(238,166,48,1)', margin: 5,}}/> 会员
        </span>
      ),
      key: 'vip',
    },
    {
      label: (
        <span onClick={() => onPressHelp()}>
          <i className="iconfont icon-help-circle" style={{color:'rgba(238,166,48,1)', margin: 5}}/> 帮助与反馈
        </span>
      ),
      key: 'help',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <span onClick={loginout}>
          <i className="iconfont icon-logout" style={{color:'rgba(238,166,48,1)', margin: 5}}/> 退出登录
        </span>
      ),
      key: 'logout',
    },
  ];

  const date = new Date();
  const today = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
  let todayNoteCount = 0;
  const hotMapMap: any = [];
  if (getHotMap) {
    for (const hot of getHotMap) {
      hotMapMap[hot.date] = hot.count;
      if (hot && hot['date'] && hot['date'] === today) {
        todayNoteCount = hot['count'] || 0;
      }
    }
  }

  const now = new Date();
  const nowTime = now.getTime();
  const day = now.getDay();
  const wDay = day === 0 ? 7 : day;
  const oneDayTime = 86400000;
  let sTime = nowTime - (wDay + 76) * oneDayTime;
  const dateList: any[] = [];
  let cDay = 84;
  let i = 0;
  const mList: any[] = [];
  let sm: string|number = '';
  while(cDay > 0) {
    const d = new Date(sTime);
    const m = d.getMonth() + 1;
    if (dateList[i]) {
      dateList[i] = dateList[i].concat([`${d.getFullYear()}-${m}-${d.getDate()}`])
    } else {
      dateList[i] = [`${d.getFullYear()}-${m}-${d.getDate()}`];
    }
    if (i === 6) {
      if (mList.includes(m)) {
        mList.push(' ')
      } else {
        if (!sm) {
          sm = m;
        }
        mList.push(m);
      }
    }

    sTime += oneDayTime;
    cDay--;

    if (i === 6) {
      i = 0;
    } else {
      i++;
    }
  }

  // const dateCounts: { date: string, count: number }[] = [];
  // let maxCount = 0;
  // // let sum = 0;
  // for (let j=29; j>=0; j--) {
  //   const d = getDate(-j);
  //   const count = Number(hotMapMap[d] || 0);
  //   dateCounts.push({
  //     date: d,
  //     count: count
  //   });
  //   if (count > maxCount) {
  //     maxCount = count;
  //   }
  //   // sum += count;
  // }

  // const svgWidth = 210;
  // const svgHeight = 100;
  // const svgWidthInterval = Number((svgWidth / 29).toFixed(2));
  // const svgHeightInterval = Number((svgHeight / maxCount).toFixed(2));
  
  // let polyLine = `0 ${svgHeight},`;
  // for (const index in dateCounts) {
  //   const w = svgWidthInterval*Number(index);
  //   const h = dateCounts[index].count === 0 ? svgHeight : svgHeight - Number(dateCounts[index].count * svgHeightInterval);
  //   polyLine += `${w} ${h},`;
  // }
  // polyLine += `${svgWidth} ${svgHeight}`;

  let useDay = 0;
  if (userInfo && userInfo.created) {
    useDay = Math.ceil(((new Date()).getTime() - userInfo.created) / 86400000);
  }

  const boxBgColor = (count = 0) => {
		if (count === 0) {
			return StyleConfig.color_muted_light;
		} else if (count === 1) {
			return 'rgba(255,195,0, 0.2)';
		} else if (count > 1 && count <= 2) {
			return 'rgba(255,195,0, 0.3)';
		}	else if (count > 2 && count <= 4) {
			return 'rgba(255,195,0, 0.4)';
		} else if (count > 4 && count <= 6) {
			return 'rgba(255,195,0, 0.5)';
		} else if (count > 6 && count <= 8) {
			return 'rgba(255,195,0, 0.6)';
		} else if (count > 8 && count <= 10) {
			return 'rgba(255,195,0, 0.7)';
		} else if (count > 10 && count <= 12) {
			return 'rgba(255,195,0, 0.8)';
		} else if (count > 12 && count <= 14) {
			return 'rgba(255,195,0, 0.9)';
		} else {
			return 'rgba(255,195,0, 1)';
		}
	}


  return (
    <Row style={{width: '100%', flexDirection: 'column'}}>
      
        <Row justify='space-between'>
          <Col style={{padding: 5, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}} className='menu-button'>
            <Dropdown menu={{items}} placement={'bottom'} arrow>
              <span onClick={() => {}} style={{color: StyleConfig.color_black}}>
                <Space>
                  <Avatar src={<Image src={(userInfo && userInfo.avatar) || 'https://file.catnote.cn/img/log_new.jpeg'} preview={false} style={{ width: 32 }} />} />
                  <span style={{fontWeight: 'bold'}}>
                  { userInfo.nickname ? (userInfo.nickname.length > 9 ? userInfo.nickname.substring(0, 9) + '...' : userInfo.nickname) : (userInfo.phone ? userInfo.phone.substring(0, 3) + '****' + userInfo.phone.substring(userInfo.phone.length-4) : '') }
                  </span>
                  <DownOutlined />
                </Space>
              </span>
            </Dropdown>
          </Col>
          <Col style={{padding: 5, cursor: 'pointer'}} className='menu-button'>
            <span>
              <span onClick={() => onPressRemind()}>
                <Badge dot={notRead.length > 0 ? true : false} offset={[-9, 9]} >
                  <Avatar src={<i className="iconfont icon-notifications" style={{ fontSize: 15, color: StyleConfig.color_dark }}/>} />
                </Badge>
              </span>
            </span>
          </Col>
        </Row>

      {/* <Row style={{marginLeft: 10, width: '100%', marginTop: 10}}>
        <span style={{color: StyleConfig.color_gray, fontSize: 12}}>共</span><span style={{fontWeight: '600', color: StyleConfig.color_dark, fontSize: 12}}>{getTotalNote}</span><span style={{color: StyleConfig.color_gray, fontSize: 12}}>条</span>
        &nbsp;
        <span style={{fontWeight: '600', color: StyleConfig.color_dark, fontSize: 12}}>{useDay}</span><span style={{color: StyleConfig.color_gray, fontSize: 12}}>天</span>
      </Row> */}

      <Row style={{width: '100%', paddingTop: 18, paddingBottom: 6, paddingLeft: 4, paddingRight: 18}}>
        <Col span={8}>
          <div style={{width: 76, marginLeft: 6, marginTop: 6, fontSize: 18, fontWeight: '600', color: StyleConfig.color_dark}}>
            {
              getTotalNote
            }
          </div>
          <div style={{width: 76, marginLeft: 6, color: StyleConfig.color_gray, fontSize: 12}}>
            笔记
          </div>
        </Col>
        <Col span={8}>
          <div style={{width: 76, marginTop: 6,textAlign: 'center', fontSize: 18, fontWeight: '600', color: StyleConfig.color_dark}}>
            {
              todayNoteCount
            }
          </div>
          <div style={{width: 76, textAlign: 'center', color: StyleConfig.color_gray, fontSize: 12}}>
            今日笔记
          </div>
        </Col>
        <Col span={8}>
          <div style={{width: 76, marginTop: 6, textAlign: 'right', fontSize: 18, fontWeight: '600', color: StyleConfig.color_dark}}>
            {
              useDay
            }
          </div>
          <div style={{width: 76, textAlign: 'right', color: StyleConfig.color_gray, fontSize: 12}}>
            使用天数
          </div>
        </Col>
      </Row>

      {/* <Row style={{marginTop: 10, marginLeft: 10, marginBottom: 20}}>
        <Col style={{ height: svgHeight + 8, position: 'absolute' }}>
          <div style={{color: StyleConfig.color_gray, fontSize: 8, position: 'absolute', top: -4}}>
            {maxCount > 0 ? maxCount : ''}
          </div>
          <div style={{color: StyleConfig.color_gray, fontSize: 8, position: 'absolute', bottom: 1 }}>
            0
          </div>
        </Col>
        <Col style={{marginLeft: 8}}>
          <svg height={svgHeight} width={svgWidth}>
            <polyline
              points={polyLine}
              stroke="#FFDD00"
              strokeWidth="1.5"
              fill="rgba(255,234,0, 0.2)"
            />
          </svg>
        </Col>
      </Row> */}
      <Row style={{flexDirection: 'column', width: '100%', paddingTop: 18, paddingBottom: 18, paddingLeft: 0, paddingRight: 18}}>
        {
          dateList.map((dl, k) => {
            return (
              <Row key={k} style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                <Row style={{width: 12, height: 12, marginTop: 2, marginBottom: 2, justifyContent: 'center'}}>
                  <div style={{fontSize: 10, color: StyleConfig.color_gray}}>
                  {
                    k === 0 ? '一' : (k === 2 ? '三' : (k === 4 ? '五' : (k === 6 ? '日': '')))
                  }
                  </div>
                </Row>
                {
                  dl.map((d: any) => {
                    return (
                      <Tooltip key={d} title={d}>
                      <Row key={d} style={{width: 12, height: 12, marginTop: 2, marginBottom: 2, borderRadius: 2, backgroundColor: boxBgColor(hotMapMap[d] || 0),
                        borderWidth: d === today ? 1: 0, borderColor: StyleConfig.color_green, borderStyle: 'solid'
                      }}>
                      </Row>
                      </Tooltip>
                    )
                  })
                }
                
              </Row>
            )
          })
        }
        <Row style={{flexDirection: 'row', justifyContent: 'space-around'}}>
          <Row style={{width: 12, height: 12}}></Row>
          {
            mList.map((md, key) => {
              return (
                <Row key={key} style={{width: 12, height: 12, alignItems: 'center', marginTop: 4}}>
                  <div style={{fontSize: 10, color: StyleConfig.color_gray}}>
                    {md === sm ? '' : md}
                  </div>
                </Row>
              )
            })
          }
        </Row>
      </Row>


        <Row key={'all_note'} style={{marginTop: rowMarginTop, width: '98%'}}>
          <Col span={24} onClick={() => onPressCategory('home')}  style={{cursor: 'pointer', borderWidth: 0, width: '100%', borderRadius: 10, backgroundColor: curTagBg(getCategory === 'home')}}>
            <Row className={getCategory === 'home' ? '' : 'menu-button'} >
              <Col span={24} style={{marginLeft: 8, padding: 10}}>
              <i className="iconfont icon-file-tray" style={{fontSize: 16, paddingTop: 4, paddingBottom: 4, color: curTagCor(getCategory === 'home')}}/>
              <span style={{fontWeight: '500', fontSize: 16, marginLeft: 5, color: curTagCor(getCategory === 'home')}}>全部笔记</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row key={'wechat_note'} style={{marginTop: rowMarginTop, width: '98%'}}>
          <Col span={24} onClick={() => onPressCategory('wechat')}  style={{cursor: 'pointer', borderWidth: 0, width: '100%', borderRadius: 10, backgroundColor: curTagBg(getCategory === 'wechat')}}>
            <Row className={getCategory === 'wechat' ? '' : 'menu-button'} >
              <Col span={24} style={{marginLeft: 8, padding: 10}}>
              <i className="iconfont icon-chatbubbles" style={{fontSize: 16, padding: 4, color: curTagCor(getCategory === 'wechat')}}/>
              <span style={{fontWeight: '500', fontSize: 16, marginLeft: 5, color: curTagCor(getCategory === 'wechat')}}>微信笔记</span>
              </Col>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row>
        <Row key={'images'} style={{marginTop: rowMarginTop, width: '98%'}}>
          <Col span={24} onClick={() => onPressCategory('images')}  style={{cursor: 'pointer', borderWidth: 0, width: '100%', borderRadius: 10, backgroundColor: curTagBg(getCategory === 'images')}}>
            <Row className={getCategory === 'images' ? '' : 'menu-button'} >
              <Col span={24} style={{marginLeft: 8, padding: 10}}>
              <i className="iconfont icon-image1" style={{fontSize: 16,padding: 4, color: curTagCor(getCategory === 'images')}}/>
              <span style={{fontWeight: '500', fontSize: 16,marginLeft: 5, color: curTagCor(getCategory === 'images')}}>全部图片</span>
              </Col>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row>
        <Row key={'no_tag'} style={{marginTop: rowMarginTop, width: '98%'}}>
          <Col span={24} onClick={() => onPressCategory('no_tag')}  style={{cursor: 'pointer', borderWidth: 0, width: '100%', borderRadius: 10, backgroundColor: curTagBg(getCategory === 'tags' && getCurTag === 'no_tag')}}>
            <Row className={(getCategory === 'tags' && getCurTag === 'no_tag') ? '' : 'menu-button'} >
              <Col span={24} style={{marginLeft: 8, padding: 10}}>
              <i className="iconfont icon-ios-albums" style={{fontSize: 15,padding: 4, color: curTagCor(getCategory === 'tags' && getCurTag === 'no_tag')}}/>
              <span style={{fontWeight: '500', fontSize: 16,marginLeft: 5, color: curTagCor(getCategory === 'tags' && getCurTag === 'no_tag')}}>无标签笔记</span>
              </Col>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row>
        {/* <Row key={'ai'} style={{marginTop: rowMarginTop, width: '98%'}}>
          <Col span={24} onClick={() => onPressCategory('ai')}  style={{cursor: 'pointer', borderWidth: 0, width: '100%', borderRadius: 10, backgroundColor: curTagBg(getCategory === 'ai')}}>
            <Row className={getCategory === 'ai' ? '' : 'menu-button'} >
              <Col span={24} style={{marginLeft: 8, padding: 10}}>
              <i className="iconfont icon-android" style={{fontSize: 18,padding: 4, color: curTagCor(getCategory === 'ai')}}/>
              <span style={{fontWeight: '500', fontSize: 16,marginLeft: 5, color: curTagCor(getCategory === 'ai')}}>问AI</span>
              </Col>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row> */}
        <Row key={'trash'} style={{marginTop: rowMarginTop, width: '98%'}}>
          <Col span={24} onClick={() => onPressCategory('trash')}  style={{cursor: 'pointer', borderWidth: 0, width: '100%', borderRadius: 10, backgroundColor: curTagBg(getCategory === 'trash')}}>
            <Row className={getCategory === 'trash' ? '' : 'menu-button'}>
              <Col span={24} style={{marginLeft: 8, padding: 10}}>
              <i className="iconfont icon-trash" style={{fontSize: 16,padding: 4, color: curTagCor(getCategory === 'trash')}}/>
              <span style={{fontWeight: '500', fontSize: 16,marginLeft: 5, color: curTagCor(getCategory === 'trash')}}>回收站</span>
              <Popover placement="right" content={trashMenu()} trigger="hover" >
                <i className="iconfont icon-ellipsis-horizontal" style={{position: 'absolute', right: 20, padding: 4, color: curTagCor(getCategory === 'trash')}} />
              </Popover>
              </Col>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row>

        {/* <Row key={'setting'} style={{marginTop: rowMarginTop}}>
          <Col span={24} onClick={() => onPressCategory('setting')}  style={{cursor: 'pointer', borderWidth: 0, width: '100%', borderRadius: 5, backgroundColor: curTagBg(getCategory === 'setting')}}>
            <Row className={getCategory === 'doc' ? '' : 'menu-button'} >
              <Col span={24} style={{marginLeft: 8, padding: 10}}>
              <i className="iconfont icon-chatbubbles" style={{fontSize: 16,padding: 4, color: curTagCor(getCategory === 'setting')}}/>
              <span style={{fontWeight: '500', fontSize: 16,marginLeft: 5, color: curTagCor(getCategory === 'setting')}}>设置</span>
              </Col>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row>
        <Row key={'doc'} style={{marginTop: rowMarginTop}}>
          <Col span={24} onClick={() => onPressCategory('doc')}  style={{cursor: 'pointer', borderWidth: 0, width: '100%', borderRadius: 5, backgroundColor: curTagBg(getCategory === 'doc')}}>
            <Row className={getCategory === 'doc' ? '' : 'menu-button'} >
              <Col span={24} style={{marginLeft: 8, padding: 10}}>
              <i className="iconfont icon-chatbubbles" style={{fontSize: 16,padding: 4, color: curTagCor(getCategory === 'doc')}}/>
              <span style={{fontWeight: '500', fontSize: 16,marginLeft: 5, color: curTagCor(getCategory === 'doc')}}>手册</span>
              </Col>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row>
        <Row key={'app'} style={{marginTop: 4}}>
          <Col span={24} onClick={() => onPressCategory('app')}  style={{cursor: 'pointer', borderWidth: 0, width: '100%', borderRadius: 5, backgroundColor: curTagBg(getCategory === 'app')}}>
            <Row className={getCategory === 'app' ? '' : 'menu-button'} >
              <Col span={24} style={{marginLeft: 8, padding: 10}}>
              <i className="iconfont icon-chatbubbles" style={{fontSize: 16,padding: 4, color: curTagCor(getCategory === 'app')}}/>
              <span style={{fontWeight: '500', fontSize: 16,marginLeft: 5, color: curTagCor(getCategory === 'app')}}>APP</span>
              </Col>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row>
        <Row key={'vip'} style={{marginTop: 4}}>
          <Col span={24} onClick={() => onPressCategory('vip')}  style={{cursor: 'pointer', borderWidth: 0, width: '100%', borderRadius: 5, backgroundColor: curTagBg(getCategory === 'vip')}}>
            <Row className={getCategory === 'vip' ? '' : 'menu-button'} >
              <Col span={24} style={{marginLeft: 8, padding: 10}}>
              <i className="iconfont icon-chatbubbles" style={{fontSize: 16,padding: 4, color: curTagCor(getCategory === 'vip')}}/>
              <span style={{fontWeight: '500', fontSize: 16,marginLeft: 5, color: curTagCor(getCategory === 'vip')}}>会员</span>
              </Col>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row> */}
        

    </Row>
  );
}