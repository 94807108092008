import { atom, selector } from 'recoil';
import { noteApi } from '../service/api';
import { User } from '../service/api/types/Note';
import { getToken } from '../untils/token';

export type AuthInfo = {
  profile: User,
};

// token 数据
export const authInfo = atom({
  key: 'auth/authInfo',
  default: { profile: { _id: '' } } as AuthInfo,
});

// 资料信息
export const profile = selector({
  key: 'auth/profile',
  get: async ({ get }) => {
    const info = get(authInfo);
    if (!info.profile._id && getToken()) {
      const userInfo = await noteApi.user.info();
      if (userInfo.success) {
        return userInfo;
      }
    }
    return info.profile;
  },
});

// 是否登陆
export const authIsLogin = selector({
  key: 'auth/isLogin',
  get: ({ get }) => {
    return Boolean(get(profile)._id);
  },
});
