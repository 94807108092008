import styled from 'styled-components';
import { Button as AntButton, Form as AntForm, Input as AntInput } from 'antd';


export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 320px;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const ContainLeft = styled.div`
width: 350px;
margin-right: 140px;
display: flex;
flex-direction: column;
align-items: flex-start;
`;

export const LeftText = styled.div`
margin-top: 45px;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: start;
`;

export const LeftTextTitle = styled.div`
font-size: 36px;
color: #19191a;
font-weight: 700;
line-height: 100%;
margin-bottom: 22px;
`;

export const LeftTextDesc = styled.div`
font-size: 16px;
width: 330px;
font-weight: 400;
line-height: 180%;
color: #555557;
`;

export const ContainerRight = styled.div`
width: 440px;
height: 500px;
background: #fff;
border: 1px solid rgba(19,18,43,.07);
box-shadow: 0 22px 50px rgba(255,234,0,0.2);
border-radius: 14px;
position: relative;
display: flex;
justify-content: center;
align-items: center;
`;

export const RightBox = styled.div`
width: 100%;
display: flex;
justify-content: center;
`;

export const RightLoginBox = styled.div`
width: 380px;
padding: 36px 60px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
position: relative;
`;

export const AccountTab = styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-between;
color: #8b8c8f;
height: 50px;
margin-bottom: 24px;
`;

export const AccountTabActive = styled.div`
font-weight: 600;
cursor: pointer;
display: block;
width: 50%;
height: 40px;
padding-top: 5px;
text-align: center;
font-size: 16px;
line-height: 22px;
color: #19191a;
border-bottom: 2px solid #FFDD00!important;
`;

export const AccountTabNormal = styled.div`
font-weight: 600;
cursor: pointer;
display: block;
width: 50%;
height: 40px;
padding-top: 5px;
text-align: center;
border-bottom: 2px solid rgb(18 17 42 / 5%);
`;

export const AccountContentBox = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
position: relative;
width: 320px;
`;

export const LoginBox = styled.div`
width: 100%;
height: 206px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;

export const LoginBoxPwd = styled.div`
width: 100%;
height: 250px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;

export const QrcodeBox = styled.div`
margin-bottom: 24px;
position: relative;
height: 170px;
`;

export const Qrcode = styled.img`
width: 160px;
height: 160px;
vertical-align: middle;
border-style: none;
overflow-clip-margin: content-box;
overflow: clip;
`;

export const QrMask = styled.div`
width: 300px;
height: 170px;
position: absolute;
top: 0;
left: 0;
background: rgba(255,255,255,.9);
`;

export const QrMaskText = styled.div`
position: absolute;
font-size: 20px;
line-height: 28px;
font-weight: 600;
width: 140px;
text-align: center;
color: #19191a;
top: 56px;
left: 50%;
transform: translate(-50%);
`;

export const QrMaskButton = styled.div`
position: absolute;
font-size: 16px;
line-height: 22px;
font-weight: 600;
color: #fff;
background: #FFC300;
width: 136px;
height: 40px;
padding: 10px 0;
text-align: center;
box-sizing: border-box;
border-radius: 6px;
cursor: pointer;
bottom: 40px;
left: 82px;
`;

export const QrTip = styled.div`
color: #19191a;
display: flex;
flex-direction: row;
align-items: center;
`;

export const QrTipText = styled.span`
margin-left: 8px;
font-size: 16px;
line-height: 22px;
font-weight: bold;
`;

export const LoginTip = styled.div`
width: 100%;
font-size:13px;
display: flex;
justify-content: center;
padding: 12px 24px;
color: gray;
`;

export const FormFooterA = styled.div`
display: inline-flex;
cursor: pointer;
text-align: center;
`;

export const GrayLine = styled.div`
box-sizing: border-box;
margin-top: 10px;
min-width: 300px;
border-top: 1px solid;
border-color: #EBEBEB;
margin-left: 2px;
margin-right: 2px;
`;

export const FormFooter = styled.div`
height: 60px;
line-height: 60px;
color: #8590a6;
justify-content: space-between;
`;

export const FormFooterButton = styled.div`
margin: 0 18px;
display: inline-flex;
align-items: center;
cursor: pointer;
`;



export const LoginForm = styled(AntForm)`
  overflow: hidden;
`;

export const LoginInputBox = styled.div`
  display: flex;
  align-items: center;
`;

export const PhoneCountryCode = styled.div`
  min-width: 90px;
  color: #8590a6;
`;

export const LoginSpan = styled.span`
  width: 1px;
  height: 22px;
  margin: 0 12px;
  background: #ebebeb;
`;

export const LoginFormInput= styled(AntInput)`
  height: 48px;
  line-height: 24px;
  background: #F7F7F9;
  border-color: #FFFFFF;
`;

export const LoginFormInputPwd = styled(AntInput.Password)`
height: 48px;
line-height: 24px;
background-color: #F7F7F9;
border-color: #FFFFFF;
`;

export const SendCodeButton = styled(AntButton)`
height: 44px;
margin: 4px;
`;

export const Button = styled(AntButton)`
  width: 100%;
  margin-top: 8px;
  height: 48px;
  font-weight: 500;
  font-size: 16px;
`;

