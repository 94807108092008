import { atom } from 'recoil';
import { Notice, TagItem } from '../service/api/types/Note';
import { getRemindStore } from '../untils/notice';

export const hotMap = atom({
  key: 'note/hotMap',
  default: [] as any[],
});

export const category = atom({
  key: 'note/category',
  default: 'home'
});

export const curTag = atom({
  key: 'note/curTag',
  default: ''
});

export const curPage = atom({
  key: 'note/curPage',
  default: 1
});

export const curHasMore = atom({
  key: 'note/curHasMore',
  default: true
});

export const tags = atom({
  key: 'note/tags',
  default: [] as TagItem[],
});

export const reload = atom({
  key: 'note/reload',
  default: new Date().getTime(),
});

export const totalNote = atom({
  key: 'note/total',
  default: 0,
});

export const curEditNote = atom({
  key: 'note/curEdit',
  default: '',
});

export const searchKey = atom({
  key: 'note/search',
  default: '',
});

export const pageNow = atom({
  key: 'page',
  default: '',
});

export const remindList = atom({
  key: 'remind',
  default: [] as Notice[],
});

export const remindReadIds =  atom({
  key: 'remind/read',
  default: getRemindStore() as string[],
});

export const vipModalShow = atom({
  key: 'vip/modal',
  default: false,
});