import { Layout as AntdLayout } from 'antd';
import styled from 'styled-components';

export const Layout = styled(AntdLayout)``;

export const Header = styled.div`
  height: 52px;
  background-color: #FFFFFF;
  transition-property: background-color,box-shadow;
  transition-duration: 0.25s;
  transition-timing-function: ease-in;
  box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
`;

export const HeaderLogo = styled.img`
box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
`;

export const HeaderContent = styled.div`
  margin: 0 auto;
  align-items: center;
  width: auto;
  width: 968px;
  height: 100%;
`;

export const Content = styled.div`
  position: relative;
  width: 1000px;
  height: calc(100vh - 60);
  margin: 8px auto;
  padding: 0 16px;
  align-items: flex-start;
  display: flex;
`;

export const ContentSider = styled.div`
flex: 1 1;
width: 262px;
background: #FFFFFF;
box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
border-radius: 4px;
`;

export const ContentList = styled.div`
width: 700px;
margin-left: 6px;
`;

export const Footer = styled.div`
  text-align: center;
`;

export const Logo2 = styled.div`
  float: left;
  border-radius: 5px;
  width: 120px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  margin: 10px 24px 10px 0;
  background: rgba(255, 255, 255, 0.3);
  color: #000000;
  border: 1px solid black;
`;

export const Logo = styled.img`
  width: 30px;
  border-radius: 20px;
  margin-top: 10px;
  margin-left: 40px;
`;

export const VipItem = styled.button`
border-radius: 5px;
border-color: rgba(228,189,134, 1);
border-width: 1px;
width: 110px;
height: 50px;
background-color: rgba(0, 0, 0, 0.3);
opacity: 0.9;
margin: 3px 14px;
`;

export const QrShow = styled.div`
width: 100%;
`;
