import { VipItem } from './Main.style';
import { Image, Button, Row, Col, message, Modal } from 'antd';
import NoteList from '../note/NoteList';
import Side from '../side/Side';
import { StyleConfig } from '../../untils/styleConfig';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { profile, authInfo } from '../../store/auth';
import { pageNow, vipModalShow } from '../../store/note';
import Setting from '../setting/Setting';
import Invite from '../invite/Invite';
import Help from '../help/Help';
import Remind from '../notice/Remind';
import { formatMsToDay } from '../../helper/funcs';
import { useState } from 'react';
import QRCode from "qrcode.react";
import { noteApi } from '../../service/api';
import SideRight from '../sideright/SideRight';
// import AiChat from '../aichat/AiChat';
import ImageList from '../imageList/ImageList';

let payCount = 60;

export default function Main() {
  const userInfo = useRecoilValue(profile);
  const setAuthInfo = useSetRecoilState(authInfo);
  const getPageNow = useRecoilValue(pageNow);
  const [getVipModalShow, setVipModalShow] = useRecoilState(vipModalShow);
  const [getVipSelect, setVipSelect] = useState('year');
  const [showPayCode, setShowPayCode] = useState(false);
  const [payCodeLink, setPayCodeLink] = useState('');
  const [payTimer, setPayTimer] = useState(null as any);

  const docHost = process.env.REACT_APP_WEB_DOC_HOST;
  
  const onPressVip = (action = true) => {
    setVipModalShow(action);
  }

  const clickButton = (type: string) => {
    let link = docHost;
    switch(type) {
      case 'help':
        link = docHost;
        break;
      case 'download':
        link = `${docHost}/download`;
        break;
      case 'wechat':
        link = `${docHost}/wechat`;
        break;
      case 'scan':
        link = `${docHost}/scan`;
        break;
      case 'review':
        link = `${docHost}/review`;
        break;
      case 'storage':
        link = `${docHost}/storage`;
        break;
      case 'connect':
        link = `${docHost}/connect`;
        break;
      case 'api':
        link = `${docHost}/api`;
        break;
      default:
        break;
    }
    const w = window.open('about:blank');
    if (w && link) {
      w.location.href=link;
    }
  }

  const font_time = {
    fontSize: 14, fontWeight: '500', alignSelf: 'center', color: '#482001', padding: 5
  };
  const font_d = {
    fontSize: 14, fontWeight: 'bold', alignSelf: 'center', color: '#482001'
  };
  const font_price = {
    fontSize: 20, fontWeight: 'bold', alignSelf: 'center', color: '#482001'
  };
  const font_old = {
    textDecorationLine: 'line-through', color: StyleConfig.color_gray, alignSelf: 'center', fontSize: 13, padding: 4
  };

  const monthPrice = 8;
  const yearPrice = 58;
  const foreverPrice = 98;

  const pressOpenVip = async () => {
    setShowPayCode(true);
    payCount = 60;

    let out_trade_id: string;
    const res = await noteApi.pay.pcPlaceOrder(getVipSelect);
    if (res.success && res.code_url) {
      setPayCodeLink(res.code_url);
      out_trade_id = res.out_trade_id || '';
    } else {
      return message.warning('下单失败，请重新下单');
    }

    // 每隔两秒轮询支付结果，轮询60次，无结果则关闭二维码
    const timer = setInterval(async () => {
      if (payCount <= 0) {
        clearInterval(timer);
        payTimer && clearInterval(payTimer);
        setShowPayCode(false);
        return;
      }
      payCount = payCount - 1;

      const res = out_trade_id && await noteApi.pay.checkWxPayRes(out_trade_id);
      if (res && res.success) {
        // 尚无支付结果
        if (!res.trade_state) {
          return;
        }
        if (res.trade_state === 'SUCCESS') {
          // 支付成功
          message.success(`恭喜您已成功开通CatNote${getVipSelect === 'month'?'月度':(getVipSelect==='year'?'年度':'永久')}会员`);
          noteApi.user.info().then(reloadUser => {
            reloadUser.success &&  setAuthInfo({ profile: reloadUser });
          })
          setShowPayCode(false);
          setPayCodeLink('');
          setVipModalShow(false);
        } else {
          // 支付失败
          message.warning('支付失败');
        }

        clearInterval(timer);
        payTimer && clearInterval(payTimer);
      }
      
    }, 2000);
    setPayTimer(timer);

  }

  return (
    <Row style={{paddingTop: 8}}>
      <Modal title="CatNote会员" zIndex={2000} open={getVipModalShow} onCancel={() => {
        setShowPayCode(false);
        payTimer && clearInterval(payTimer);
        onPressVip(false);
      }} footer={null} keyboard={false} maskClosable={false}>
        {
          showPayCode ? (
            <span>
              <Row align={'middle'} justify={'center'} style={{fontWeight: 'bold'}}>
                {getVipSelect === 'month'?'一个月':(getVipSelect==='year'?'一年':'永久')}
              </Row>
              <Row align={'middle'} justify={'center'} style={{padding: 10}}>
                {
                  payCodeLink ? (
                    <QRCode
                      style={{alignSelf: 'center'}}
                      value={payCodeLink}
                      size={200}
                    />
                  ): <></>
                }
              </Row>
              <Row align={'middle'} justify={'center'} style={{fontSize: 22, fontWeight: 500, color: '#FFA200'}}>
                ￥{getVipSelect === 'month'?monthPrice:(getVipSelect==='year'?yearPrice:foreverPrice)}
              </Row>
              <Row align={'middle'} justify={'center'}>
                微信扫一扫轻松支付
              </Row>
            </span>
          ) : (
            <div>
              <Row style={{padding: 10}}>
                <Col>
                  <Image src={(userInfo && userInfo.avatar) || 'https://file.catnote.cn/img/log_new.jpeg'} preview={false} style={{ width: 46, borderRadius: 23 }} />
                </Col>
                <Col style={{marginLeft: 10}}>
                  <Row style={{fontSize: 18, fontWeight: '700'}}>
                    { userInfo.nickname ? (userInfo.nickname.length > 9 ? userInfo.nickname.substring(0, 9) + '...' : userInfo.nickname) : (userInfo.phone ? userInfo.phone.substring(0, 3) + '****' + userInfo.phone.substring(userInfo.phone.length-4) : '') }
                  </Row>
                  <Row style={{paddingLeft: 2}}>
                    {
                      userInfo && userInfo.is_vip ? (userInfo.vip_exp_at === -1 ? '您已是永久会员': `您已是会员 有效期至${userInfo.vip_exp_at && formatMsToDay(userInfo.vip_exp_at)}`) : '暂未开通会员'
                    }
                  </Row>
                </Col>
              </Row>
              <Row style={{marginLeft: 5, marginRight: 5, borderTopLeftRadius: 5, borderTopRightRadius: 5, backgroundColor: StyleConfig.color_black, justifyContent: 'center', paddingTop: 2, height: 40, opacity: 0.9}}>
                <Row style={{padding: 5, fontSize: 15, fontWeight: '600', color: 'rgba(228,189,134, 1)'}}>
                  会员权益
                </Row>
              </Row>
              <Row style={{marginLeft: 5, marginRight: 5, justifyContent: 'center', backgroundColor: StyleConfig.color_black, opacity: 0.9}}>
                <VipItem onClick={() => clickButton('wechat')}>
                  <span style={{flexDirection: 'column'}}>
                    <i className="iconfont icon-chatbubbles-outline" style={{color: 'rgba(228,189,134, 1)'}}/>
                    <span style={{color: 'rgba(228,189,134, 1)', fontSize: 12, padding: 3}}>
                      微信语音入
                    </span>
                  </span>
                </VipItem>
                <VipItem onClick={() => clickButton('scan')}>
                  <i className="iconfont icon-scan" style={{color: 'rgba(228,189,134, 1)',}}/>
                  <span style={{color: 'rgba(228,189,134, 1)', fontSize: 12, padding: 3}}>
                    文字识别
                  </span>
                </VipItem>
                <VipItem onClick={() => clickButton('review')}>
                  <i className="iconfont icon-attention" style={{color: 'rgba(228,189,134, 1)'}}/>
                  <span style={{color: 'rgba(228,189,134, 1)', fontSize: 12, padding: 3}}>
                    定时回顾
                  </span>
                </VipItem>
              </Row>
              <Row style={{marginLeft: 5, marginRight: 5, justifyContent: 'center', backgroundColor: StyleConfig.color_black, opacity: 0.9, borderBottomLeftRadius: 5, borderBottomRightRadius: 5, paddingBottom: 8}}>
                <VipItem onClick={() => clickButton('storage')}>
                  <i className="iconfont icon-image-outline" style={{color: 'rgba(228,189,134, 1)',}}/>
                  <span style={{color: 'rgba(228,189,134, 1)', fontSize: 12, padding: 3}}>
                    超大图片存储
                  </span>
                </VipItem>
                <VipItem onClick={() => clickButton('connect')}>
                  <i className="iconfont icon-link" style={{color: 'rgba(228,189,134, 1)',}}/>
                  <span style={{color: 'rgba(228,189,134, 1)', fontSize: 12,alignSelf: 'center', padding: 3}}>
                    反向链接
                  </span>
                </VipItem>
                <VipItem onClick={() => clickButton('api')}>
                  <i className="iconfont icon-flash-outline" style={{color: 'rgba(228,189,134, 1)',}}/>
                  <span style={{color: 'rgba(228,189,134, 1)', fontSize: 12, padding: 3}}>
                    API输入
                  </span>
                </VipItem>
              </Row>

              <Row style={{marginTop: 14, justifyContent: 'center'}}>
                <Button onClick={() => setVipSelect('month')} style={{
                  borderColor: getVipSelect === 'month' ? '#D9B675':  '#EEEEEE',
                  justifyContent: 'center',
                  width: '30%',
                  height: 100,
                  borderWidth: 1,
                  borderRadius: 10,
                  marginRight: 8,
                }}>
                  <div style={font_time}>
                    一个月
                  </div>
                  <div style={font_d}>
                    ￥ <span style={font_price}>
                    {monthPrice}
                    </span>
                  </div>
                  <div style={font_old}>
                    ￥18
                  </div>
                </Button>
                <Button onClick={() => setVipSelect('year')} style={{
                  borderColor: getVipSelect === 'year' ? '#D9B675':  '#EEEEEE',
                  justifyContent: 'center',
                  width: '30%',
                  height: 100,
                  borderWidth: 1,
                  borderRadius: 10,
                  marginLeft: 8,
                  marginRight: 8,
                }}>
                  <div style={{color: '#482001', flexDirection: 'row', justifyContent: 'center', borderBottomLeftRadius: 10, borderTopRightRadius: 10, height: 20, width: 80, position: 'absolute', right: -1, top: -9, backgroundColor: '#F4C975'}}>
                    超值推荐
                  </div>
                  <div style={font_time}>
                    一年
                  </div>
                  <div style={font_d}>
                    ￥ <span style={font_price}>
                    {yearPrice}
                    </span>
                  </div>
                  <div style={font_old}>
                    ¥98
                  </div>
                </Button>
                <Button onClick={() => setVipSelect('forever')} style={{
                  borderColor: getVipSelect === 'forever' ? '#D9B675':  '#EEEEEE',
                  justifyContent: 'center',
                  width: '30%',
                  height: 100,
                  borderWidth: 1,
                  borderRadius: 10,
                  marginLeft: 8,
                }}>
                  <div style={font_time}>
                    永久
                  </div>
                  <div style={font_d}>
                    ￥ <span style={font_price}>
                    {foreverPrice}
                    </span>
                  </div>
                  <div style={font_old}>
                    ¥298
                  </div>
                </Button>
              </Row>
              <Row style={{flexDirection: 'row', justifyContent: 'space-between', marginTop: 4 }}>
                <span style={{padding: 12}}>
                  付款方式：
                </span>
                <span style={{padding: 12}}>
                  <i className="iconfont icon-wechat-pay" style={{color: 'rgba(83,169,73,1)', paddingRight: 5}}/>
                  微信支付
                </span>
              </Row>
              <Row style={{flexDirection: 'row', justifyContent: 'center'}}>
                <Button disabled={userInfo.vip_exp_at === -1} type='primary' style={{width: '96%',height: 40,fontSize: 18, fontWeight: 'bold'}} onClick={() => pressOpenVip()} >
                  ￥{getVipSelect === 'month'?monthPrice:(getVipSelect==='year'?yearPrice:foreverPrice)} {userInfo.is_vip ? (userInfo.vip_exp_at === -1 ? '已无限期' : '立即续订') : '立即开通'}
                </Button>
              </Row>
              <Row style={{color: StyleConfig.color_gray, padding: 10}}>
                开通前请查阅<span style={{color: StyleConfig.color_action}}>《会员服务协议》</span>;
                会员时长中，1个月=31天，1年=366天
              </Row>
            </div>
          )
        }
      </Modal>
      <Col xs={0} sm={0} md={0} lg={5} xl={5} xxl={5}>
        <Row justify="end">
          <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
            <Side />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={19} md={19} lg={13} xl={13} xxl={13}>
        <Row justify="center">
          <Col span={23}>
            <div style={{width: '100%'}}>
              {
                getPageNow === 'setting' ? 
                <Setting /> :
                getPageNow === 'invite' ? 
                <Invite /> :
                getPageNow === 'help' ? 
                <Help /> : 
                getPageNow === 'remind' ?
                <Remind /> :
                // getPageNow === 'ai' ? 
                // <AiChat /> :
                getPageNow === 'images' ? 
                <ImageList /> :
                (
                  <NoteList />
                )
              }
            
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={0} sm={5} md={5} lg={5} xl={5} xxl={5}>
        <Row justify="start">
          <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
            <div style={{width: '100%'}}>
              <SideRight />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );

}