const TAG_OPEN_CLOSE_KEY = 'tagopen';

export const setTagClose = (tagId: string) => {
  let exist = JSON.parse(window.localStorage.getItem(TAG_OPEN_CLOSE_KEY) || '{}') || {} as any;
  exist[tagId] = !exist[tagId];
  return window.localStorage.setItem(TAG_OPEN_CLOSE_KEY, JSON.stringify(exist));
}

export const getTagClosees = () => {
  return JSON.parse(window.localStorage.getItem(TAG_OPEN_CLOSE_KEY) || '{}');
}
