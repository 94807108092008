import { Row, Skeleton, Divider, List, Modal, Image, message  } from "antd";
import { useEffect, useState,  } from 'react';
import { noteApi } from '../../service/api';
import { NoteImage, NoteItem } from "../../service/api/types/Note";
import { StyleConfig } from '../../untils/styleConfig';
import InfiniteScroll from 'react-infinite-scroll-component';
import { formatCardId } from '../../untils/notice';
import Html2React from '../note/Html2React';

const ContainerHeight = window.innerHeight;
const weekMap = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
const pageSize = 10;

export default function ImageList() {

  const [data, setData] = useState<NoteImage[]>([]);
  const [loading, setLoading] = useState(false);
  const [getCurPage, setCurPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isNoteDetailShow, setNoteDetailShow] = useState(false);
  const [noteDetail, setNoteDetail] = useState({} as NoteItem);


  const date = new Date();
  const today = `${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`;

  const appendData = () => {
    if (loading) {
      return;
    }
    setLoading(true);

    noteApi.note.imageList(getCurPage).then(res => {
      if (getCurPage === 1) {
        res && res.list && setData(res.list);
      } else {
        res && res.list && setData([...data, ...res.list]);
      }
      setLoading(false);
      if (res.list.length < pageSize) {
        setHasMore(false);
      }
      setCurPage(getCurPage + 1);
    }).catch(() => {
      setLoading(false);
    })

  }

  useEffect(() => {
    appendData();
  }, []);

  const onPressNoteImg = (noteId: string) => {
    noteId && noteApi.note.noteDetail(noteId).then(res => {
      if (res.success && res.note) {
        res.note.text = res.note.text.replaceAll('http:catNote:', `${process.env.REACT_APP_WEB_DOMAIN || ''}/note/`)
        setNoteDetail(res.note)
        setNoteDetailShow(true)
      } else {
        message.warning(res.msg || '笔记内容获取失败');
      }
    }).catch(() => {
      message.error('笔记内容获取失败');
    })
  }


  return (
    <div>
      <div
        id="scrollableDiv"
        style={{
          height: ContainerHeight,
          overflow: 'auto',
          backgroundColor: StyleConfig.color_background,
        }}
      >
        <Modal title={formatCardId((noteDetail && noteDetail.card_id) || '')} open={isNoteDetailShow} onCancel={() => setNoteDetailShow(false)}
          footer={false}
          width={700}
        >
          <div style={{width: 660}}>
            <Html2React html={noteDetail.text || ''}  imgBig={true} />
          </div>
          <div style={{marginTop: 10}}>
            {
              (noteDetail.tags || []).map(tag => {
                return (
                  <span
                    key={tag._id}
                    style={{ marginTop: 10, marginRight: 5, paddingTop: 3, paddingBottom: 3, paddingLeft: 5, paddingRight: 5, backgroundColor: StyleConfig.color_tag_back, borderRadius: 5 }}
                  >
                    <span
                      style={{ color: StyleConfig.color_tag_text, fontSize: StyleConfig.tag_text_size }}
                    >
                      {tag.name}
                    </span>
                  </span>
                )
              })
            }
          </div>
        </Modal>
        
        <InfiniteScroll
          dataLength={data.length}
          next={appendData}
          hasMore={hasMore && data.length >= pageSize}
          loader={<Skeleton paragraph={{ rows: 3 }} active />}
          endMessage={data.length >= pageSize ? <Divider plain>我也是有底线的~</Divider> : <></>}
          scrollableTarget="scrollableDiv"
        >
          <List
            style={{backgroundColor: StyleConfig.color_background, paddingBottom: 50}}
            dataSource={data}
            split={false}
            locale={{emptyText: '让记忆无处可藏'}}
            renderItem={(item: NoteImage) => {

              const date = new Date(item.date);
              const weekDay = weekMap[date.getDay()] || '';

              return (
                <div className='note' key={item.date} style={{borderRadius: 6, marginBottom: 10, backgroundColor: StyleConfig.color_white, padding: 14, margin: 5}}>
                  <Row style={{color: StyleConfig.color_black, fontSize: 12, fontWeight: '400', paddingLeft: 2,}}>
                    <div>
                      <div style={{float: 'left', width: 2, marginTop: 4, marginRight: 2, height: 10, backgroundColor: StyleConfig.color_blue }}>
                      </div>
                      {
                        item.date === today ? `${item.date} 今天` : item.date
                      }
                      {
                        ` ${weekDay}`
                      }
                    </div>
                  </Row>
                    <div style={{width: '100%', marginTop: 4, overflow: 'hidden' }} >
                      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4, marginLeft: -4, flexWrap: 'wrap' }}>
                        {
                          item && item.list && item.list.map(nImg => {
                            return (
                              <div style={{padding: 5, cursor: 'pointer'}} key={nImg.id}
                                onClick={() => onPressNoteImg(nImg.id)}
                              >
                                <Image preview={false} src={`${nImg.img}?x-oss-process=image/quality,q_20`} style={{ borderRadius: 4, width: 80, height: 80, objectFit: 'cover' }}/>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
              );
            }}
          />
        </InfiniteScroll>
    </div>
    </div>
  )
}