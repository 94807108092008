import React from 'react';
import { RecoilRoot, useRecoilValue } from 'recoil';
import { enableMapSet, enablePatches } from 'immer';
import { ConfigProvider, Spin } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login, { genReturnUrl } from './view/login/Login';
import Main from './view/main/Main';
import { getToken } from './untils/token';
import Share from './view/share/Share';
import Mobile from './view/mobile/Mobile';
import { authIsLogin } from './store/auth';

enableMapSet();
enablePatches();

function TheRoutes() {

  const isLogin = useRecoilValue(authIsLogin) || getToken();
  if (!isLogin) {
    genReturnUrl();
  }

  const toLink = window.location.toString().replace(new RegExp(`${window.location.origin}`), '');

  // PC还是H5
  const detectDeviceType = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "Mobile" : "Desktop"; 
  const device = detectDeviceType();
  if (device === 'Mobile' && toLink.indexOf('nm=yes') === -1 && toLink.indexOf('note/') === -1) {
    return (
      <Routes>
        <Route path='*' element={<Mobile />}></Route>
      </Routes>
    )
  }
 
  return (
    <Routes>
      <Route path='/note/*' element={<Share />}></Route>
      <Route path='*' element={isLogin ? <Main /> : <Login />}></Route>
    </Routes>
  );
}

// function DebugObserver() {
//   const snapshot = useRecoilSnapshot();
//   useEffect(() => {
//     console.info('atoms数据改变:');
//     for (const node of snapshot.getNodes_UNSTABLE({isModified: true})) {
//       console.info(node.key, snapshot.getLoadable(node));
//     }
//   }, [snapshot]);

//   return null;
// }

function App() {
  return (
    <RecoilRoot>
      
      {/* TODO 这里是debug recoil */}
      {/* <DebugObserver /> */}

      <ConfigProvider locale={zhCN}
        theme={{
          token: {
            colorPrimary: '#FFD000',
          },
        }}
      >
        <React.Suspense fallback={<Spin />}>
          <Router>
            <TheRoutes />
          </Router>
        </React.Suspense>
      </ConfigProvider>
    </RecoilRoot>
  );
}

export default App;
