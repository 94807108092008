const TOKEN_KEY = 'cat_sess';

export const setToken = (token: string) => {
  return window.localStorage.setItem(TOKEN_KEY, token);
}

export const getToken = () => {
  return window.localStorage.getItem(TOKEN_KEY);
}

export const removeToken = () => {
  return window.localStorage.removeItem(TOKEN_KEY);
}