import { useRef, useCallback, useMemo, useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { message, Popover, Button, Input, Row, Col, List, Tooltip, Space, Tour } from 'antd';
import { StyleConfig } from '../../untils/styleConfig';
import { noteApi } from '../../service/api';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { curPage, curEditNote, tags, vipModalShow, curHasMore, pageNow } from '../../store/note';
import { reload } from '../../store/note';
import { NoteItem, TagItem } from '../../service/api/types/Note';
import { CloseOutlined } from '@ant-design/icons';
import { profile } from '../../store/auth';
import { formatCardId, hasTourSaw, setCloseTour } from '../../untils/notice';
import Html2React from './Html2React';
import type { TourProps } from 'antd';

const { Search } = Input;
// const { Option } = Select;

export default function Editor(props?: { propsNote?: NoteItem, listNote?: NoteItem[]  })  {
  const editorRef = useRef(null as any);
  const refSend =  useRef(null as any);
  let tagRef = useRef(null as any) as any;
  let ocrRef = useRef(null as any) as any;
  let linkRef = useRef(null as any) as any;
  let inLinkRef = useRef(null as any) as any;

  const [editorValue, setEditorValue] = useState((props && props.propsNote &&  props.propsNote.text) || '');
  const [editorBorder, setEditorBorder] = useState('1px solid rgba(173,181,189, 0.4)');
  const [editorBoxShadow, setEditorBoxShadow] = useState('');
  const setCurPage = useSetRecoilState(curPage);
  const setReload = useSetRecoilState(reload);
  const setCurEdit = useSetRecoilState(curEditNote);
  const getTagList = useRecoilValue(tags);
  const [getTextTagList, setTextTagList] = useState([] as any[]);
  const [postTagList, setPostTagList] = useState((props && props.propsNote && props.propsNote.tags) || [] as TagItem[]);
  const [getAllInputList, setAllTagInputList] = useState([] as any[]);
  const [getTagNewText, setTagNewText] = useState('');
  const [getLinkSearchText, setLinkSearchText] = useState('');
  const [getLinkSearchData, setLinkSearchData] = useState([] as NoteItem[]);
  const [getLinkInsertIndex, setLinkInsertIndex] = useState(0);
  const [getInLinkValue, setInLinkValue] = useState('');
  // const [getInLinkBefore, setInLinkBefore] = useState('http://');
  const userInfo = useRecoilValue(profile);
  const setVipModalShow = useSetRecoilState(vipModalShow);
  const [getTourOpen, setTourOpen] = useState(false);
  const setCurHasMore = useSetRecoilState(curHasMore);
  const setPageNow = useSetRecoilState(pageNow);

  // const tagExistList = (getTagList || []).filter(item => !item.sub_ids || item.sub_ids.length === 0);
  const tagExistList = (getTagList || []);
  const [getTagFilter, setTagFilter] = useState(tagExistList);

  const setEditorStateFn = function (v: string) {
    if (v === "<p><br></p>") {
      setEditorValue('');
    } else {
      setEditorValue(v);
    }
    if (v) {
      // 把文本中的标签单放一个数组
      const textTagArr = ((v.match(/#.*?( |<)/g) || []) as any[]).map(item => {
        return item && item.replaceAll('#', '').replaceAll(' ', '').replaceAll('<', '') || ''
      }) as any[];
      setTextTagList(textTagArr.filter(name => !!name).map(item => {
        return {
          name: item,
          cantdel: true
        }
      }))
      // 删除选择的标签中与文本正文重复的
      setPostTagList(postTagList.filter(item => !textTagArr.includes(item.name)))
      // console.log('textTagArr', textTagArr)
      // console.log('tagSelectArr', postTagList)
    }
  }

  const steps: TourProps['steps'] = [
    {
      title: '发送快捷键：',
      description: '输入笔记时 “Ctrl+回车” 可快速发送笔记',
      target: () => refSend.current,
    },
  ];
  const closeTour = () => {
    setCloseTour('ctrlsend')
    setTourOpen(false);
  }

  const IconTag = () => <i className="iconfont icon-tag"/>
  const IconBold = () => <i className="iconfont icon-bold"/>
  const IconImage = () => <i className="iconfont icon-image"/>
  const IconList = () => <i className="iconfont icon-listul"/>
  const IconScan = () => <i className="iconfont icon-scan"/>
  const IconLink = () => <i className="iconfont icon-link1"/>
  const IconInLink = () => <i className="iconfont icon-link"/>
  const IconCancel = () => <span>取消</span>
  const IconSend = () => <i style={{color: 'white'}} className="iconfont icon-send"/>

  
  // [8,0] 返回的是光标起始位置和选定内容的长度
  const getEditorSelection = () =>  {
    if (!editorRef || !editorRef.current) {
      return [0, 0];
    }
    const range = editorRef.current.editor.getSelection();
    if (!range) {
      return [0, 0];
    }
    return [range.index || 0, range.length || 0];
  }

  const insertText = (text: string) => {
    const [index, length] = getEditorSelection();
    console.log(length);
    editorRef && editorRef.current && editorRef.current.editor.insertText(index, text);
    editorRef && editorRef.current && editorRef.current.editor.setSelection(index + text.length, 0);
  }

  // tag
  const tagAction = () => {
    tagRef && tagRef.current.click();
  }
  const onPressTagExist = (tag: TagItem) => {
    const isInclude = !tag.name || postTagList.map(item => item.name).includes(tag.name);
    if (!isInclude) {
      setPostTagList(postTagList.concat([tag]));
    }
    tagRef && tagRef.current && tagRef.current.click()
  }
  const onPressPostTagRemove = (item: TagItem) => {
    const tagLeft = (postTagList || []).filter(tag => tag.name !== item.name);
    setPostTagList(tagLeft);
  }
  const onTagAddPress = () => {
    const tagNew = getTagNewText;
    const isInclude = !tagNew || postTagList.map(item => item.name).includes(tagNew);
    if (!isInclude) {
      setPostTagList(postTagList.concat([{name: tagNew, _id: `n${new Date().getTime()}`} as any]))
    }
    setTagNewText('');
    tagRef && tagRef.current && tagRef.current.click()
  }
  const tagInputChange = (value: string) => {
    setTagNewText(value);
    if (value) {
      setTagFilter(tagExistList.filter(item => item.name.indexOf(value) !== -1))
    } else {
      setTagFilter(tagExistList)
    }
  }
  const onTagInputKeyDown = (e: any) =>{
    if (e && e.keyCode === 13) {
      onTagAddPress();
    }
  }
  const tagMenu = (
    <div>
      <Row>
        <Col>
          <Input placeholder="输入标签名，可用'/'分级" onChange={ e => tagInputChange(e.target.value) } value={getTagNewText} onKeyDown={onTagInputKeyDown} />
        </Col>
        <Col>
          <Button type='primary' style={{marginLeft: 4}} onClick={() => {onTagAddPress()}}>添加</Button>
        </Col>
      </Row>
      <div>
        <p style={{ color: StyleConfig.color_gray, marginTop: 10 }}>
          已有{tagExistList.length}个标签:
        </p>
        <div style={{maxHeight: 300, overflow: 'scroll'}}>
        <List
          size="small"
          bordered={false}
          locale={{emptyText: '暂无标签'}}
          dataSource={getTagNewText ? getTagFilter: tagExistList}
          renderItem={item => {
            return (
              <div style={{marginTop: 8}}>
                <span
                  key={item._id}
                  onClick={() => onPressTagExist(item)}
                  style={{ cursor: 'pointer',marginTop: 10, marginRight: 5, paddingTop: 3, paddingBottom: 3, paddingLeft: 5, paddingRight: 5, backgroundColor: StyleConfig.color_tag_back, borderRadius: 5 }}
                >
                  <span
                    style={{ color: StyleConfig.color_tag_text, fontSize: StyleConfig.tag_text_size }}
                  >
                    {item.name}
                  </span>
                </span>
              </div>
              // <p style={{paddingBottom: 4, paddingTop: 4}}>
              //   <Button size='small' type='link' key={item._id} onClick={() => onPressTagExist(item)} >
              //     {item.name}
              //   </Button>
              // </p>
            )
          }}
        />
        </div>
      </div>
    </div>
    );

  // img
  const insertImage = (url: string) => {
    const [index, length] = getEditorSelection();
    console.log(length);
    editorRef && editorRef.current && editorRef.current.editor.insertEmbed(index, 'image', url);
    editorRef && editorRef.current && editorRef.current.editor.setSelection(index + 1, 0);
  }
  function imagePaste(file: any) {
    const fd = new FormData();
    if (file) {
      fd.append('file', file);
      const hide = message.loading('图片上传中...', 0);
      setTimeout(hide, 2500);
      noteApi.note.uploadImage(fd).then(res => {
        setTimeout(hide, 1);

        if (res.success) {
          const imageUrl = (res.files && res.files[0] && res.files[0].url) || '';
          insertImage(imageUrl);
        } else {
          if (res.code === 40010) {
            setVipModalShow(true);
            return message.warning(res.msg || '图片存储空间不足');
          } else {
            message.error(res.msg || '图片上传失败');
          }
        }
      }).catch(err => {
        setTimeout(hide, 1);
        console.log('uploadImage err:', err);
      })
    }
  }
  function imageAction() {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = () => {
      const file = input && input.files && input.files[0];
      const fd = new FormData();
      if (file) {
        fd.append('file', file);
        const hide = message.loading('图片上传中...', 0);
        setTimeout(hide, 2500);
        noteApi.note.uploadImage(fd).then(res => {
          setTimeout(hide, 1);

          if (res.success) {
            const imageUrl = (res.files && res.files[0] && res.files[0].url) || '';
            insertImage(imageUrl);
          } else {
            if (res.code === 40010) {
              // TODO 图片空间不足
              setVipModalShow(true);
              return message.warning(res.msg || '图片存储空间不足');
            } else {
              message.error(res.msg || '图片上传失败');
            }
          }
        }).catch(err => {
          setTimeout(hide, 1);
          console.log('uploadImage err:', err);
        })
      }
    }
  }

  // ocr
  const scanDoAction = () => {
    if (!userInfo.is_vip) {
      ocrRef && ocrRef.current && ocrRef.current.click();
    }

    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = () => {
      const file = input && input.files && input.files[0];
      const fd = new FormData();
      if (file) {
        fd.append('file', file);
        const hide = message.loading('文字识别中...', 0);
        setTimeout(hide, 2500);
        noteApi.note.uploadImage(fd).then(res => {
          if (res.success) {

            const imageUrl = (res.files && res.files[0] && res.files[0].url) || '';
            if (imageUrl) {
              noteApi.note.imageOcr(imageUrl).then(res2 => {
                setTimeout(hide, 1);
                if (!res2.success) {
                  if (res2.code === 40010) {
                    // TODO
                    setVipModalShow(true);
                    return message.warning(res2.msg || '继续使用需会员权限');
                  }
                  return message.error(res2.msg || '文字识别失败');
                } else {
                  if (!res2.data) {
                    return message.info(res2.msg || '未识别到文字');
                  }
                  insertText(res2.data);
                }
              }).catch(err2 => {
                setTimeout(hide, 1);
                console.log('image orc err:', err2);
              }) 
            }

          } else {
            setTimeout(hide, 1);
            if (res.code === 40010) {
              // TODO 图片空间不足
              setVipModalShow(true);
              return message.warning(res.msg || '图片存储空间不足');
            } else {
              message.error(res.msg || '图片上传失败');
            }
          }
        }).catch(err => {
          setTimeout(hide, 1);
          console.log('uploadImage err:', err);
        })
        //这里写自己的接口
      }
    }
  }
  const scanAction = () => {
    if (!userInfo.is_vip) {
      ocrRef && ocrRef.current.click();
    } else {
      scanDoAction();
    }
  }
  const scanOpenVip = () => {
    setVipModalShow(true);
  }
  const ocrMenu = (
    <div>
      <Row justify='center' style={{marginBottom: 10}}>
        <Col>
          免费用户每月可免费使用3次
        </Col>
      </Row>
      <Row>
        <Col>
          <Space>
            <Button type='primary' size='small' onClick={scanDoAction}>
              免费识别
            </Button>
            <Button size='small' onClick={scanOpenVip}>
              开通会员
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );

  // 关联笔记
  const slinkAction = () => {
    const [index, length] = getEditorSelection();
    console.log(length);
    setLinkInsertIndex(index);
    linkRef && linkRef.current.click();
  }
  const insertLink = (linkName: string, url: string) => {
    editorRef && editorRef.current && editorRef.current.editor.insertText(getLinkInsertIndex, linkName, 'link', url);
    editorRef && editorRef.current && editorRef.current.editor.setSelection(getLinkInsertIndex + linkName.length, 0);
  }
  const onLinkSearch = (value: string) => {
    setLinkSearchText(value);
    if (value) {
      noteApi.note.search(value.trim()).then(res => {
        if (res.success) {
          setLinkSearchData(res.list);
        } else {
          return message.warning(res.msg || '搜索笔记失败');
        }
      }).catch(err => {
        console.log('search err', err);
      })
    }
  }
  const clickNote = (note: NoteItem) => {
    insertLink(formatCardId(note.card_id), `http:catNote:${note._id}`);
    linkRef && linkRef.current && linkRef.current.click();
  }
  const linkMenu = (
    <div>
      <Row justify='start' style={{marginBottom: 10}}>
        <Col>
          <Search placeholder="搜索全部笔记" onSearch={onLinkSearch} allowClear={true} />
        </Col>
      </Row>
      <Row justify='start'>
        <Col style={{fontWeight: 'bold'}}>
          点击关联近日笔记
        </Col>
      </Row>
      <Row style={{backgroundColor: '#f0f2f5'}}>
        <Col>
          <div style={{maxHeight: 500, overflow: 'scroll'}}>
          <List
            dataSource={getLinkSearchText ? getLinkSearchData : (props?.listNote || []).filter(item => item._id !== (props?.propsNote && props?.propsNote?._id))}
            locale={{emptyText: '未搜到相关笔记'}}
            split={false}
            renderItem={(item: NoteItem) => {
              item.text = item.text.replace('http:catNote:', `${process.env.REACT_APP_WEB_DOMAIN || ''}/note/`);
              if (getLinkSearchText && isNaN(Number(getLinkSearchText))) {
                item.text = item.text.replace(getLinkSearchText, `<span class="search-text">${getLinkSearchText}</span>`);
              }
              return (
                <div className='note' key={item._id} style={{borderRadius: 6, marginBottom: 10, backgroundColor: StyleConfig.color_white, padding: 14, margin: 5, cursor: 'pointer' }}>
                  <Row style={{color: StyleConfig.color_gray, fontSize: 12}}
                  onClick={() => clickNote(item)}
                  >
                    <div>
                    <div style={{float: 'left', width: 2, marginTop: 4, marginRight: 2, height: 10, backgroundColor: item.deleted ? StyleConfig.color_danger : (item.wx_in ? StyleConfig.color_green : StyleConfig.color_primary)}}></div>
                    {formatCardId(item.card_id)}
                    </div>
                    <div style={{position: 'absolute', right: 20}}>
                    </div>
                  </Row>
                  {/* <div style={{width: 660, maxHeight: userInfo.note_fold ? 500 : 10000, overflow: 'hidden', marginTop: 4 }} 
                    dangerouslySetInnerHTML={{ __html: item.text }} 
                    onClick={() => clickNote(item)}
                  /> */}
                  <div style={{width: 660, marginTop: 4 }} 
                    // dangerouslySetInnerHTML={{ __html: item.text }} 
                    onClick={() => clickNote(item)}
                  >
                    <Html2React html={item.text || ''} imgBig={false} isList={true} maxHeight={userInfo.note_fold ? 500 : 10000}/>
                    </div>
                    <div style={{marginTop: 6}}>
                      {
                        (item.tags || []).map(tag => {
                          return (
                            <span
                              key={tag._id}
                              style={{ marginTop: 10, marginRight: 5, paddingTop: 3, paddingBottom: 3, paddingLeft: 5, paddingRight: 5, backgroundColor: StyleConfig.color_tag_back, borderRadius: 5 }}
                            >
                              <span
                                style={{ color: StyleConfig.color_tag_text, fontSize: StyleConfig.tag_text_size }}
                              >
                                {tag.name}
                              </span>
                            </span>
                          )
                        })
                      }
                    </div>
                  </div>
              );
            }}
          />
          </div>
        </Col>
      </Row>
    </div>
  )

  const sinlinkAction = () => {
    const [index, length] = getEditorSelection();
    console.log(length);
    setLinkInsertIndex(index);
    inLinkRef && inLinkRef.current.click();
  }
  // const selectBefore = (
  //   <Select className="select-before" value={getInLinkBefore} onChange={(e) => setInLinkBefore(e)}>
  //     <Option value="http://">http://</Option>
  //     <Option value="https://">https://</Option>
  //   </Select>
  // );
  const onPressInLink = () => {
    if (!getInLinkValue) {
      return message.warning('链接为空');
    }
    let link = getInLinkValue;
    if (getInLinkValue.indexOf('http') < 0) {
      link = `https://${getInLinkValue}`;
    }
    // insertLink(getInLinkValue, `${getInLinkBefore}${getInLinkValue}`);
    insertLink(link, link);
    setInLinkValue('');
    inLinkRef && inLinkRef.current && inLinkRef.current.click();
  }
  const inLinkMenu = (
    <div>
      <Row>
        <Col>
          <Input.Group compact>
            <Input style={{ width: 'calc(100% - 100px)' }} 
            // addonBefore={selectBefore} 
            value={getInLinkValue} onChange={(e) => setInLinkValue(e.target.value)} />
            <Button type="primary" onClick={onPressInLink}>插入</Button>
          </Input.Group>
        </Col>
      </Row>
    </div>
  )

  const cancelAction = () => {
    setEditorBorder('1px solid rgba(173,181,189, 0.4)');
    setEditorBoxShadow('');
    setCurEdit('');
  }

  const sendAction = () => {
    // 把p改为div
    const value = ((editorRef && editorRef.current && editorRef.current.value) || '').replace(/<([\/]?)(p)((:?\s*)(:?[^>]*)(:?\s*))>/g, '<$1div$3>').replaceAll(`${process.env.REACT_APP_WEB_DOMAIN || ''}/note/`, 'http:catNote:');
    if (value.length === 0) {
      return message.warning('笔记内容为空');
    }

    if (props?.propsNote?._id) {
      // 编辑笔记
      noteApi.note.noteUpdate(props?.propsNote?._id, 
        Buffer.from(encodeURI(value)).toString('base64'),
        editorRef && editorRef.current && editorRef.current['postTagList'] && editorRef.current['postTagList'].length > 0 ? Buffer.from(encodeURI(JSON.stringify(editorRef.current['postTagList']))).toString('base64') : '',
        ).then(res => {
        if (res.success) {
          setPageNow('');
          setCurPage(1);
          setCurHasMore(true);
          setReload(new Date().getTime());
          setEditorValue('');
          setPostTagList([]);
          editorRef && editorRef.current && editorRef.current.editor && editorRef.current.editor.blur();
          onBlurFn();
          cancelAction();
          setTagNewText('');
        } else {
          message.error(res.msg || '笔记编辑失败');
        }
      }).catch(err => {
        console.log('noteUpdate error', err);
        message.error('服务繁忙，请稍后再试');
      });
    } else {
      // 新增笔记
      noteApi.note.noteAdd(Buffer.from(encodeURI(value)).toString('base64'),
      editorRef && editorRef.current && editorRef.current['postTagList'] && editorRef.current['postTagList'].length > 0 ? Buffer.from(encodeURI(JSON.stringify(editorRef.current['postTagList']))).toString('base64') : '',
      ).then(res => {
        if (res.success) {
          setCurPage(1);
          setReload(new Date().getTime());
          setEditorValue('');
          setPostTagList([]);
          editorRef && editorRef.current && editorRef.current.editor && editorRef.current.editor.blur();
          onBlurFn();
          setTagNewText('');
        } else {
          message.error(res.msg || '笔记创建失败');
        }
      }).catch(err => {
        console.log('noteAdd error', err);
        message.error('服务繁忙，请稍后再试');
      });
    }
  }

  // 自定义的 toolbar, useCallback 重渲染会有显示问题
  const CustomToolbar = useCallback((custom: { editorValueLength: number }) => (
    <div id="toolbar" style={{paddingLeft: 10, borderWidth: 0,backgroundColor: 'white', borderBottomLeftRadius: 8, borderBottomRightRadius: 8}}>
      <button className="ql-stag">
        <IconTag />
      </button>
      <button className="ql-bold">
        <IconBold />
      </button>
      <button className="ql-list">
        <IconList />
      </button>
      <Tooltip title="插入图片">
        <button className="ql-simage">
          <IconImage />
        </button>
      </Tooltip>
      <Tooltip title="文字识别">
        <button className="ql-sscan">
          <IconScan />
        </button>
      </Tooltip>
      <Tooltip title="关联笔记">
        <button className="ql-slink">
          <IconLink />
        </button>
      </Tooltip>
      <Tooltip title="超链接">
        <button className="ql-sinlink">
          <IconInLink />
        </button>
      </Tooltip>
      <button className='ql-sCancel' style={{position: 'absolute', right: 80, width: 50, height: 28}}>
        {
          props && props.propsNote ? <IconCancel /> : <></>
        }
      </button>
      <button ref={refSend} className="ql-ssend" style={{position: 'absolute', right: 30, width: 40, height: 28, backgroundColor: custom.editorValueLength > 0 ? StyleConfig.color_action: StyleConfig.color_muted, borderRadius: 20}}>
        <IconSend />
      </button>
    </div>

  ), []);

  // 直接声明会有显示问题
  const modules = useMemo(() => ({
    toolbar: {
      container: '#toolbar',
      handlers: {
        stag: tagAction,
        simage: imageAction,
        sscan: scanAction,
        slink: slinkAction,
        sinlink: sinlinkAction,
        ssend: sendAction,
        sCancel: cancelAction,
      },
    },
  }), []);
  
  const onKeyDown = (e: any) => {
    if (e && e.ctrlKey && e.keyCode == 13) {
      console.log('同时按下ctrl+回车')
      sendAction()
    }
  }

  useEffect(() => {
    props && props.propsNote && editorRef && editorRef.current && editorRef.current.editor && editorRef.current.editor.focus();
    const tourSaw = hasTourSaw('ctrlsend');
    if (!tourSaw) {
      setTourOpen(true)
    }

    // 监听粘贴图片(一张)
    const el = document.getElementById('past-contener');
    el && el.addEventListener('paste', function(e: any){
      let file = null;
      const items = (e.clipboardData).items;
      if (items && items.length) {
        for (var i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            file = items[i].getAsFile();
            break;
          }
        }
      }
      if (file) {
        // 此时获取到file文件对象，即可处理上传相关处理
        imagePaste(file);
      }
     });


  }, [])

  useEffect(() => {
    const textTagNames = getTextTagList.map(item => item.name);
    const allTagInputList = getTextTagList.concat(postTagList.filter(item => !textTagNames.includes(item.name)));
    setAllTagInputList(allTagInputList);
    if (editorRef && editorRef.current) {
      editorRef.current['postTagList'] = allTagInputList;
    }
  }, [postTagList.length, getTextTagList])

  const onFocusFn = () => {
    setEditorBorder('1px solid rgba(255,208,0, 0.8)');
    setEditorBoxShadow('0 2px 10px rgba(255,208,0, 0.4)');
  }
  const onBlurFn = () => {
    if (tagRef && tagRef.current.className === 'ant-popover-open') {
      return;
    }
    setEditorBorder('1px solid rgba(173,181,189, 0.4)');
    setEditorBoxShadow('');
  }

  // .ql-container.ql-snow
  return (
   <div style={{border: editorBorder, borderRadius: 6, boxShadow: editorBoxShadow, backgroundColor: StyleConfig.color_white }}>
    <ReactQuill
      ref={editorRef}
      id="past-contener"
      theme="snow" 
      value={editorValue} 
      onChange={setEditorStateFn} 
      modules={modules} 
      style={{backgroundColor: StyleConfig.color_white, borderTopRightRadius: 8, borderTopLeftRadius: 8,
         whiteSpace: 'pre', // 粘贴的文字保留换行
        }}
      onFocus={onFocusFn}
      onBlur={onBlurFn}
      onKeyDown={onKeyDown}
     />
     {
      getAllInputList && getAllInputList.length > 0 ?
      (
        <div style={{paddingLeft: 8, overflowY:'hidden', overflowWrap: 'normal', backgroundColor: StyleConfig.color_white, marginTop: 10}}>
          <List
            size="small"
            bordered={false}
            locale={{emptyText: ''}}
            dataSource={getAllInputList}
            renderItem={item => {
              return (
                <span
                  key={item._id}
                  style={{
                    backgroundColor: StyleConfig.color_tag_back,
                    borderRadius: 10,
                    padding: 7,
                    marginRight: 10,
                    marginTop: 2,
                  }}
                >
                  <span
                    style={{color: StyleConfig.color_tag_text, fontSize: StyleConfig.tag_text_size}}
                  >
                  {
                    item.name
                  }
                  </span>
                  {
                    !item.cantdel ? (
                      <span style={{paddingLeft: 2, cursor: 'pointer'}}
                        onClick={(e) => {  e.stopPropagation(); e.preventDefault(); onPressPostTagRemove(item); }}
                      >
                        <CloseOutlined />
                      </span>
                    ):<></>
                  }
                </span>
              )
            }}
          />
        </div>
      ) : <></>
     }
    
    <div style={{height: 0}}>
      <Popover content={tagMenu} trigger="click" style={{height: 0}} placement="right" title="添加标签">
        <span ref={tagRef} style={{marginLeft: 26, height: 0}}></span>
      </Popover>
    </div>
    <div style={{height: 0}}>
      <Popover content={ocrMenu} trigger="click" style={{height: 0}} placement="right" title="图片文字识别">
        <span ref={ocrRef} style={{marginLeft: 130, height: 0}}></span>
      </Popover>
    </div>
    <div style={{height: 0}}>
      <Popover content={linkMenu} trigger="click" style={{height: 0}} title="关联笔记">
        <span ref={linkRef} style={{marginLeft: 160, height: 0}}></span>
      </Popover>
    </div>
    <div style={{height: 0}}>
      <Popover content={inLinkMenu} trigger="click" style={{height: 0}} placement="right" title="插入超链接">
        <span ref={inLinkRef} style={{marginLeft: 180, height: 0}}></span>
      </Popover>
    </div>
    <CustomToolbar editorValueLength={editorValue.length || 0}/>
    <Tour open={getTourOpen} steps={steps} onClose={closeTour} />
   </div>
  );
}
