import styled from 'styled-components';
import { Button as AntButton, Form as AntForm, Input as AntInput } from 'antd';


export const SettingContent = styled.div`
  position: relative;
  height: calc(100vh - 60);
  margin: 8px auto;
  padding: 0 16px;
  align-items: flex-start;
`;

export const SettingRightContent = styled.div`
  margin: 0 0px;
  padding: 0 0px;
`;

export const SQrcodeBox = styled.div`
margin-bottom: 24px;
position: relative;
height: 170px;
`;

export const SQrcode = styled.img`
width: 160px;
height: 160px;
vertical-align: middle;
border-style: none;
overflow-clip-margin: content-box;
overflow: clip;
`;

export const SQrMask = styled.div`
width: 300px;
height: 170px;
position: absolute;
top: 0;
left: 0;
background: rgba(255,255,255,.9);
`;

export const SQrMaskText = styled.div`
position: absolute;
font-size: 20px;
line-height: 28px;
font-weight: 600;
width: 140px;
text-align: center;
color: #19191a;
top: 56px;
left: 50%;
transform: translate(-50%);
`;

export const SQrMaskButton = styled.div`
position: absolute;
font-size: 16px;
line-height: 22px;
font-weight: 600;
color: #fff;
background: #FFC300;
width: 136px;
height: 40px;
padding: 10px 0;
text-align: center;
box-sizing: border-box;
border-radius: 6px;
cursor: pointer;
bottom: 40px;
left: 82px;
`;


export const SLoginForm = styled(AntForm)`
  overflow: hidden;
`;

export const SLoginInputBox = styled.div`
  display: flex;
  align-items: center;
`;

export const SLoginSpan = styled.span`
  width: 1px;
  height: 22px;
  margin: 0 12px;
  background: #ebebeb;
`;

export const SLoginFormInput= styled(AntInput)`
  height: 48px;
  line-height: 24px;
  background: #F7F7F9;
  border-color: #FFFFFF;
`;

export const SSendCodeButton = styled(AntButton)`
height: 44px;
margin: 4px;
`;