export const StyleConfig = {
  color_primary: 'rgba(255,208,0, 1)',
	color_action: 'rgba(255,195,0, 1)',
	color_action_blue: '#0066CC',
	color_danger: 'rgba(255,123,0,1)',
	color_warning: 'rgba(216, 196, 128, 1)',
	color_success: 'rgba(69, 190, 174, 1)',
	color_white: 'rgba(255, 255, 255, 1)',
	color_light: 'rgba(255, 255, 255, 0.6)',
	color_muted_light: 'rgba(244,244,244, 1)',
	color_muted: 'rgba(173,181,189, 0.4)',
	color_middle: 'rgba(206,212,218, 0.5)',
	color_gray: 'rgba(73,80,87, 0.6)',
	color_dark: 'rgba(33,37,41, 0.7)',
	color_black: 'rgba(0, 0, 0, 0.9)',
	color_red: 'rgba(237, 85, 101, 1)',
	color_blue: 'rgba(12, 84, 227, 0.8)',
  color_background: 'rgba(236, 239, 242, 0.1)',
	color_background_gray: 'rgba(236, 239, 242, 1)',
	color_green: 'rgba(25, 175, 88, 0.8)',

	color_tag_text: 'rgba(255,136,0,1)',
	color_tag_back: 'rgba(255,234,0,0.3)',
	color_tag_back_select: 'rgba(248,249,250,1)',
	color_tag_back_x: 'rgba(108,117,125, 1)',
	tag_text_size: 13,
}