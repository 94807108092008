import styled from 'styled-components';

export const Container = styled.div`
background-image: url(https://file.catnote.cn/img/mobilei.png);
background-color: #4281FF;
background-position: center center;
background-repeat: no-repeat;
background-attachment: fixed;
background-size: cover;
min-height:100vh;
width: 100%;
height: auto;
display: flex;
flex-direction: column;
align-items: center;
position: relative;
overflow: scroll;
`;

export const MobileLogo = styled.img`
box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
`;

export const DownDiv = styled.div`
margin: 20px auto;
width: 90%;
height: 200px;
align-items: center;
flex-direction: column;
justify-content: center;
display: flex;
box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%), 0 4px 20px 0 rgb(0 0 0 / 16%);
border-radius: 6px;
`;
