import { Button, Menu, Card, Col, MenuProps, Row, List } from 'antd';
import React, { useEffect, useState } from 'react';
import { RemindContent, RemindRightContent } from './Remind.style';
import { remindList, remindReadIds } from '../../store/note';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Notice } from '../../service/api/types/Note';
import { StyleConfig } from '../../untils/styleConfig';
import { formatMsToDate } from '../../helper/funcs';
import { getRemindStore, setRemindStore } from '../../untils/notice';


type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('站内消息列表', 'remind', <i className="iconfont icon-notifications" style={{margin: 4, fontSize: 16}}/>),
];

const ContainerHeight = window.innerHeight - 150;

export default function Remind() {
  const [getNowMenu, setNowMenu] = useState('remind');
  const getRemindList = useRecoilValue(remindList);
  const [getRemindReadList, setRemindReadList] = useRecoilState(remindReadIds);
  const [getReloadRemindRead, setReloadRemindRead] = useState((new Date()).getTime());

  const onClickMenu = (e: any) => {
    const key = e.key;
    setNowMenu(key);
  }

  useEffect(() => {
    setRemindReadList(getRemindStore());
  }, [getReloadRemindRead, setRemindReadList]);


  const onPressRemind = (remind: Notice) => {
    if (!getRemindReadList.includes(remind._id)) {
      setRemindStore(getRemindReadList.concat([remind._id]));
    }
    setReloadRemindRead((new Date().getTime()));
  }

  const allRead = () => {
    setRemindStore(getRemindList.map(item => item._id));
    setReloadRemindRead((new Date().getTime()));
  }
  
  return (
    <RemindContent style={{ height: window.innerHeight - 64 }}>
      <div style={{ width: '100%' }}>
        <Menu
          defaultSelectedKeys={['remind']}
          mode='horizontal'
          items={items}
          onClick={(e) => onClickMenu(e)}
          style={{borderRadius: 8}}
        />
      </div>
      <RemindRightContent>
        {
          getNowMenu === 'remind' ? 
          (
            <Card>
              <Row justify='end'>
                <Col>
                  <Button type='link' style={{fontWeight: 'bold', paddingBottom: 10}} onClick={allRead}>
                    全标已读
                  </Button>
                </Col>
              </Row>
              <Row style={{backgroundColor: '#f0f2f5'}}>
                <div style={{maxHeight: ContainerHeight, overflow: 'scroll', width: '100%'}}>
                  <List
                    dataSource={getRemindList}
                    locale={{emptyText: '暂无站内消息'}}
                    split={false}
                    renderItem={(item: Notice) => {
                      return (
                        <div onClick={() => onPressRemind(item)} className='note' key={item._id} style={{opacity: getRemindReadList.includes(item._id) ? 0.5 : 1, borderRadius: 6, marginBottom: 10, backgroundColor: StyleConfig.color_white, padding: 14, margin: 5, cursor: 'pointer' }}>
                          <Row>
                            <Col>
                            {item.text || ''}
                            </Col>
                          </Row>
                          <Row justify='space-between'>
                            <Col style={{paddingTop: 6, fontSize: 13}}>
                              {
                                (item.start_time && formatMsToDate(item.start_time)) || ''
                              }
                            </Col>
                            <Col>
                            {
                              item.link ? (
                              <Button type='link' href={item.link}>
                                查看详情
                              </Button>
                              ) : <></>
                            }
                              
                            </Col>
                          </Row>
                        </div>
                      );
                    }}
                  />
                </div>
              </Row>
            </Card>
          ) : <></>
        }
      </RemindRightContent>
    </RemindContent>
  );
}