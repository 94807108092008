import { useEffect, useState } from 'react';
import { Col, message, Row } from 'antd';
import { noteApi } from '../../service/api';
import { NoteItem } from '../../service/api/types/Note';
import { StyleConfig } from '../../untils/styleConfig';

const ContainerHeight = window.innerHeight;
const ContainerWidth = window.innerWidth;

export default function Share() {
  const [noteDetail, setNoteDetail] = useState({} as NoteItem);
  const noteId = window.location.toString().replace(new RegExp(`${window.location.origin}/note/`), '');

  useEffect(() => {
    (async () => {
      if (noteId.length === 24) {
        const note = await noteApi.note.nodeShareDetail(noteId);
        if (note.success) {
          setNoteDetail(note.note);
        }
      }
    })();
  }, [noteId]);

  if (noteId.length !== 24) {
    message.error('笔记不存在');
  }

  return (
    <Row style={{width: ContainerWidth, backgroundColor: StyleConfig.color_background_gray}} justify="center" >
      <Col style={{height: ContainerHeight, overflow: 'scroll', backgroundColor: StyleConfig.color_background_gray, padding: 8}}>
        <div style={{borderRadius: 8, backgroundColor: StyleConfig.color_white, padding: 14, margin: 5}}>
          <div className='img-share' style={{minHeight: 100, maxWidth: 660, width: ContainerWidth * 0.9}}
            dangerouslySetInnerHTML={{ __html: noteDetail.text || '' }} 
          />
          <Row style={{ marginTop: 12}} justify="center">
            <Col>
              <img src='https://file.catnote.cn/img/log_new.jpeg' alt='' style={{width: 16, height: 16, borderRadius: 8, marginBottom: -3, alignSelf: 'center'}}/>
              <span style={{marginLeft: 4, fontSize: 9, fontWeight: 'bold', alignSelf: 'center'}}>
                CatNote
              </span>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}