
function addZero(num: any){
  if(parseInt(num) < 10){
    num = '0' + num;
  }
  return num;
}

export function formatMsToDay(ms: number) {
  var oDate = new Date(ms),
  oYear = oDate.getFullYear(),
  oMonth = oDate.getMonth()+1,
  oDay = oDate.getDate();
  return oYear + '-' + addZero(oMonth) + '-' + addZero(oDay);
}

export function formatMsToDate(ms: number) {
  var oDate = new Date(ms),
  oYear = oDate.getFullYear(),
  oMonth = oDate.getMonth()+1,
  oDay = oDate.getDate(),
  oHour = oDate.getHours(),
  oMin = oDate.getMinutes(),
  oSen = oDate.getSeconds();
  return oYear + '-' + addZero(oMonth) + '-' + addZero(oDay) + ' ' + addZero(oHour) + ':' + addZero(oMin) + ':' +addZero(oSen);
}

export function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
          v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}