import { Content, RightContent } from "./Invite.style";
import { Button, Menu, Card, Col, Input, MenuProps, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { noteApi } from '../../service/api';
import { RightCircleOutlined } from "@ant-design/icons";
import { StyleConfig } from '../../untils/styleConfig';
import copy from 'copy-to-clipboard';
import { profile } from '../../store/auth';
import { useRecoilValue } from "recoil";

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('邀请好友', 'invite', <i className="iconfont icon-arrow-redo-circle-sharp" style={{margin: 4, fontSize: 16}}/>),
];

export default function Invite() {
  const [getNowMenu, setNowMenu] = useState('invite');
  const [getInviteCode, setInviteCodeV] = useState('');
  const [getSubmitting, setSubmitting] = useState(false);
  const [recordPeople, setRecordPeople] = useState(0);
  const [recordAward, setRecordAward] = useState(0);
  const userInfo = useRecoilValue(profile);

  useEffect(() => {
    noteApi.note.inviteRecord().then(res => {
      if (res.success) {
        let award = 0;
        let people: string[] = [];
        for (const item of (res.list || [])) {
          award += item.award_day || 0;
          if (item.to && !people.includes(item.to)) {
            people.push(item.to);
          } 
        }
        setRecordPeople(people.length);
        setRecordAward(award);
      } 
    }).catch(err => {
      console.log('invite record err:', err);
    })
  }, []);

  const onClickMenu = (e: any) => {
    const key = e.key;
    setNowMenu(key);
  }

  const setInviteCode = (value: string) => {
    setInviteCodeV(value);
  }

  const onPressCopyInvite = () => {
    copy(userInfo.invite);
		message.success('邀请码复制成功');
  }

  const onSubmitCode = () => {
    const code = getInviteCode.trim();
    if (!code || code.length !== 6) {
      message.error('邀请码长度错误');
      return;
    }
    if (getSubmitting) {
      return;
    }
    setSubmitting(true);
    noteApi.note.inviteByCode(code).then(res => {
      if (res.success) {
        message.success('恭喜激活成功');
      } else {
        message.error(res.msg || '激活失败');
      }
      setSubmitting(false);
    }).catch(err => {
      console.log('invite submit err:', err);
      setSubmitting(false);
      message.error('服务繁忙，请稍后再试');
    })
  }

  
  return (
    <Content style={{ height: window.innerHeight - 64 }}>
      <div style={{ width: 610 }}>
        <Menu
          defaultSelectedKeys={['invite']}
          mode='horizontal'
          items={items}
          onClick={(e) => onClickMenu(e)}
          style={{borderRadius: 8}}
        />
      </div>
      <RightContent>
        {
          getNowMenu === 'invite' ? 
          (
            <Row>
                <Card title="邀请好友得会员" bordered={false} style={{ width: 300 }}>
                  <Row>
                    <Col>
                      <Row style={{paddingBottom: 4}}>邀请好友登录激活<RightCircleOutlined />得<span style={{color: StyleConfig.color_action, fontSize: 18, fontWeight: 'bold', marginTop: -4}}>5</span>天会员</Row>
                      <Row style={{paddingBottom: 4}}>好友开通会员<RightCircleOutlined />再得<span style={{color: StyleConfig.color_action, fontSize: 18, fontWeight: 'bold', marginTop: -4}}>25</span>天会员</Row>
                      <Row style={{paddingBottom: 20}}>多邀多得<RightCircleOutlined /><span style={{color: StyleConfig.color_action, fontSize: 18, fontWeight: 'bold', marginTop: -4}}>上不封顶</span></Row>
                      <Row style={{paddingBottom: 20}}>
                        您已邀请{recordPeople}人 获得{recordAward}天邀请奖励
                      </Row>
                      <Row style={{paddingBottom: 10}}>
                        <span style={{paddingTop: 5, paddingRight: 5, fontWeight: 'bold'}}>我的邀请码: </span><span style={{backgroundColor: StyleConfig.color_muted_light, padding: 5, borderRadius: 5, fontWeight: 'bold'}}>{userInfo.invite || ''}</span>
                      </Row>
                      <Row>
                        <Button type="primary" onClick={onPressCopyInvite}>复制邀请码</Button>
                      </Row>
                    </Col>
                  </Row>
                </Card>
                <Card title="输入好友邀请码" bordered={false} style={{ width: 300, marginLeft: 10 }}>
                  <Row>
                    <Col>
                      <Row style={{paddingBottom: 10}}>
                        <Col style={{paddingTop: 5, paddingRight: 5}}>
                          邀请码:
                        </Col>
                        <Col>
                        <Input placeholder="" onChange={ e => e && setInviteCode(e.target.value) } maxLength={6} style={{width: 80}}/>
                        </Col>
                      </Row>
                      <Row>
                        <Button type="primary" onClick={onSubmitCode}>激活邀请</Button>
                      </Row>
                    </Col>
                  </Row>
                </Card>
            </Row>
          ) : <></>
        }
      </RightContent>
    </Content>
  );
}