
import { errorHandler } from '../../helper/request';
import { extend, RequestOptionsInit } from 'umi-request';
import { notification } from 'antd';
import md5 from 'md5';
import _ from 'lodash';
import * as uuid from 'uuid';
import { 
  NoteImage,
  NoteItem,
  Notice,
  R,
  TagItem,
  User
} from './types/Note';
import { getToken } from '../../untils/token';

export const noteApiHost = process.env.REACT_APP_NOTE_API_HOST;
export const WEB_CLIENT_ID = 'webNote';
export const WEB_CLIENT_SECRET = 'Bp29QyWgoL1O';
export const WEB_CLIENT_VERSION = '1.1.0';

/**
 * 配置request请求时的默认参数
 */
 const request = extend({
  responseType: 'json',
  prefix: noteApiHost,
  credentials: 'same-origin',
  errorHandler,
});

request.use(async (ctx, next) => {
  await (next as () => Promise<void>)(); // 实际是个异步
  const { res, req } = ctx;
  const { success = false, code = 200 } = ctx.res; // 假设返回结果为 : { success: false, msg: '错误信息' } || { success: true, data: unkown }
  if (success || req.options.disabledCheckSuccess) {
    ctx.res = res;
  } else {
    if (code !== 400 && code !== 40010) {
      notification.error({
        description: res.msg || res.message || '网络请求错误',
        message: '请求错误',
      });
    }
  }
});

function genSign(data: any) {
  const notParams = [ 'sig', 'ver', 'sys', 'uuid' ];
  const appSecret = WEB_CLIENT_SECRET;
  const sortedKeys = Object.keys(data).sort();
  let sigStr = '';
  for (let i = 0; i < sortedKeys.length; i++) {
    const key = sortedKeys[i];
    if (notParams.includes(key)) {
      continue;
    }
    const val = data[key];
    if (_.isNull(val) || _.isUndefined(val)) {
      continue;
    }
    sigStr = `${sigStr}&${key}=${val}`;
  }
  return md5(sigStr.substring(1) + appSecret);
}

function urlToDataObj(str: string) {
	const strSplit = str.split("?");
	if (!strSplit[1]) {
		return {};
	}
	let arr = strSplit[1].split("&");   //先通过？分解得到？后面的所需字符串，再将其通过&分解开存放在数组里
	let obj: any = {};
	for (let i of arr) {
		obj[i.split("=")[0]] = i.split("=")[1];  //对数组每项用=分解开，=前为对象属性名，=后为属性值
	}
	return obj;
}

function dealUri(str: string) {
	try {
		const dataObj = urlToDataObj(str);
		dataObj['app'] = WEB_CLIENT_ID;
		dataObj['sys'] = 'web';
		dataObj['ver'] = WEB_CLIENT_VERSION;
		dataObj['time'] = Date.now();
		dataObj['uuid'] = uuid.v4();
		dataObj['sig'] = genSign(dataObj);
		const sData = [];
		for (let attr in dataObj) {
			sData.push(`${attr}=${dataObj[attr]}`);
		}
		return `${str.split("?")[0]}?${sData.join('&')}`;
	}
	catch(e){
		throw new Error('data sign error');
	}
}

async function postRequest<RT>(path: string, options: RequestOptionsInit | undefined) {
  return await request.post<R<RT>>(dealUri(path), Object.assign(options || {}, { headers: { 'X-Token': getToken() } }));
}

async function getRequest<RT>(path: string, options: RequestOptionsInit | undefined) {
  return await request.get<R<RT>>(dealUri(path), Object.assign(options || {}, { headers: { 'X-Token': getToken() } }));
}

async function postFormRequest<RT>(path: string, options: RequestOptionsInit | undefined) {
  return await request.post<R<RT>>(dealUri(path), Object.assign(options || {}, { requestType: 'form', headers: { 'X-Token': getToken() } }));
}

export const noteApi = {
  login: {
    gotoLogin: (url: string) => (window.location.href = `login?returnUrl=${encodeURIComponent(url)}`),
    sendCode: (phone: string) => postRequest<{}>('/api/user/login/phone/code', { data: { phone } }),//不是86的保存时才加前缀
    login: (mobile: string, code: string) => postRequest<{ user: User, token: string }>('/api/user/login/phone', { data: { mobile, code, app: WEB_CLIENT_ID } }),
    loginPwd: (mobile: string, password: string) => postRequest<{ user: User, token: string }>('/api/user/login/password', { data: { mobile, password, app: WEB_CLIENT_ID } }),
    register: (mobile: string, code: string, password: string, password2: string) => postRequest<{ user: User, token: string }>('/api/user/register', { data: { mobile, code, password, password2, app: WEB_CLIENT_ID } }),
    resetPwd: (mobile: string, code: string, password: string, password2: string) => postRequest<{ user: User, token: string }>('/api/user/password/reset', { data: { mobile, code, password, password2, app: WEB_CLIENT_ID } }),
    qrCheck: (qrtoken: string) => postRequest<{ user: User, token: string, data?: string }>('/api/user/login/qr/check', { data: { qrtoken, app: WEB_CLIENT_ID } }),
    bindQrCheck: (qrState: string) => postRequest<{ data?: string }>('/api/user/bind/wx/pc', { data: { qrState, app: WEB_CLIENT_ID } }),
  },

  user: {
    info: () => getRequest<User>('/api/user/info', {}),
    unbind: (type: string) => postRequest<{}>('/api/user/unbind', { data: { type } }),
    bindPhone: (phone: string, code: string) => postRequest<{}>('/api/user/bind/phone', { data: { phone, code } }),
  },

  note: {
    list: (pageIndex: number, tag = '') => getRequest<{ list: NoteItem[], page: { current: number, pageSize: number, total: number, totalPage: number } }>(`/api/note/list?pageIndex=${pageIndex}&tag=${tag}`, {}),
    hotMap: () => getRequest<{ list: { date: string, count: number }[] }>('/api/note/hotmap?v=v2', {}),
    tagList: () => getRequest<{ list: TagItem[] }>('/api/note/tag/list', {}),
    wechatList: (pageIndex: number) => getRequest<{ list: NoteItem[] }>(`/api/note/wechat/list?pageIndex=${pageIndex}`, {}),
    trashList: (pageIndex: number) => getRequest<{ list: NoteItem[] }>(`/api/note/trash/list?pageIndex=${pageIndex}`, {}),
    search: (word: string) => getRequest<{ list: NoteItem[] }>(`/api/note/search?word=${word}`, {}),
    noticeList: () => getRequest<{ announce: Notice, remindList: Notice[] }>('/api/notice/list', {}),
    noteLinks: (to_id: string) => getRequest<{ cur: NoteItem, links: NoteItem[] }>(`/api/note/links?to_id=${to_id}`, {}),

    imageList: (pageIndex: number) => getRequest<{ list: NoteImage[], page: { current: number, pageSize: number, total: number, totalPage: number } }>(`/api/note/image/list?pageIndex=${pageIndex}`, {}),

    tagSetTop: (id: string, cancel = '') => postRequest<{}>(`/api/note/tag/setTop`, { data: { id, cancel } }),
    tagRename: (id: string, name: string) => postRequest<{}>(`/api/note/tag/rename`, { data: { id, name } }),
    tagDel: (id: string) => postRequest<{}>(`/api/note/tag/delete`, { data: { id } }),
    tagMove: (id: string, action = '') => postRequest<{}>(`/api/note/tag/move`, { data: { id, action } }),

    noteAdd: (text: string, tags = '') => postRequest<{}>(`/api/note/add`, { data: { text, tags } }),
    noteUpdate: (id: string, text: string, tags = '') => postRequest<{}>(`/api/note/update`, { data: { id, text, tags } }),
    noteDetail: (id: string) => getRequest<{ note: NoteItem }>(`/api/note/detail?id=${id}`, {}),
    noteSetTop: (id: string, cancel = '') => postRequest<{}>(`/api/note/setTop`, { data: { id, cancel } }),
    noteCombine: (ids: string) => postRequest<{}>(`/api/note/combine`, { data: { ids } }),
    noteDel: (id: string, focus = '', reTurn = '') => postRequest<{}>(`/api/note/delete`, { data: { id, focus, return: reTurn } }),
    noteDelMany: (ids: string, focus = '', reTurn = '') => postRequest<{}>(`/api/note/delete/many`, { data: { ids, focus, return: reTurn } }),
    removeAll: () => postRequest<{}>(`/api/note/removeAll`, { data: {} }),
    trashClear: () => postRequest<{}>(`/api/note/trash/clear`, { data: {} }),
    exportAll: () => postRequest<{ link: string }>(`/api/note/export/html`, { data: {} }),

    uploadImage: (data: any) => postFormRequest<{ files: {name: string, url: string}[] }>('/upload/image', { data: data }),
    imageOcr: (iurl: string) => postRequest<{ data: string }>(`/api/note/image/ocr`, { data: { iurl } }),

    setUserInfo: (config: { avatar?: string, nickname?: string, img_zip?: string, note_fold?: string, notice_in?: string, notice_sys?: string }) => postRequest<{}>(`/api/user/config/set`, { data: config }),
    userDelSelf: () => postRequest<{}>(`/api/user/delself`, { data: {} }),
    resetApi: () => postRequest<{}>(`/api/sapi/reset`, { data: {} }),
    feedback: (content: { type: number, terminal: number, title: string, text: string, client: string }) => postRequest<{}>(`/api/feedback/create`, { data: content }),
    inviteByCode: (code: string) => postRequest<{}>(`/api/invite/code`, { data: { code } }),
    inviteRecord: () => getRequest<{ list: { to: string, type: string, award_day: number, to_name: string, time: number }[] }>(`/api/invite/record`, {}),

    // 不需要登录
    nodeShareDetail: (id: string) => postRequest<{ note: NoteItem }>(`/api/note/share/detail`, { data: { id } }),

  },

  pay: {
    pcPlaceOrder: (type: string) => postRequest<{ code_url?: string, out_trade_id: string }>(`/api/pay/wx/native/order/place`, { data: { type } }),
    checkWxPayRes:  (out_trade_id: string) => postRequest<{ trade_state: string }>(`/api/pay/wx/checkres`, { data: { out_trade_id } }),
  },

  ai: {
    completions: (messages: object[]) => postRequest<{ answer: string }>(`/api/openai/chat/completions`, { data: { messages } }),
  }
};