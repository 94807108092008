const ANNOUNCE_KEY = 'announce';
const REMIND_KEY = 'remind';

export const setAnnounceStore = (token: string) => {
  return window.localStorage.setItem(ANNOUNCE_KEY, token);
}

export const getAnnounceStore = () => {
  return window.localStorage.getItem(ANNOUNCE_KEY);
}

export const removeAnnounceStore = () => {
  return window.localStorage.removeItem(ANNOUNCE_KEY);
}

export const setRemindStore = (ids: string[]) => {
  const arr = ids.join(',');
  return window.localStorage.setItem(REMIND_KEY, arr);
}

export const getRemindStore = () => {
  const str = window.localStorage.getItem(REMIND_KEY);
  return (str || '').split(',') || [];
}

export const removeRemindStore = () => {
  return window.localStorage.removeItem(REMIND_KEY);
}

export function formatCardId(card_id: string) {
  return `${card_id.substring(0, 4)}-${card_id.substring(4, 6)}-${card_id.substring(6, 8)} ${card_id.substring(8, 10)}:${card_id.substring(10, 12)}:${card_id.substring(12, 14)}`;
}

export function isChinese(temp: string){
	var part = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/gi;
		if(!part.exec(temp)) {
			return false;
		} else {
			return true;
		}
}

export function hasTourSaw(key: string) {
  return !!window.localStorage.getItem(`tour${key}`);
}
export function setCloseTour(key: string) {
  window.localStorage.setItem(`tour${key}`, 'CLOSE')
}