import { StyleConfig } from "../../untils/styleConfig";
import { useEffect, useState } from 'react';
import { noteApi } from '../../service/api';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Col, Row, Button, Popover, Modal, Input, message, Space } from 'antd';
import { hotMap, category, tags, curPage, curTag, curHasMore, reload, searchKey, pageNow } from '../../store/note';
import { TagItem } from "../../service/api/types/Note";
import { getTagClosees, setTagClose } from "../../untils/tag";

const { Search } = Input;

const ContainerHeight = window.innerHeight - 54;


export default function SideRight() {

  const [tagsOpen] = useState(true);
  const [tagEditOpen, setTagEditOpen] = useState(false);
  const [tagEditLoading, setTagEditLoading] = useState(false);
  const [tagDelOpen, setTagDelOpen] = useState(false);
  const [tagDelLoading, setTagDelLoading] = useState(false);
  const [tagName, setTagName] = useState('');
  const [editTag, setEditTag] = useState(null as any);
  const setHotMap = useSetRecoilState(hotMap);
  const [getCategory, setCategory] = useRecoilState(category);
  const [getTagList, setTagList] = useRecoilState(tags);
  const setCurPage = useSetRecoilState(curPage);
  const [getCurTag, setCurTag] = useRecoilState(curTag);
  const setCurHasMore = useSetRecoilState(curHasMore);
  const isReload = useRecoilValue(reload);
  const setSearchKey = useSetRecoilState(searchKey);
  const [tagCloseMap, setTagCloseMap] = useState({} as any);
  const [tagCloseUpdate, setTagCloseUpdate] = useState(new Date().getTime())
  const setPageNow = useSetRecoilState(pageNow);

  useEffect(() => {
    (async () => {
      const tagList = await noteApi.note.tagList();
      if (tagList.success) {
        setTagList(tagList.list);
      }

      const hotMap = await noteApi.note.hotMap();
      if (hotMap.success) {
        setHotMap(hotMap.list);
      }
    })();
  }, [isReload, setHotMap, setTagList]);


  useEffect(() => {
    const tagCloses = getTagClosees();
    setTagCloseMap(tagCloses);
  }, [tagCloseUpdate])
  const setTagCloseFn = (tagId: string) => {
    setTagClose(tagId);
    setTagCloseUpdate(new Date().getTime())
  }
  const getTagOpenIcon = (id: string) => {
    return tagCloseMap[id] ? 'iconfont icon-arrow-right-filling' : 'iconfont icon-arrow-down-filling'
  }
  const getTagOpenIs = (id: string) => {
    return tagCloseMap[id] ? false : true;
  }

  const onSearch = (value: string) => setSearchKey(value || '');

  const onPressCategory = function(c: string) {
    if (c === 'tags') {
      setCategory('home');
      setPageNow('');
      setCurTag('');
      setCurPage(1);
      setCurHasMore(true);
      // setTagsOpen(!tagsOpen);
    } else {
      if (c === 'home') {
        setCurTag('');
      }
      setCurPage(1);
      setCurHasMore(true);
      setCategory(c);
    }
  }

  const onPressTag = function(c: string) {
    setCurPage(1);
    setCurHasMore(true);
    setCurTag(c);
    setCategory('tags');
  }

  const handleEditTag = () => {
    if (tagName.length === 0) {
      return message.error('标签名长度不能为零');
    }
    setTagEditLoading(true);
    const tag = editTag;
    tag && noteApi.note.tagRename(tag._id, tagName).then(res => {
      if (res.success) {
        noteApi.note.tagList().then(res2 => {
          setTagList(res2.list);
        })

        setCurTag('');
        setCurPage(1);
        setCurHasMore(true);
        setCategory('home');
      } else {
        message.error(res.msg || '标签重命名失败');
      }
      setTagEditOpen(false);
      setTagEditLoading(false);
    }).catch(err => {
      setTagEditOpen(false);
      setTagEditLoading(false);
    });
  };

  const handleDelTag = () => {
    setTagDelLoading(true);
    const tag = editTag;
    tag && noteApi.note.tagDel(tag._id).then(res => {
      if (res.success) {
        noteApi.note.tagList().then(res2 => {
          setTagList(res2.list);
        })

        setCurTag('');
        setCurPage(1);
        setCurHasMore(true);
        setCategory('home');
      } else {
        message.error(res.msg || '标签删除失败');
      }
      setTagDelOpen(false);
      setTagDelLoading(false);
    }).catch(err => {
      setTagDelOpen(false);
      setTagDelLoading(false);
    });
  };

  const onPressTagTop = (tag: TagItem) => {
    noteApi.note.tagSetTop(tag._id, tag.weight > 0 ? 'TRUE' : '').then(res => {
      noteApi.note.tagList().then(res2 => {
        if (res2.success) {
          setTagList(res2.list);
        }
      })
    });
  }
  const onPressTagEdit = (tag: TagItem) => {
    setEditTag(tag);
    setTagName(tag.r_name);
    setTagEditOpen(true);
  }
  const onPressTagDel = (tag: TagItem) => {
    setEditTag(tag);
    setTagDelOpen(true);
  }
  const onPressTagUp = (tag: TagItem) => {
    noteApi.note.tagMove(tag._id, 'up').then(res => {
      noteApi.note.tagList().then(res2 => {
        if (res2.success) {
          setTagList(res2.list);
        }
      })
    });
  }
  const onPressTagDown = (tag: TagItem) => {
    noteApi.note.tagMove(tag._id, 'down').then(res => {
      noteApi.note.tagList().then(res2 => {
        if (res2.success) {
          setTagList(res2.list);
        }
      })
    });
  }

  // const isAction = tagSelectedId ? false: (item.flag === this.state.flag);
  // const textColor = isAction ? StyleConfig.color_white : StyleConfig.color_gray;
  const tagWithSubList: any[] = [];
  const tagList = getTagList || [];
  const tagsMap = new Map();
  let subIds: string[] = []; // 二三级的子标签
  for (const tag of tagList) {
    tagsMap.set(tag._id, tag);
    if (tag.sub_ids && tag.sub_ids.length > 0) {
      subIds = subIds.concat(tag.sub_ids);
    }
  }

  for (let tag of tagList) {
    if (!subIds.includes(tag._id)) {
      let subs: TagItem[] = [];
      if (tag && tag.sub_ids && tag.sub_ids.length > 0) {
        subs = tag.sub_ids.filter(item => !!tagsMap.get(item)).map(item => tagsMap.get(item));
      }
      const tagExt = Object.assign({ subs: subs.sort((a, b) => {
        if (a.weight < b.weight) {
          return 1;
        } else if (a.weight > b.weight) {
          return -1;
        } else {
          return 0;
        }
      }) }, tag);
      for (const index in tagExt.subs) {
        const subTag = tagExt.subs[index] as TagItem;
        let subSubs = [];
        if (subTag && subTag.sub_ids && subTag.sub_ids.length > 0) {
          subSubs = subTag.sub_ids.filter(item => !!tagsMap.get(item)).map(item => tagsMap.get(item));
        }
        tagExt['subs'][index] = Object.assign({ subs: subSubs.sort((a, b) => {
          if (a.weight < b.weight) {
            return 1;
          } else if (a.weight > b.weight) {
            return -1;
          } else {
            return 0;
          }
        }) }, tagExt['subs'][index]);
      }
      tagWithSubList.push(tagExt);
    }
  }

  const curTagBg = function(isCur: boolean) {
    return isCur ? StyleConfig.color_primary : '#F8F9FA';
  }
  const curTagCor = function(isCur: boolean) {
    return isCur ? StyleConfig.color_white : StyleConfig.color_dark;
  }
  const curTagBgCor = function(tagId: string) {
    return `#${tagId.substr(-6, 6)}`
  }

  const menu = function(tag: TagItem) {
    return (
      <div>
        <p style={{paddingTop: 0, marginTop: 0}}>
          <Button onClick={(e) => { onPressTagTop(tag); e.stopPropagation(); }} style={{borderWidth: 0, padding: 0, height: 10}} >
          {
            tag.weight > 0 ? <i className="iconfont icon-arrow-down-circle" style={{padding: 4}}/> : <i className="iconfont icon-arrow-up-circle1" style={{padding: 4}}/>
          }
          {
            tag.weight > 0 ? '取消置顶' : '置顶'
          }
          </Button>
        </p>
        <p>
          <Button onClick={(e) => { onPressTagEdit(tag); e.stopPropagation(); }} style={{borderWidth: 0, padding: 0, height: 10}} >
          <i className="iconfont icon-create-outline" style={{padding: 4}}/>
          编辑
          </Button>
        </p>
        <p>
          <Button onClick={(e) => { onPressTagUp(tag); e.stopPropagation(); }} style={{borderWidth: 0, padding: 0, height: 10}} >
          <i className="iconfont icon-Up" style={{padding: 4, fontSize: 14}}/>
          上移
          </Button>
        </p>
        <p>
          <Button onClick={(e) => { onPressTagDown(tag); e.stopPropagation(); }} style={{borderWidth: 0, padding: 0, height: 10}} >
          <i className="iconfont icon-arrow-down" style={{padding: 4, fontSize: 14}}/>
          下移
          </Button>
        </p>
        <p style={{color: StyleConfig.color_red}}>
          <Button onClick={(e) => { onPressTagDel(tag); e.stopPropagation(); }} style={{borderWidth: 0, padding: 0, height: 10}} >
          <i className="iconfont icon-trash-outline" style={{color: StyleConfig.color_red}}/>
          删除
          </Button>
        </p>
      </div>
    );
  }


  return (
    <Row style={{paddingTop: 4}}>
      <Modal
        title={`修改标签名：${(editTag && editTag.r_name) || ''}`}
        open={tagEditOpen}
        onOk={handleEditTag}
        confirmLoading={tagEditLoading}
        onCancel={() => { setTagEditOpen(false) }}
      >
        <p>
        <Input placeholder="标签名不能为空" value={tagName} onChange={(v) => { setTagName(v.target.value) }} status={(tagName.length === 0 || tagName.length > 30) ? 'error' : '' }/>
        </p>
      </Modal>
      <Modal
        title={`删除标签：${(editTag && editTag.r_name) || ''}`}
        open={tagDelOpen}
        onOk={handleDelTag}
        confirmLoading={tagDelLoading}
        onCancel={() => { setTagDelOpen(false) }}
      >
        <p>标签同时会从笔记中移除</p>
      </Modal>

      <Row style={{width: '100%'}}>
        <Space direction='horizontal' >
          <Search placeholder="搜索全部笔记" onSearch={onSearch} allowClear={true} style={{marginLeft: 10}}/>
        </Space>
      </Row>

      <div style={{marginTop: 6}}>
        <Row key={'all_tag'} justify='space-between'>
          <Col>
            <div onClick={() => onPressCategory('tags')} style={{ padding: 4 }}>
              <i className="iconfont icon-ios-pricetags" style={{padding: 4, color: StyleConfig.color_gray}}/>
              <span style={{fontWeight: '500', color: StyleConfig.color_gray, cursor: 'pointer'}}>全部标签</span>
            </div>
          </Col>
          {/* <Col span={4} style={{padding: 5}}>
            <i className={tagsOpen ? "iconfont icon-chevron-down" : "iconfont icon-chevron-back"} style={{padding: 4, color: StyleConfig.color_gray}}/>
          </Col> */}
        </Row>

        <Row style={{overflow: 'scroll', height: ContainerHeight}}>
          {
            tagsOpen && tagWithSubList.length > 0 ?
            (
              <div style={{}}>
                {
                  tagWithSubList.map(tag1 => (
                    <div key={tag1._id}>
                      <Button  className="tag-button" style={{borderWidth: 0, width: 250, textAlign: 'left', borderRadius: 5, backgroundColor: curTagBg(getCategory === 'tags' && getCurTag === tag1._id) }}>
                        {/* <i className="iconfont icon-tag1" style={{color: curTagCor(getCategory === 'tags' && getCurTag === tag1._id), position: 'absolute'}}/> */}
                        {/* <span style={{width: 6, height: 6, borderRadius: 3, marginTop: 8, backgroundColor: curTagBgCor(tag1._id), position: 'absolute'}}></span> */}
                        <div onClick={() => setTagCloseFn(tag1._id)} style={{display:'inline'}}>
                        {
                          tag1 && tag1.subs && tag1.subs.length > 0 ?
                          <i className={ getTagOpenIcon(tag1._id) } style={{ fontSize: 12, marginLeft: -2, paddingRight: 3, paddingTop: 2, color: curTagBgCor(tag1._id), position: 'absolute'}} />
                          : <span style={{width: 6, height: 6, borderRadius: 3, marginTop: 8, backgroundColor: curTagBgCor(tag1._id), position: 'absolute'}}></span>
                        }
                        </div>
                        <span onClick={() => onPressTag(tag1._id)}  style={{marginLeft: 18, width: 172, overflow: 'hidden', color: curTagCor(getCategory === 'tags' && getCurTag === tag1._id)}}>
                        {tag1.r_name}
                        </span>
                        <div style={{display:'inline', paddingTop: 3, paddingLeft: 5, color: '#A9A9A9', fontSize: 12, position: 'absolute'}}>
                          {tag1.ncount || '0'}
                        </div>
                        <Popover placement="right" content={menu(tag1)} trigger="hover">
                          <i className="iconfont icon-ellipsis-horizontal" style={{position: 'absolute', right: 8, color: curTagCor(getCategory === 'tags' && getCurTag === tag1._id)}} />
                        </Popover>
                      </Button>
                      {
                        tag1 && tag1.subs && tag1.subs.length > 0 && getTagOpenIs(tag1._id) ? (
                          <div>
                            {
                              tag1.subs.map((tag2: any) => (
                                <div key={tag2._id}>
                                  <Button className="tag-button" style={{borderWidth: 0, width: 250, textAlign: 'left', borderRadius: 5, backgroundColor: curTagBg(getCategory === 'tags' && getCurTag === tag2._id)}}>
                                    <span style={{width: 10}}/>
                                    <div onClick={() => setTagCloseFn(tag2._id)} style={{display:'inline'}}>
                                    {
                                      tag2 && tag2.subs && tag2.subs.length > 0 ?
                                      <i className={ getTagOpenIcon(tag2._id) } style={{ fontSize: 12, marginLeft: -2, paddingRight: 3, paddingTop: 2, color: curTagBgCor(tag2._id), position: 'absolute'}} />
                                      : <span style={{width: 6, height: 6, borderRadius: 3, marginTop: 8, backgroundColor: curTagBgCor(tag2._id), position: 'absolute'}}></span>
                                    }
                                    </div>
                                    <span onClick={() => onPressTag(tag2._id)} style={{marginLeft: 18, width: 162, overflow: 'hidden', color: curTagCor(getCategory === 'tags' && getCurTag === tag2._id)}}>
                                    {tag2.r_name}
                                    </span>
                                    <div style={{display:'inline', paddingTop: 3,  paddingLeft: 5, color: '#A9A9A9', fontSize: 12, position: 'absolute'}}>
                                      {tag2.ncount || '0'}
                                    </div>
                                    <Popover placement="right" content={menu(tag2)} trigger="hover">
                                      <i className="iconfont icon-ellipsis-horizontal" style={{position: 'absolute', right: 8, color: curTagCor(getCategory === 'tags' && getCurTag === tag2._id)}} />
                                    </Popover>
                                  </Button>
                                  {
                                    tag2 && tag2.subs && tag2.subs.length > 0 && getTagOpenIs(tag2._id) ? (
                                      <div>
                                        {
                                          tag2.subs.map((tag3: any) => (
                                            <div key={tag3._id}>
                                              <Button className="tag-button" style={{borderWidth: 0, width: 250, textAlign: 'left', borderRadius: 5, backgroundColor: curTagBg(getCategory === 'tags' && getCurTag === tag3._id)}}>
                                                <span style={{width: 20}}/>
                                                {/* <i className="iconfont icon-tag1" style={{color: curTagCor(getCategory === 'tags' && getCurTag === tag3._id), position: 'absolute'}}/> */}
                                                <span style={{width: 6, height: 6, borderRadius: 3, marginTop: 8, backgroundColor: curTagBgCor(tag3._id), position: 'absolute'}}></span>
                                                <span onClick={() => onPressTag(tag3._id)} style={{marginLeft: 18, width: 152, overflow: 'hidden', color: curTagCor(getCategory === 'tags' && getCurTag === tag3._id)}}>
                                                {tag3.r_name}
                                                </span>
                                                <div style={{display:'inline', paddingTop: 3, paddingLeft: 5, color: '#A9A9A9', fontSize: 12, position: 'absolute'}}>
                                                  {tag3.ncount || '0'}
                                                </div>
                                                <Popover placement="right" content={menu(tag3)} trigger="hover">
                                                  <i className="iconfont icon-ellipsis-horizontal" style={{position: 'absolute', right: 8, color: curTagCor(getCategory === 'tags' && getCurTag === tag3._id)}} />
                                                </Popover>
                                              </Button>
                                            </div>
                                          ))
                                        }
                                      </div>
                                    ) : <></>
                                  }
                                </div>
                              ))
                            }
                          </div>
                        ) : <></>
                      }
                    </div>
                  ))
                }
              </div>
            ) : <></>
          }
        </Row>
      </div>
    </Row>
  );
}