import styled from 'styled-components';

export const Content = styled.div`
  position: relative;
  height: calc(100vh - 60);
  margin: 8px auto;
  padding: 0 16px;
  align-items: flex-start;
`;

export const RightContent = styled.div`
  margin: 0 0px;
  padding: 0 0px;
`;

