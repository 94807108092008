import { notification } from 'antd';
import { ResponseError } from 'umi-request';
import { removeToken } from '../untils/token';
import { genReturnUrl } from '../view/login/Login';

/**
 * 异常处理程序
 */
export const errorHandler = (error: ResponseError) => {
  const { response } = error;

  if (response && response.status) {
    const { status, url } = response;
    if (status === 401) {
      removeToken();
      window.location.href = genReturnUrl();
      return;
    }
    notification.error({
      message: `请求错误 `,
      description: `${status}: ${url}`,
    });
  } else if (!response) {
    notification.error({
      description: error.message || '网络异常，无法连接服务器',
      message: '请求异常',
    });
    throw error;
  }
  return response;
};
