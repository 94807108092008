import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.tsx';
import 'react-quill/dist/quill.snow.css';
import './statics/iconfont/iconfont.css';
import './index.css';
import reportWebVitals from './reportWebVitals';

// 只允许粘贴纯文本
document.addEventListener('paste', e => {
  if (e.target && e.target.localName && (e.target.localName === 'input' || e.target.localName === 'textarea')) {

  } else {
    // 阻止默认的复制事件
    e.preventDefault()
    let txt = ''
    let range = null
    // 获取复制的文本
    txt = e.clipboardData.getData('text/plain')
    // 获取页面文本选区
    range = window.getSelection().getRangeAt(0)
    // 删除默认选中文本
    range.deleteContents()
    // 创建一个文本节点，用于替换选区文本
    let pasteTxt = document.createTextNode(txt)
    // 插入文本节点
    range.insertNode(pasteTxt)
    // 将焦点移动到复制文本结尾
    range.collapse(false)
  }
})

ReactDOM.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  <App />,
  document.getElementById('root')
);

reportWebVitals();
