import { HelpContent, HelpRightContent } from "./Help.style";
import { Button, Menu, Card, Col, Input, MenuProps, message, Row, Form, Radio, Select } from 'antd';
import React, { useState } from 'react';
import { RightOutlined } from "@ant-design/icons";
import { noteApi } from '../../service/api';

const { TextArea } = Input;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('帮助与反馈', 'help', <i className="iconfont icon-help-circle" style={{margin: 4, fontSize: 16}}/>),
];

export default function Help() {
  const [getNowMenu, setNowMenu] = useState('help');
  const [form] = Form.useForm();

  const docHost = process.env.REACT_APP_WEB_DOC_HOST;

  const clickProblemButton = () => {
    const link = `${docHost}/problem`;
    const w = window.open('about:blank');
    if (w && link) {
      w.location.href=link;
    }
  }

  const onClickMenu = (e: any) => {
    const key = e.key;
    setNowMenu(key);
  }

  const onFeedbackFinish = (values: any) => {
    noteApi.note.feedback({ type: Number(values.type), terminal: Number(values.terminal), title: String(values.title), text: String(values.text), client: navigator.userAgent || '' }).then(res => {
      if (res.success) {
        form.resetFields();
        message.success('反馈成功，感谢您的反馈');
      } else {
        message.error(res.msg || '反馈失败，请稍后再试');
      }
    }).catch(err => {
      console.log('feedback err:', err);
      message.error('服务繁忙，请稍后再试');
    })
  }
  
  return (
    <HelpContent style={{ height: window.innerHeight - 64 }}>
      <div style={{ width: '100%' }}>
        <Menu
          defaultSelectedKeys={['help']}
          mode='horizontal'
          items={items}
          onClick={(e) => onClickMenu(e)}
          style={{borderRadius: 8}}
        />
      </div>
      <HelpRightContent>
        {
          getNowMenu === 'help' ? 
          (
            <Card>
              <Card.Grid style={{width: '100%', textAlign: 'center'}}>
                <Button type="link" onClick={() => clickProblemButton() }>
                    快速解决常见问题 <RightOutlined />
                </Button>
              </Card.Grid>
              <Card.Grid style={{width: '100%', textAlign: 'center'}}>
                <Row justify="center">
                  <Col style={{fontWeight: 'bold'}}>
                    反馈给CatNote
                  </Col>
                </Row>
                <Row style={{marginTop: 20}}>
                  <Col span={3}>
                  </Col>
                  <Col span={18}>

                    <Form
                      labelCol={{ span: 4 }}
                      wrapperCol={{ span: 20 }}
                      layout="horizontal"
                      onFinish={onFeedbackFinish}
                      form={form}
                    >
                      <Form.Item label="反馈类型" name="type" initialValue="1">
                        <Radio.Group>
                          <Radio value="1"> 建议 </Radio>
                          <Radio value="2"> 问题 </Radio>
                          <Radio value="3"> 申诉 </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item label="使用端" name="terminal" initialValue="3">
                        <Select>
                          <Select.Option value="1">移动端IOS</Select.Option>
                          <Select.Option value="2">移动端Android</Select.Option>
                          <Select.Option value="3">网页端</Select.Option>
                          <Select.Option value="4">桌面端</Select.Option>
                          <Select.Option value="5">其他</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item label="标题" name="title" rules={[{ required: true, message: '请输入标题'}, { max: 30, message: '标题过长'}]}>
                        <Input />
                      </Form.Item>
                      <Form.Item label="描述" name="text" rules={[{ required: true, message: '请输入描述' }, { max: 300, message: '描述过长，请精简'}]}>
                        <TextArea rows={4} />
                      </Form.Item>
                      <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                          提交
                        </Button>
                      </Form.Item>
                    </Form>

                  </Col>
                  <Col span={3}>
                  </Col>
                </Row>
              </Card.Grid>
            </Card>
          ) : <></>
        }
      </HelpRightContent>
    </HelpContent>
  );
}